export const REPORT_BASE_PATH = '/report';

export const REPORT_PERFORMANCE_PATH = '/performance';
export const REPORT_INCENTIVE_PATH = '/incentive';
export const REPORT_RECRUITMENT_PATH = '/recruitment';
export const REPORT_NEWS_PATH = '/news';

export const REPORT_PERFORMANCE_PRODUCTION_TARGET_PATH = '/production-target';
export const REPORT_INCENTIVE_RESULT_PATH = '/result';
export const REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_PATH = '/production-mtd-target';
export const REPORT_PERFORMANCE_ADBU_TARGET_PATH = '/adbu-target';
export const REPORT_RECRUITMENT_PRUDNA_SUMMARY_PATH = '/prudna-summary';
export const REPORT_RECRUITMENT_PRUDNA_DETAIL_PATH = '/prudna-detail';
export const REPORT_RECRUITMENT_PRUDNA_MASS_SUMMARY_PATH = '/prudna-mass-summary';
export const REPORT_RECRUITMENT_PRUDNA_MASS_DETAIL_PATH = '/prudna-mass-detail';
export const REPORT_NEWS_AGENTS = '/agents';
export const REPORT_NEWS_COMMENTS = '/comments';
export const REPORT_NEWS_INFO = '/info';
export const REPORT_NEWS_AGENT_ACTIVITY = '/agentActivity';

export const reportFilterKeys = ['requestedDateFrom', 'requestedDateTo'];

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const DATE_ERROR_TEXT = 'End date must be after Begin date';

//  These constants map to aside menu, header menu to display title
export const REPORT_TITLE = 'title.report';
export const REPORT_PERFORMANCE_TITLE = 'title.report.performance';
export const REPORT_PERFORMANCE_PRODUCTION_TARGET_TITLE = 'title.report.performance.productionTarget';
export const REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_TITLE = 'title.report.performance.productionTargetMtd';
export const REPORT_PERFORMANCE_ADBU_TARGET_TITLE = 'title.report.performance.adbuTarget';
export const REPORT_INCENTIVE_TITLE = 'title.report.incentive';
export const REPORT_INCENTIVE_RESULT_TITLE = 'title.report.incentive.result';
export const REPORT_RECRUITMENT_TITLE = 'title.report.recruitment';
export const REPORT_RECRUITMENT_PRUDNA_SUMMARY_TITLE = 'title.report.recruitment.prudnaSummary';
export const REPORT_RECRUITMENT_PRUDNA_DETAIL_TITLE = 'title.report.recruitment.prudnaDetail';
export const REPORT_RECRUITMENT_MASS_PRUDNA_SUMMARY_TITLE = 'title.report.recruitment.mass.prudnaSummary';
export const REPORT_RECRUITMENT_MASS_PRUDNA_DETAIL_TITLE = 'title.report.recruitment.mass.prudnaDetail';
export const REPORT_NEWS_TITLE = 'title.report.NEWS';
export const REPORT_NEWS_AGENTS_TITLE = 'title.report.NEWS.agents';
export const REPORT_NEWS_COMMENTS_TITLE = 'title.report.NEWS.comments';
export const REPORT_NEWS_INFO_TITLE = 'title.report.NEWS.info';
export const REPORT_NEWS_AGENT_ACTIVITY_TITLE = 'title.report.NEWS.agentActivity';

//  These constants map to permission control, identical to permission enum defined below
export const REPORT_PERFORMANCE_PRODUCTION_TARGET_NAMESPACE = 'REPORT-PERFORMANCE-PRODUCTION-TARGET';
export const REPORT_INCENTIVE_RESULT_NAMESPACE = 'REPORT-INCENTIVE-RESULT';
export const REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_NAMESPACE = 'REPORT-PERFORMANCE-PRODUCTION-TARGET-MTD';
export const REPORT_PERFORMANCE_ADBU_TARGET_NAMESPACE = 'REPORT-PERFORMANCE-ADBU-TARGET';
export const REPORT_RECRUITMENT_PRUDNA_SUMMARY_NAMESPACE = 'REPORT-RECRUITMENT-PRUDNA-SUMMARY';
export const REPORT_RECRUITMENT_PRUDNA_DETAIL_NAMESPACE = 'REPORT-RECRUITMENT-PRUDNA-DETAIL';
export const REPORT_RECRUITMENT_EXAM_PASSING_REPORT_NAMESPACE = 'REPORT-RECRUITMENT-EXAM-PASSING-REPORT';
export const REPORT_NEWS_AGENTS_NAMESPACE = 'REPORT-NEWS-AGENTS';
export const REPORT_NEWS_COMMENTS_NAMESPACE = 'REPORT-NEWS-COMMENTS';
export const REPORT_NEWS_INFO_NAMESPACE = 'REPORT-NEWS-INFO';
export const REPORT_NEWS_AGENT_ACTIVITY_NAMESPACE = 'REPORT-NEWS-AGENT-ACTIVITY';
export const PRUdnaHomeEntryKeyPath = 'PRUdnaHome'; // DON'T edit this value on less you are knowing base app route changed

export enum AdminReportType {
  REPORT_PERFORMANCE_PRODUCTION_TARGET_READ = 'READ_ANY_REPORT-PERFORMANCE-PRODUCTION-TARGET',
  REPORT_PERFORMANCE_PRODUCTION_TARGET_CREATE = 'CREATE_ANY_REPORT-PERFORMANCE-PRODUCTION-TARGET',
  REPORT_PERFORMANCE_PRODUCTION_TARGET_UPDATE = 'UPDATE_ANY_REPORT-PERFORMANCE-PRODUCTION-TARGET',

  REPORT_INCENTIVE_RESULT_READ = 'READ_ANY_REPORT-INCENTIVE-RESULT',
  REPORT_INCENTIVE_RESULT_CREATE = 'CREATE_ANY_REPORT-INCENTIVE-RESULT',
  REPORT_INCENTIVE_RESULT_UPDATE = 'UPDATE_ANY_REPORT-INCENTIVE-RESULT',

  REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_READ = 'READ_ANY_REPORT-PERFORMANCE-PRODUCTION-TARGET-MTD',
  REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_CREATE = 'CREATE_ANY_REPORT-PERFORMANCE-PRODUCTION-TARGET-MTD',
  REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_UPDATE = 'UPDATE_ANY_REPORT-PERFORMANCE-PRODUCTION-TARGET-MTD',
  REPORT_PERFORMANCE_ADBU_TARGET_READ = 'READ_ANY_REPORT-PERFORMANCE-ADBU-TARGET',
  REPORT_PERFORMANCE_ADBU_TARGET_CREATE = 'CREATE_ANY_REPORT-PERFORMANCE-ADBU-TARGET',
  REPORT_PERFORMANCE_ADBU_TARGET_UPDATE = 'UPDATE_ANY_REPORT-PERFORMANCE-ADBU-TARGET',

  REPORT_RECRUITMENT_PRUDNA_SUMMARY_READ = 'READ_ANY_REPORT-RECRUITMENT-PRUDNA-SUMMARY',
  REPORT_RECRUITMENT_PRUDNA_SUMMARY_CREATE = 'CREATE_ANY_REPORT-RECRUITMENT-PRUDNA-SUMMARY',
  REPORT_RECRUITMENT_PRUDNA_SUMMARY_UPDATE = 'UPDATE_ANY_REPORT-RECRUITMENT-PRUDNA-SUMMARY',
  REPORT_RECRUITMENT_PRUDNA_DETAIL_READ = 'READ_ANY_REPORT-RECRUITMENT-PRUDNA-DETAIL',
  REPORT_RECRUITMENT_PRUDNA_DETAIL_CREATE = 'CREATE_ANY_REPORT-RECRUITMENT-PRUDNA-DETAIL',
  REPORT_RECRUITMENT_PRUDNA_DETAIL_UPDATE = 'UPDATE_ANY_REPORT-RECRUITMENT-PRUDNA-DETAIL',
  REPORT_RECRUITMENT_EXAM_PASSING_REPORT_READ = 'READ_ANY_REPORT-RECRUITMENT-EXAM-PASSING-REPORT',

  REPORT_NEWS_AGENTS_READ = 'READ_ANY_REPORT-NEWS-AGENTS',
  REPORT_NEWS_AGENTS_CREATE = 'CREATE_ANY_REPORT-NEWS-AGENTS',
  REPORT_NEWS_AGENTS_UPDATE = 'UPDATE_ANY_REPORT-NEWS-AGENTS',
  REPORT_NEWS_COMMENTS_READ = 'READ_ANY_REPORT-NEWS-COMMENTS',
  REPORT_NEWS_COMMENTS_CREATE = 'CREATE_ANY_REPORT-NEWS-COMMENTS',
  REPORT_NEWS_COMMENTS_UPDATE = 'UPDATE_ANY_REPORT-NEWS-COMMENTS',
  REPORT_NEWS_INFO_READ = 'READ_ANY_REPORT-NEWS-INFO',
  REPORT_NEWS_INFO_CREATE = 'CREATE_ANY_REPORT-NEWS-INFO',
  REPORT_NEWS_INFO_UPDATE = 'UPDATE_ANY_REPORT-NEWS-INFO',
  REPORT_NEWS_AGENT_ACTIVITY_READ = 'READ_ANY_REPORT-NEWS-AGENT-ACTIVITY',
  REPORT_NEWS_AGENT_ACTIVITY_CREATE = 'CREATE_ANY_REPORT-NEWS-AGENT-ACTIVITY',
  REPORT_NEWS_AGENT_ACTIVITY_UPDATE = 'UPDATE_ANY_REPORT-NEWS-AGENT-ACTIVITY',
}
