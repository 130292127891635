import React, { FC, useState } from 'react';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { NewsArticleListParam, fetchNewsArticleList, fetchNewsCategoryList } from '../../../network/newsCrud';
import { useDataProvider } from 'src/app/common/utils';
import { NewsArticlePaginateList, NewsCategoryPaginateList } from '../../../types/news-type';
import { useDispatch } from 'react-redux';
import NewsArticleList from './components/NewsArticleList';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { useServerLocale } from 'src/app/i18n';
import { LoadingComponent } from 'src/app/common/components/loading/loading.component';
import { useNewsListingPage } from './news-listing-page.hook';

type NewsListingPageProps = {} & ParamsProps;

const initialState: NewsArticleListParam = {
  limit: 5,
  page: 1,
  categoryId: '',
  search: '',
  isHeadline: '',
  isPublished: '',
  isArchived: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): NewsArticleListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 5,
        categoryId: initialParams.categoryId ?? undefined,
        search: initialParams.search ?? undefined,
        isHeadline: initialParams.isHeadline ?? undefined,
        isPublished: initialParams.isPublished ?? undefined,
        isArchived: initialParams.isArchived ?? undefined,
      }
    : initialState;
};

export const newsArticleFilterKeys = ['categoryId', 'search', 'isHeadline', 'isPublished'];

const NewsListingPage: FC<NewsListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const locale = useServerLocale();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [filterState, setFilterState] = useState<NewsArticleListParam>(paramsInitiator(initialParams));

  const [newsArticleList, setNewsArticleList] = useState<NewsArticlePaginateList>();
  const { isLoading, refreshData } = useDataProvider<NewsArticlePaginateList>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchNewsArticleList(filterState, dispatch);
      } catch (err) {}
    },
    setNewsArticleList,
    false,
  );

  const {
    segmentList,
    segmentOption,
    categoryList,
    categoryOption,
    loadingSegmentList,
    loadingCategory,
    enabledSubsegment,
    getSubsegmentNames,
  } = useNewsListingPage();
  if (loadingCategory || loadingSegmentList) {
    return <LoadingComponent />;
  }

  return (
    <>
      <PruFilter
        title={Translation('newsArticle.filter')}
        itemDef={[
          {
            type: PruFilterItemType.MULTIPLE_DROPDOWN,
            style: filterWidth,
            field: 'categoryId',
            initialValue: categoryList,
            displayName: Translation('newsArticle.common.category'),
            choices: categoryOption,
          },

          {
            type: PruFilterItemType.DROPDOWN,
            style: filterWidth,
            field: 'isHeadline',
            initialValue: filterState.isHeadline,
            displayName: Translation('newsArticle.common.isHeadline'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              { displayName: Translation('newsArticle.common.true'), value: 'true' },
              { displayName: Translation('newsArticle.common.false'), value: 'false' },
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: filterWidth,
            field: 'isPublished',
            initialValue: filterState.isPublished,
            displayName: Translation('newsArticle.common.isPublished'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              { displayName: Translation('newsArticle.common.true'), value: 'true' },
              { displayName: Translation('newsArticle.common.false'), value: 'false' },
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: filterWidth,
            field: 'isArchived',
            initialValue: filterState.isArchived,
            displayName: Translation('newsArticle.common.isArchived'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              { displayName: Translation('newsArticle.common.true'), value: 'true' },
              { displayName: Translation('newsArticle.common.false'), value: 'false' },
            ],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: filterWidth,
            field: 'search',
            initialValue: filterState.search,
            displayName: Translation('newsArticle.common.keyword'),
          },
          {
            type: PruFilterItemType.MULTIPLE_DROPDOWN,
            style: filterWidth,
            field: 'segments',
            initialValue: segmentList,
            displayName: Translation('newsArticle.common.segment'),
            choices: segmentOption,
          },
          {
            type: PruFilterItemType.DATE_TIME_RANGE,
            fieldFrom: 'publishDateFrom',
            fieldTo: 'publishDateTo',
            initialDateFrom: filterState.publishDateFrom,
            initialDateTo: filterState.publishDateTo,
            displayName: Translation('newsArticle.common.publishDate'),
          },
        ]}
        onChangeFilter={(data) => {
          // console.log('data=====:', JSON.stringify(data));
          setFilterState({
            ...filterState,
            categoryId: data.categoryId,
            // categoryId: data.categoryId ? data.categoryId[0] : undefined,
            search: data.search || undefined,
            isPublished: data.isPublished || undefined,
            isHeadline: data.isHeadline || undefined,
            isArchived: data.isArchived || undefined,
            publishDateFrom: data.publishDateFrom,
            publishDateTo: data.publishDateTo,
            segments: data.segments,
          });
        }}
        fetchData={refreshData}
      />
      <NewsArticleList
        isLoading={isLoading}
        newsArticleList={newsArticleList}
        categiryList={categoryOption}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        getSubsegmentNames={enabledSubsegment ? (subIds) => getSubsegmentNames(subIds) : undefined}
      />
    </>
  );
};

export default NewsListingPage;

const filterWidth = { width: 200 };
