import React, { FC, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from 'src/app/layout';
import { RootState } from '../../../../redux/store';
import { logout } from '@pruforce/common-adminweb-sdk';
import { revokeRefreshToken } from '../_redux/authCrud';

const Logout: FC<{ hasAuthToken: boolean }> = ({ hasAuthToken }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const logoutFunc = async () => {
      const refreshToken = window.localStorage.getItem('refreshToken');
      if (refreshToken) {
        await revokeRefreshToken(refreshToken).catch(() => {
          // this catch is required, to avoid page keep loading when revokeRefreshToken api error
        });
      }
      dispatch(logout());
    };
    logoutFunc();
  }, [dispatch]);

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
};

export default connect((state: RootState) => ({ hasAuthToken: !!state.auth.token }))(Logout);
