import React, { FC, memo, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControl, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './target-applicant.style';
import { dataConvert, useTargetApplicant } from './target-applicant.hook';
import { DataUploaderComponent } from 'src/app/common/components/data-uploader/data-uploader.component';
import TargetApplicationSection from 'src/app/common/components/TargetApplicantSection';
import { EnrolmentType } from 'src/app/common/types';
import { getSubSegmentConfig, NewsArticleFormAction, NewsArticleFormState } from '../NewsDetail.util';
import { ErrorState } from 'src/app/common/utils/form-error-utils';
import { NewsArticleFormMode } from 'src/app/modules/News/types/news-type';

export type TargetApplicantProps = ParamsProps & {
  enrolmentType: EnrolmentType;
  onToggleDesignation: (value: string) => void;
  modifyTargetApplicant: (
    option:
      | 'enrolmentType'
      | 'eligibleAgents'
      | 'eligibleDesignations'
      | 'excludedAgents'
      | 'agentListFileName'
      | 'agentListUploaded',
    value: any,
  ) => void;
  formState: NewsArticleFormState;
  formDispatch: React.Dispatch<NewsArticleFormAction>;
  formMode: NewsArticleFormMode;
  errorState?: ErrorState;
  disabled?: boolean;
  disabledByDesignation?: boolean;
  disabledByUploadAgent?: boolean;
};

export const TargetApplicantComponent: React.FC<TargetApplicantProps> = memo((props: TargetApplicantProps) => {
  const {
    modifyTargetApplicant,
    enrolmentType,
    formState,
    errorState,
    formDispatch,
    formMode,
    disabled = false,
    disabledByDesignation = false,
    disabledByUploadAgent = false,
  } = props;
  // i18n
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // get config
  const { enabledSubsegment } = useMemo(() => {
    return getSubSegmentConfig();
  }, []);

  // custom-hook
  const {
    isShowAgentDownload,
    isShowExcludeDownload,
    isShowIncludeDownload,
    getUploadedAgents,
    getExcludeAgents,
    getIncludeAgents,
    validateUploadedData,
  } = useTargetApplicant(props);
  return (
    <>
      <FormControl>
        <RadioGroup
          aria-label="receiverType"
          name="receiverType"
          value={enrolmentType}
          onChange={(e) => {
            modifyTargetApplicant('enrolmentType', e.target.value);
          }}
          className="flex-row tw-mb-2"
        >
          <FormControlLabel
            key="by-segement"
            value={EnrolmentType.BY_SEGMENT}
            control={<Radio disabled={disabled || disabledByDesignation} />}
            label={t('targetApplicantSection.common.target_applicant_type.designations')}
          />

          <FormControlLabel
            key="target-reveivers"
            value={EnrolmentType.SPECIFIC}
            control={<Radio disabled={disabled || disabledByUploadAgent} />}
            label={t('resource_targetType_byList')}
          />
        </RadioGroup>
        <span
          className={
            // display note why one of the RadioGroup disabled
            enabledSubsegment &&
            (disabledByUploadAgent || disabledByDesignation) &&
            !(disabledByUploadAgent && disabledByDesignation) // view mode: both disabled, hide the note
              ? 'tw-text-pink-500 tw--mt-4 tw-mb-4'
              : 'tw-hidden'
          }
        >
          {t('segment.disabled.type')}
        </span>
      </FormControl>
      <div className={formState.enrolmentType === EnrolmentType.BY_SEGMENT ? '' : 'tw-hidden'}>
        <TargetApplicationSection
          isDisabled={disabled || disabledByDesignation}
          eligibleZones={[]}
          enableZones={false}
          isNew={false} // no selecting all Designation by default
          eligibleDesignations={formState.eligibleDesignations || []}
          eligibleAgents={formState.eligibleAgents || []}
          excludedAgents={formState.excludedAgents || []}
          // agentListFileName={formState.agentListFileName}
          // onModifyTarget={modifyTargetApplicant}
          onToggleDesignation={(value) => {
            // formDispatch({ type: 'eligibleDesignations', payload: { value } });
            modifyTargetApplicant('eligibleDesignations', value);
          }}
        />
        <div className={styles.rowContainer}>
          <div className={styles.fieldContainer}>
            <span>{t('receiver.include.text')} : </span>
          </div>
          <div className="tw-flex-grow">
            <DataUploaderComponent
              onChange={(data) => {
                modifyTargetApplicant('eligibleAgents', dataConvert(data));
              }}
              validateData={validateUploadedData}
              onDownload={isShowIncludeDownload ? getIncludeAgents : undefined}
              displayFileName="extra-include-agent-list"
              templateData={sampleData}
              disabled={disabled || disabledByDesignation}
            />
          </div>
        </div>
        <div className={styles.rowContainer}>
          <div className={styles.fieldContainer}>
            <span>{t('receiver.exclude.text')} : </span>
          </div>
          <div>
            <DataUploaderComponent
              onChange={(data) => {
                modifyTargetApplicant('excludedAgents', dataConvert(data));
              }}
              validateData={validateUploadedData}
              onDownload={isShowExcludeDownload ? getExcludeAgents : undefined}
              displayFileName="extra-exclude-agent-list"
              templateData={sampleData}
              disabled={disabled || disabledByDesignation}
            />
          </div>
        </div>
      </div>
      <div className={formState.enrolmentType === EnrolmentType.SPECIFIC ? '' : 'tw-hidden'}>
        <DataUploaderComponent
          onChange={(data) => {
            modifyTargetApplicant('agentListUploaded', dataConvert(data));
          }}
          validateData={validateUploadedData}
          onDownload={isShowAgentDownload ? getUploadedAgents : undefined}
          displayFileName={'upload-agent-list'}
          templateData={sampleData}
          disabled={disabled}
        />
      </div>
    </>
  );
});
// for download-template excel
const sampleData = [{ agentCode: '00010001' }, { agentCode: '00010002' }];
