import { PaginateList } from '../../../common/types/common-types';
import { SegmentNameItem } from '../../Config/types/agent-segment-type';

export enum NewsCategoryFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum NewsArticleFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum NewsArticleStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Published = 'Published',
}

export type CategoryName = Record<string, string>;

export type NewsCategoryEmailItem = {
  name: string;
  address: string;
};

export type NewsCategoryItem = {
  _id: string;
  name: CategoryName;
  emails: NewsCategoryEmailItem[];
  color?: string;
  order?: Number;
  createdAt: Date;
  updatedAt: Date;
  key?: string;
  maxNoOfHeadlines: number;
  maxArchiveDays: number;
  active: boolean;
  // i18n title
  [key: string]: string | unknown;
};
// create or edit dto
export type NewsCategoryEditItem = {
  emails?: NewsCategoryEmailItem[];
  color?: string;
  maxNoOfHeadlines: number;
  maxArchiveDays: number;
  name?: SegmentNameItem;
  active?: boolean;
};

export type NewsTitleItem = Record<string, string>;

export type NewsContentItem = Record<string, string>;

export type NewsContentWordcountItem = Record<string, number>;

export type NewsAttachmentItem = {
  blobId: string;
  filename: string;
  url?: string;
};

export type NewsImageItem = {
  blobId: string;
  type?: string;
};

export type NewsArticleItem = {
  newsId: string;
  categoryId: string;
  publishDate?: Date;
  archiveDate?: Date;
  noArchiveDate?: boolean;
  title: NewsTitleItem;
  content: NewsContentItem;
  likes: number;
  bookmarked: number;
  comments: number;
  readCount: number;
  isSuggested: boolean;
  isHeadline: boolean;
  category: NewsCategoryItem;
  createdAt: Date;
  updatedAt: Date;
  banner?: NewsImageItem;
  thumbnail?: NewsImageItem;
  tags?: string[];
  wordCount?: NewsContentWordcountItem;
  enquiryEmail?: string;
  createdBy?: string;
  updatedBy?: string;
  attachments?: NewsAttachmentItem[];
  isPublished?: boolean;
  status?: string;
  eligibleAgents: string[];
  excludedAgents: string[];
  agentListFileName?: string;
  enrolmentType: EnrolmentType;
  eligibleSegments: {
    designation: string[];
  };
  newsType: newsType;
  segments: string[];
  subSegments?: string[];
  segmentNames: Record<string, string>[];
};

export type NewsArticleEditItem = {
  categoryId?: string;
  publishDate?: Date;
  archiveDate?: Date | null;
  noArchiveDate: boolean;
  title: NewsTitleItem;
  content?: NewsContentItem;
  isSuggested: boolean;
  banner?: NewsImageItem;
  thumbnail?: NewsImageItem;
  tags?: string[];
  wordCount?: NewsContentWordcountItem;
  enquiryEmail?: string;
  attachments?: NewsAttachmentItem[];
  status?: string;
  enrolmentType?: EnrolmentType;
  eligibleSegments?: {
    designation: string[];
  };
  eligibleAgents?: string[];
  excludedAgents?: string[];
  agentListFileName?: string;
  newsType: newsType;
  segments: string[];
  subSegments?: string[];
  segmentNames?: Record<string, string>[];
  isHeadline: boolean;
};

export type NewsHeadlineItem = {
  newsId: string;
  categoryId?: string;
  publishDate?: Date;
  archiveDate?: Date;
  title?: NewsTitleItem;
  content?: NewsContentItem;
  likes?: number;
  bookmarked?: number;
  comments?: number;
  isSuggested?: boolean;
  isHeadline?: boolean;
  category?: NewsCategoryItem;
  createdAt?: Date;
  updatedAt?: Date;
  banner?: NewsImageItem;
  thumbnail?: NewsImageItem;
  tags?: string[];
  wordCount?: NewsContentWordcountItem;
  enquiryEmail?: string;
  createdBy?: string;
  updatedBy?: string;
  attachments?: NewsAttachmentItem[];
  triggerPush?: boolean;
  readCount?: number;
  status?: string;
};

export type NewsHeadlineItemList = {
  headlines?: NewsHeadlineItem[];
};

export type NewsCategoryPaginateList = PaginateList<NewsCategoryItem> & {
  categories: NewsCategoryItem[];
  totalRecords: number;
};

export type NewsArticlePaginateList = PaginateList<NewsArticleItem> & {
  newsList: NewsArticleItem[];
  totalRecords: number;
};

export type NewsHeadlinePaginateList = PaginateList<NewsHeadlineItem> & {
  newsList: NewsHeadlineItem[];
  totalRecords: number;
};

export enum EnrolmentType {
  BY_SEGMENT = 'SEGMENTS',
  SPECIFIC = 'AGENTS',
}

export enum newsType {
  GENERAL = 'GENERAL',
  SEGMENTED = 'SEGMENTED',
}

export type BulkUpdateCategoryData = {
  newsIds: string[];
  categoryId: string;
};
