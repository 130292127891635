import React, { useState, useEffect, ChangeEvent, CSSProperties, useImperativeHandle, useMemo, memo } from 'react';
// import { makeStyles } from 'tss-react/mui';
import { Button, LinearProgress } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';

import { get } from 'lodash';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './data-uploader.style';
import { defaultFileName, useDataUploader } from './data-uploader.hook';
import { LoadingButton } from '@mui/lab';

export type JsonObject = {
  [key: string]: number | string | boolean;
};

export type ComponentProps = ParamsProps & {
  //
  /**
   * Get list of data that have been uploaded. will default show download link if this prop is set.
   * @returns JsonObject[]
   */
  onDownload?: () => JsonObject[] | Promise<JsonObject[]>;
  onChange?: (data: JsonObject[]) => void; // upload file change callback fn
  validateData?: (data: JsonObject[]) => boolean; // check uploaded data is valid or not, reject user upload if false.
  disabled?: boolean;
  uploadTips?: string; // extra string display to the right of upload button
  maxFileSize?: number; // in MB
  maxRowCount?: number; //
  displayFileName?: string; // the download-file link display name, default: uploaded_file
  templateData?: JsonObject[]; // the download template data, will auto generate download template link
};
/** only support upload files: excel (.xlsx),
 * WILL NOT upload file to server, only process the data in the local
 * */
export const DataUploaderComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { disabled = false, uploadTips, displayFileName, templateData } = props;
  // i18n
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { handleDownload, isUploading, isShowDownload, limit, onUploadFile, deleteFile, handleDownloadTemplate } =
    useDataUploader(props);

  return (
    <>
      <div className={!isUploading && !isShowDownload ? 'tw-mb-2' : 'tw-hidden'}>
        <LoadingButton
          component="label"
          loading={isUploading}
          disabled={disabled}
          role={undefined}
          variant="contained"
          color="secondary"
          tabIndex={-1}
          startIcon={<AddIcon fontSize="small" />}
        >
          {t('app.button.upload')}
          <input className="tw-hidden" type="file" id="input" onChange={onUploadFile} />
        </LoadingButton>
        <span className="tw-mx-2">{limit}</span>
        {uploadTips && <span className="tw-mx-2">{uploadTips}</span>}
      </div>
      {/** download uploaded-data-link */}
      <div className={isShowDownload && !isUploading ? 'tw-mb-2' : 'tw-hidden'}>
        <a
          onClick={handleDownload}
          title={t('app.button.download')}
          className="tw-underline tw-text-blue-600 hover:tw-text-blue-700"
        >
          {displayFileName || defaultFileName}
        </a>
        {!disabled && <Delete className={styles.closeIcon} onClick={deleteFile} />}
      </div>
      {/** download template-link */}
      <div className={templateData ? 'tw-mb-2' : 'tw-hidden'}>
        <a onClick={handleDownloadTemplate} title={t('download_template_button')} className="tw-underline">
          {t('download_template_button')}
        </a>
      </div>
    </>
  );
});
