import React, { FC, useState, useEffect, useRef } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';
import { Button, Dialog, IconButton, Tooltip, Select, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  NewsArticlePaginateList,
  NewsArticleItem,
  NewsHeadlineItem,
  NewsCategoryItem,
} from 'src/app/modules/News/types/news-type';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate, useJWT, isPermitted } from 'src/app/common/utils';
import { NEWS_BASE_PATH, NEWS_ARTICLE_PATH, AdminNewsPermissionType } from 'src/app/modules/News/constants';
import { useServerLocale, getAvailableServerLocale } from 'src/app/i18n';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { setNewsAsHeadline, unsetNewsHeadline, bulkUpdateNewsCategory } from 'src/app/modules/News/network/newsCrud';
import { useDispatch } from 'react-redux';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { map, isEmpty, isNil } from 'lodash';
type NewsArticleListProps = {
  isLoading: boolean;
  newsArticleList?: NewsArticlePaginateList;
  categiryList?: { displayName: string; value: string }[];
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  getSubsegmentNames?: (ids: string[]) => (string | null)[];
};

const LargerFontTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    fontSize: 12,
  },
}));

const useStyles = makeStyles()((theme) => ({
  dialogContainer: {
    padding: '20px',
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorContainer: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  errorHeader: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  errorFooter: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  segmentContainer: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  diglogContent: {
    padding: '0 20px',
    marginBottom: 20,
    minWidth: 512,
  },
  dialogTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '32px',
  },
  updateCategoryDescription: {
    width: 362,
  },
}));

const NewsArticleList: FC<NewsArticleListProps> = ({
  isLoading,
  newsArticleList,
  categiryList,
  onRefresh,
  onChangePage,
  getSubsegmentNames,
}) => {
  const dispatch = useDispatch();
  const locale = useServerLocale();
  const availableLocales = getAvailableServerLocale();
  const history = useHistory();
  const intl = useIntl();
  const { classes } = useStyles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [headlineDialogId, setHeadlineDialogId] = useState<string>('');
  const [headlineDialogData, setHeadlineDialogData] = useState<NewsArticleItem>();
  const [headlineIsNotPublshedDialog, setHeadlineIsNotPublshedDialog] = useState<boolean>(false);
  const [headlineIsNotSetForAllAgentDialog, setHeadlineIsNotSetForAllAgentDialog] = useState<boolean>(false);
  let updateHeadlinePermission = false;
  let updateNewsPermission = false;
  let createNewsPermission = false;
  const jwt = useJWT() || {};
  const [selectedRows, setSelectedRows] = useState<NewsArticleItem[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');
  const [showSelectCategoryDialog, setShowSelectCategoryDialog] = useState<boolean>(false);
  const [selectingCategory, setSelectingCategory] = useState<boolean>(false);
  const resetSelectedRowsFuncRef = useRef<any>();

  if (jwt.permissions) {
    const jwtPermission = map(jwt.permissions, (_, key) => key);
    updateNewsPermission = isPermitted([AdminNewsPermissionType.NEWS_ARTICLE_UPDATE], jwtPermission);
    createNewsPermission = isPermitted([AdminNewsPermissionType.NEWS_ARTICLE_CREATE], jwtPermission);
    updateHeadlinePermission = isPermitted(
      [AdminNewsPermissionType.NEWS_HEADLINE_CREATE, AdminNewsPermissionType.NEWS_HEADLINE_UPDATE],
      jwtPermission,
    );
  }
  const copyIdClicked = async (rowId: string) => {
    navigator.clipboard && navigator.clipboard.writeText(rowId);
  };

  const onSetHeadlineClicked = (selectedData: NewsArticleItem) => {
    setHeadlineDialogId(selectedData.newsId);
    setHeadlineDialogData(selectedData);
  };

  const checkIfAllAgentAreSelectedForTarget = (data: NewsArticleItem) => {
    if (data.agentListFileName != '') {
      return false;
    } else if (data.eligibleAgents.length > 0 || data.excludedAgents.length > 0) {
      return false;
    } else if (data.eligibleSegments.designation.length < 12) {
      return false;
    } else {
      return true;
    }
  };

  const confirmSetHeadline = async (isTriggerPush: boolean) => {
    //Prompt user cannot send push as article is unpublished
    if (isTriggerPush && !headlineDialogData?.isPublished) {
      setHeadlineIsNotPublshedDialog(true);
    } else if (isTriggerPush && headlineDialogData && !checkIfAllAgentAreSelectedForTarget(headlineDialogData)) {
      setHeadlineIsNotSetForAllAgentDialog(true);
    } else {
      setHeadlineIsNotPublshedDialog(false);
      setHeadlineIsNotSetForAllAgentDialog(false);
      try {
        const data: NewsHeadlineItem = {
          newsId: headlineDialogId,
          triggerPush: isTriggerPush,
        };
        await setNewsAsHeadline(data);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: Translation('newsArticle.common.setAsHeadlineSuccess'),
            },
          ]),
        );
        setHeadlineDialogId('');
        onRefresh();
      } catch (err: any) {
        setErrorDialog((prev) => ({
          ...prev,
          show: true,
          message: err.message ?? Translation('common.serviceError'),
        }));
        setHeadlineDialogId('');
        onRefresh();
      }
    }
  };

  const [errorDialog, setErrorDialog] = useState({
    show: false,
    message: '',
  });

  const onUnsetHeadlineClicked = async (newsId: string) => {
    try {
      await unsetNewsHeadline(newsId, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: Translation('newsArticle.common.unsetHeadlineSuccess'),
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const clearSelectedRows = () => {
    resetSelectedRowsFuncRef.current?.();
    setSelectedRows([]);
  };

  const hideSelectCategoryDialog = () => {
    setShowSelectCategoryDialog(false);
    setSelectingCategory(false);
    setSelectedCategoryId('');
  };

  const bulkUpdateCategory = async () => {
    const newsIds = selectedRows.map((item) => item.newsId);
    const data = {
      newsIds,
      categoryId: selectedCategoryId,
    };
    bulkUpdateNewsCategory(data)
      .then(() => {
        clearSelectedRows();
        onRefresh();
      })
      .catch((err) => {
        setErrorDialog({
          show: true,
          message: err.message ?? Translation('common.serviceError'),
        });
      })
      .finally(() => {
        setSelectedCategoryId('');
      });
    setShowSelectCategoryDialog(false);
    setSelectingCategory(false);
  };

  return (
    <>
      <PruTable
        title={Translation('newsArticle.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('newsArticle.common.newNewsArticle'),
            onClick: () => {
              history.push(`${NEWS_BASE_PATH}${NEWS_ARTICLE_PATH}/create`);
            },
            condition: () => createNewsPermission,
          },
          {
            color: 'primary',
            title: 'BULK UPDATE CATEGORY',
            onClick: () => {
              setSelectingCategory(true);
              setShowSelectCategoryDialog(true);
            },
            condition: () => selectedRows.length > 0 && updateNewsPermission,
          },
        ]}
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Article Detail',
            onClick: (row) => {
              history.push(`${NEWS_BASE_PATH}${NEWS_ARTICLE_PATH}/view/${row.newsId}`);
            },
            condition: () => updateNewsPermission,
          },
          {
            title: Translation('newsArticle.common.setAsHeadline'),
            tooltipText: 'Set as Headline',
            onClick: (row) => onSetHeadlineClicked(row),
            condition: (row) => !!(!row.isHeadline && updateHeadlinePermission),
          },
          {
            title: Translation('newsArticle.common.unsetHeadline'),
            tooltipText: 'Unset Headline',
            onClick: (row) => onUnsetHeadlineClicked(row.newsId),
            condition: (row) => !!(row.isHeadline && updateHeadlinePermission),
          },
        ]}
        columnDef={[
          {
            isId: true,
            keyIndex: 'newsId',
            align: 'center',
            displayName: Translation('component.formLabel.id'),
            renderData: (row) => (
              <LargerFontTooltip title={row.newsId} style={{ fontSize: 20 }} placement="left">
                <IconButton
                  onClick={() => {
                    copyIdClicked(row.newsId);
                  }}
                >
                  <FileCopyIcon />
                </IconButton>
              </LargerFontTooltip>
            ),
          },
          {
            keyIndex: 'category',
            align: 'center',
            displayName: Translation('newsArticle.common.category'),
            renderData: (row) => row.category?.name[locale] || '-',
          },
          {
            keyIndex: 'categoryStatus',
            align: 'center',
            displayName: `${Translation('newsArticle.common.category')} ${Translation('component.formLabel.status')}`,
            renderData: (row) => {
              const i18nKey = row.category?.active ? 'dropdown_status_active' : 'dropdown_status_inactive';
              return Translation(i18nKey);
            },
          },
          {
            keyIndex: `title${availableLocales[0]}`,
            align: 'center',
            displayName: Translation(`newsArticle.common.title.${availableLocales[0]}`),
            renderData: (row) => (row.title ? row.title[availableLocales[0]] : '-') || '-',
          },
          {
            keyIndex: 'publishDate',
            align: 'center',
            displayName: Translation('newsArticle.common.publishDate'),
            renderData: (row) => (row.publishDate ? getDefaultDisplayDate(row.publishDate, 'datetime') : '-'),
          },
          {
            keyIndex: 'readCount',
            align: 'center',
            displayName: Translation('newsArticle.common.readCount'),
            renderData: (row) => String(row.readCount ? row.readCount : 0),
          },
          {
            keyIndex: 'likes',
            align: 'center',
            displayName: Translation('newsArticle.common.likes'),
            renderData: (row) => String(row.likes ? row.likes : 0),
          },
          {
            keyIndex: 'bookmarked',
            align: 'center',
            displayName: Translation('newsArticle.common.bookmarked'),
            renderData: (row) => String(row.bookmarked ? row.bookmarked : 0),
          },
          {
            keyIndex: 'comments',
            align: 'center',
            displayName: Translation('newsArticle.common.comments'),
            renderData: (row) => String(row.comments ? row.comments : 0),
          },
          {
            keyIndex: 'newsType',
            align: 'center',
            displayName: Translation('newsArticle.common.newsType'),
            renderData: (row) => String(row.newsType ? row.newsType : ''),
          },
          {
            keyIndex: 'segments',
            align: 'center',
            displayName: Translation('newsArticle.common.segment'),
            renderData: (row) =>
              !row.segmentNames || row.segmentNames.length <= 0 ? (
                '-'
              ) : (
                <div>
                  {row.segmentNames.map((item) => (
                    <label className={classes.segmentContainer} key={item[locale]}>
                      {item[locale]}
                    </label>
                  ))}
                </div>
              ),
          },
          {
            keyIndex: 'subsegments',
            align: 'center',
            displayName: Translation('subsegment'),
            renderData: (row) => {
              if (!getSubsegmentNames) {
                return '-';
              }
              const arr = getSubsegmentNames(row.subSegments || []);
              const names = arr.map((name, index) => (
                <label className={classes.segmentContainer} key={index}>
                  {name}
                </label>
              ));
              return <>{names}</>;
            },
            hidden: !getSubsegmentNames, //typeof getSubsegmentNames !== 'function',
          },
          {
            keyIndex: 'isHeadline',
            align: 'center',
            displayName: Translation('newsArticle.common.isHeadline'),
            renderData: (row) =>
              row.isHeadline ? (
                <IconButton>
                  <CheckBoxIcon />
                </IconButton>
              ) : (
                ''
              ),
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('newsArticle.common.createdAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.createdAt), 'datetime'),
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('newsArticle.common.updatedAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.updatedAt), 'datetime'),
          },
          {
            keyIndex: 'updatedBy',
            align: 'center',
            displayName: Translation('newsArticle.common.updatedBy'),
            renderData: (row) => (row.updatedBy ? row.updatedBy : ''),
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('newsArticle.common.status'),
            renderData: (row) => (row.status ? row.status : ''),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={newsArticleList?.newsList}
        totalPages={newsArticleList?.totalPages}
        totalRecords={newsArticleList?.totalRecords}
        onChangePage={onChangePage}
        currentSelectedRow={(data, parent, onResetRowSelected) => {
          setSelectedRows(data);
          resetSelectedRowsFuncRef.current = onResetRowSelected;
        }}
      />
      <Dialog open={!!headlineDialogId} onClose={() => setHeadlineDialogId('')}>
        <div className={classes.dialogContainer}>{Translation('newsArticle.common.confirmSetHeadline')}</div>
        <div className={classes.btnContainer}>
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="secondary"
            onClick={() => confirmSetHeadline(true)}
          >
            {Translation('global.text.yes')}
          </Button>
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="secondary"
            onClick={() => confirmSetHeadline(false)}
          >
            {Translation('global.text.no')}
          </Button>
          <Button variant="contained" color="inherit" onClick={() => setHeadlineDialogId('')}>
            {Translation('global.text.cancel')}
          </Button>
        </div>
      </Dialog>
      <Dialog open={!!headlineIsNotPublshedDialog} onClose={() => setHeadlineIsNotPublshedDialog(false)}>
        <div className={classes.dialogContainer}>{Translation('newsArticle.common.selectedNewsIsNotPublished')}</div>
        <div className={classes.btnContainer}>
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="inherit"
            onClick={() => confirmSetHeadline(false)}
          >
            {Translation('global.text.okay')}
          </Button>
        </div>
      </Dialog>
      <Dialog open={!!headlineIsNotSetForAllAgentDialog} onClose={() => setHeadlineIsNotSetForAllAgentDialog(false)}>
        <div className={classes.dialogContainer}>{Translation('newsArticle.common.selectedNewsIsNotForAllAgent')}</div>
        <div className={classes.btnContainer}>
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="inherit"
            onClick={() => confirmSetHeadline(false)}
          >
            {Translation('global.text.okay')}
          </Button>
        </div>
      </Dialog>
      {/** select category dialog */}
      <Dialog open={!!showSelectCategoryDialog} onClose={() => setShowSelectCategoryDialog(false)}>
        <div className={classes.dialogContainer}>
          {selectingCategory ? (
            <div className={classes.dialogTitle}>{Translation('newsArticle.dialog.updateCategory.title')}</div>
          ) : (
            <div className={classes.updateCategoryDescription}>
              {Translation('newsArticle.dialog.updateCategory.description')}
            </div>
          )}
        </div>
        {selectingCategory ? (
          <>
            <div className={classes.diglogContent}>
              <Select
                fullWidth
                value={selectedCategoryId}
                onChange={(e) => {
                  setSelectedCategoryId(e.target.value as string);
                }}
              >
                {categiryList?.map((category) => {
                  return (
                    <MenuItem
                      key={category.value}
                      value={category.value}
                      onClick={() => setSelectedCategoryId(category.value)}
                    >
                      {category.displayName}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className={classes.btnContainer}>
              <Button
                style={{ marginRight: 20 }}
                variant="contained"
                color="inherit"
                onClick={hideSelectCategoryDialog}
              >
                {Translation('global.text.cancel')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={!selectedCategoryId}
                onClick={() => setSelectingCategory(false)}
              >
                {Translation('global.text.confirm')}
              </Button>
            </div>
          </>
        ) : (
          <div className={classes.btnContainer}>
            <Button style={{ marginRight: 20 }} variant="contained" color="inherit" onClick={hideSelectCategoryDialog}>
              {Translation('global.text.no')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={!selectedCategoryId}
              onClick={() => bulkUpdateCategory()}
            >
              {Translation('global.text.yes')}
            </Button>
          </div>
        )}
      </Dialog>

      {/** Error Dialog */}
      <Dialog
        onClose={() => {
          setErrorDialog((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        open={errorDialog.show}
      >
        <div className={classes.errorContainer}>
          <div>{errorDialog.message}</div>
          <div className={classes.errorFooter}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setErrorDialog((prev) => ({
                  ...prev,
                  show: false,
                }));
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default NewsArticleList;
