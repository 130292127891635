import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    borderRadius: 5,
    margin: theme.spacing(1),
  },
  btn: { marginRight: '1rem' },
  closeIcon: {
    verticalAlign: 'middle',
    cursor: 'pointer',
    color: '#666',
  },
}));
