import { useLang } from '../index'; //src/app/i18n
import { find } from 'lodash';
import { toAbsoluteUrl } from '../../common/utils';
import { GlobalHelper } from '../../common/helpers/GlobalHelpers';
import { RegionLocale, I18nDataItem, I18nGenericData } from '../types/i18n-types';

type LocaleContent = {
  name: string;
  flag: string;
};

export const regionLocaleMap: Record<RegionLocale, LocaleContent> = {
  [RegionLocale.ENGLISH]: {
    name: 'navBar.lang.en',
    flag: toAbsoluteUrl('/media/svg/flags/226-united-states.svg'),
  },
  [RegionLocale.TRADITIONAL_CHINESE]: {
    name: 'navBar.lang.zh-Hant',
    flag: toAbsoluteUrl('/media/svg/flags/183-hong-kong.svg'),
  },
  [RegionLocale.SIMPLIFIED_CHINESE]: {
    name: 'navBar.lang.zh-Hans',
    flag: toAbsoluteUrl('/media/svg/flags/015-china.svg'),
  },
  [RegionLocale.BAHASA]: {
    name: 'navBar.lang.id',
    flag: toAbsoluteUrl('/media/svg/flags/004-indonesia.svg'),
  },
  [RegionLocale.SPANISH]: {
    name: 'navBar.lang.es',
    flag: toAbsoluteUrl('/media/svg/flags/128-spain.svg'),
  },
  [RegionLocale.JAPANESE]: {
    name: 'navBar.lang.ja',
    flag: toAbsoluteUrl('/media/svg/flags/063-japan.svg'),
  },
  [RegionLocale.GERMAN]: {
    name: 'navBar.lang.de',
    flag: toAbsoluteUrl('/media/svg/flags/162-germany.svg'),
  },
  [RegionLocale.FRENCH]: {
    name: 'navBar.lang.fr',
    flag: toAbsoluteUrl('/media/svg/flags/195-france.svg'),
  },
  [RegionLocale.VIETNAMESE]: {
    name: 'navBar.lang.vi',
    flag: toAbsoluteUrl('/media/svg/flags/220-vietnam.svg'),
  },
  [RegionLocale.KHMER]: {
    name: 'navBar.lang.km',
    flag: toAbsoluteUrl('/media/svg/flags/159-cambodia.svg'),
  },
};

const getRegionLocale = (): RegionLocale[] => {
  const lang = window.envConfig['REGION_LANGUAGE'];
  const arr = lang ? lang.split(',') : [RegionLocale.ENGLISH];

  //  Only filter available languages
  return arr.filter((item) => find(RegionLocale, (locale) => locale === item)) as RegionLocale[];
};

export const regionLocale = getRegionLocale();

// if there is non-english language, then there is local language
export const localLanguage = regionLocale.find((locale) => locale !== RegionLocale.ENGLISH);

export const convertServerLocale = (locale: RegionLocale): string => {
  if (locale === RegionLocale.ENGLISH) {
    return 'enUs';
  } else if (locale === RegionLocale.TRADITIONAL_CHINESE) {
    return 'zhHk';
  } else {
    return locale;
  }
};

export const useServerLocale = () => {
  const lang = useLang();
  return convertServerLocale(lang);
};

export const getAvailableServerLocale = () => {
  const regionLocale = getRegionLocale();
  return regionLocale.map((locale) => convertServerLocale(locale));
};

export const Translation = (key: string) => {
  const intl = GlobalHelper.getGlobalIntlRef();
  return intl.formatMessage({ id: key });
};

export const TranslationWithNum = (key: string, count: number) => {
  const intl = GlobalHelper.getGlobalIntlRef();
  return intl.formatMessage({ id: key }, { num: count });
};

export const TranslationWithParams = (key: string, params: any) => {
  const intl = GlobalHelper.getGlobalIntlRef();
  return intl.formatMessage({ id: key }, params);
};

export const initI18nData = (regionLocale: RegionLocale[], item: any, defaultName?: string): I18nDataItem => {
  const initialI18nData = item?.i18nData
    ? item.i18nData
    : regionLocale.reduce(
        (result, locale) => ({
          ...result,
          [locale]: { name: undefined },
        }),
        {},
      );
  // handle old data
  if (defaultName && !item?.i18nData) {
    if (regionLocale.includes(RegionLocale.ENGLISH) && !initialI18nData.en?.name) {
      initialI18nData.en = { name: defaultName };
    } else if (!regionLocale.includes(RegionLocale.ENGLISH)) {
      initialI18nData[Object.keys(initialI18nData)[0]] = { name: defaultName };
    }
  }
  return initialI18nData;
};

export const initI18nGenericData = <T>(regionLocale: RegionLocale[], defaultValue?: T): I18nGenericData<T> => {
  return regionLocale.reduce((result, locale) => ({ ...result, [locale]: defaultValue }), {});
};

export const checkI18nKeyExist = (key: string) => {
  const intl = GlobalHelper.getGlobalIntlRef();
  return key in intl.messages;
};
