import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { NewsCategoryFormMode, NewsCategoryItem } from '../../../types/news-type';
import { useDispatch } from 'react-redux';
import NewsCategoryDetailForm from './components/NewsCategoryDetailForm';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import { fetchNewsCategoryItem } from '../../../network/newsCrud';
import { LayoutSplashScreen } from 'src/app/layout';

const NewsCategoryDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();

  const { formMode, code } = useMemo(
    () => ({
      formMode: id ? NewsCategoryFormMode.EDIT : NewsCategoryFormMode.CREATE,
      code: id ? id : undefined,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newsCategoryDetail, setNewsCategoryDetail] = useState<NewsCategoryItem>();

  const reloadData = () => {
    if (code) {
      setIsLoading(true);
      fetchNewsCategoryItem(code, dispatch)
        .then((result) => {
          setNewsCategoryDetail(result);
        })
        .catch(() => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!code) {
      return;
    }
    reloadData();
    // eslint-disable-next-line
  }, [code]);

  return (
    <>
      {!isLoading ? (
        <NewsCategoryDetailForm
          formMode={formMode}
          code={code ? code : ''}
          newsCategoryDetail={newsCategoryDetail}
          onRouteTo={(route) => history.push(route)}
        />
      ) : (
        <LayoutSplashScreen />
      )}
    </>
  );
};

export default NewsCategoryDetailPage;
