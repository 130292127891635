import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  AdminAgentSegmentPermissionType,
  AdminNewsPermissionType,
  AGENT_SUB_SEGMENT_PATH,
  CONFIG_AGENT_NAMESPACE,
  CONFIG_AGENT_PATH,
  CONFIG_AGENT_SEGMENT_NAMESPACE,
  CONFIG_AGENT_SEGMENT_PATH,
  CONFIG_AGENT_TITLE,
  CONFIG_BASE_PATH,
  CONFIG_TITLE,
  AdminSubSegmentPermissionType,
  CONFIG_AGENT_SUB_SEGMENT_NAMESPACE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import ConfigAgentPage from './pages/Agent/ConfigAgentPage';
import ConfigAgentSegmentPage from './pages/AgentSegment/ConfigAgentSegmentPage';
import { AgentSubSegmentRoutes } from './pages/agent-sub-segment/agent-sub-segment-routes';
import { useMemo } from 'react';
import { getSubSegmentConfig } from '../News/pages/Article/View/components/NewsDetail.util';

export const configModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  // get config
  const { enabledSubsegment } = getSubSegmentConfig();

  const child = [
    {
      title: Translation(CONFIG_AGENT_TITLE),
      path: `${CONFIG_BASE_PATH}${CONFIG_AGENT_PATH}`,
      namespace: CONFIG_AGENT_NAMESPACE,
      component: ConfigAgentPage,
      enableRead: isPermitted(
        [
          AdminNewsPermissionType.CONFIG_AGENT_READ,
          AdminNewsPermissionType.CONFIG_AGENT_CREATE,
          AdminNewsPermissionType.CONFIG_AGENT_UPDATE,
        ],
        permissions,
      ),
      enableCreate: isPermitted([AdminNewsPermissionType.CONFIG_AGENT_CREATE], permissions),
      enableUpdate: isPermitted([AdminNewsPermissionType.CONFIG_AGENT_UPDATE], permissions),
    },
    {
      // title: Translation(CONFIG_AGENT_TITLE),
      title: 'Agent Segment',
      path: `${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}`,
      namespace: CONFIG_AGENT_SEGMENT_NAMESPACE,
      component: ConfigAgentSegmentPage,
      enableRead: isPermitted(
        [
          AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_READ,
          AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_CREATE,
          AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_UPDATE,
        ],
        permissions,
      ),
      enableCreate: isPermitted([AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_CREATE], permissions),
      enableUpdate: isPermitted([AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_UPDATE], permissions),
    },
  ];
  if (enabledSubsegment) {
    child.push({
      title: 'Agent SubSegment',
      path: `${CONFIG_BASE_PATH}${AGENT_SUB_SEGMENT_PATH}`,
      namespace: CONFIG_AGENT_SUB_SEGMENT_NAMESPACE,
      component: AgentSubSegmentRoutes,
      enableRead: isPermitted(
        [
          AdminSubSegmentPermissionType.CONFIG_AGENT_SUB_SEGMENT_READ,
          AdminSubSegmentPermissionType.CONFIG_AGENT_SUB_SEGMENT_CREATE,
          AdminSubSegmentPermissionType.CONFIG_AGENT_SUB_SEGMENT_UPDATE,
        ],
        permissions,
      ),
      enableCreate: isPermitted([AdminSubSegmentPermissionType.CONFIG_AGENT_SUB_SEGMENT_CREATE], permissions),
      enableUpdate: isPermitted([AdminSubSegmentPermissionType.CONFIG_AGENT_SUB_SEGMENT_UPDATE], permissions),
    });
  }
  return {
    title: Translation(CONFIG_TITLE),
    path: CONFIG_BASE_PATH,
    icon: '/media/svg/icons/General/Settings-2.svg',
    enableRead: isPermitted(
      [
        AdminNewsPermissionType.CONFIG_AGENT_READ,
        AdminNewsPermissionType.CONFIG_AGENT_CREATE,
        AdminNewsPermissionType.CONFIG_AGENT_UPDATE,
        AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_READ,
        AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_CREATE,
        AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_UPDATE,
        AdminSubSegmentPermissionType.CONFIG_AGENT_SUB_SEGMENT_READ,
        AdminSubSegmentPermissionType.CONFIG_AGENT_SUB_SEGMENT_CREATE,
        AdminSubSegmentPermissionType.CONFIG_AGENT_SUB_SEGMENT_UPDATE,
      ],
      permissions,
    ),
    child: child,
  };
};
