import { IntlShape } from 'react-intl';
import { ModuleHierachy } from 'src/app/common/module/types';
import { isPermitted } from 'src/app/common/utils';
import {
  AUDIT_LOG_TITLE,
  USER_MANAGEMENT_LOG_TITLE,
  AUDIT_LOG_BASE_PATH,
  USER_MANAGEMENT_LOG_PATH,
  USER_MANAGEMENT_LOG_NAMESPACE,
  AdminAuditLogPermissionType,
  NEWSS_ARTICLE_LOG_PATH,
  NEWS_LOG_NAMESPACE,
  AdminNewsPermissionType,
} from './constants';
import { AuditLogRoutes } from './audit-log-routes';

export const auditLogModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(AUDIT_LOG_TITLE),
    path: AUDIT_LOG_BASE_PATH,
    // enableRead: true,
    enableRead: isPermitted(
      [AdminAuditLogPermissionType.USER_MANAGEMENT_LOG_READ, AdminNewsPermissionType.NEWS_LOG_READ],
      permissions,
    ),
    child: [
      {
        title: Translation(USER_MANAGEMENT_LOG_TITLE),
        path: `${AUDIT_LOG_BASE_PATH}${USER_MANAGEMENT_LOG_PATH}`,
        namespace: USER_MANAGEMENT_LOG_NAMESPACE,
        component: AuditLogRoutes,
        options: {
          // only display read checkbox in page: /permission/role/list
          disableCreate: true,
          disableUpdate: true,
        },
        enableRead: isPermitted(
          [
            // log only has read access
            AdminAuditLogPermissionType.USER_MANAGEMENT_LOG_READ,
          ],
          permissions,
        ),
      },
      {
        title: Translation('news.article.log'),
        path: `${AUDIT_LOG_BASE_PATH}${NEWSS_ARTICLE_LOG_PATH}`,
        namespace: NEWS_LOG_NAMESPACE,
        component: AuditLogRoutes,
        options: {
          disableCreate: true,
          disableUpdate: true,
        },
        enableRead: isPermitted(
          [
            // log only has read access
            AdminNewsPermissionType.NEWS_LOG_READ,
          ],
          permissions,
        ),
      },
    ],
  };
};
