import { AgentSegmentItem, EnrolmentType } from 'src/app/common/types';
import { SegmentNameItem } from 'src/app/modules/Config/types/agent-segment-type';

export type AgentSegmentFormState = {
  parentSegmentId?: string;
  segmentId: string;
  name: SegmentNameItem;
  icon: string;
  iconBgColor: string;
  enrolmentType: EnrolmentType;
  eligibleSegments: {
    designation: string[];
  };
  eligibleDesignations?: string[];
  eligibleAgents: string[];
  excludedAgents: string[];
  agentListFileName?: string;
  agentListUploaded?: string[];
  region: string;
  channel: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  enableInPruNews: boolean;
  enableInIncentives: boolean;
};

export const initialState: AgentSegmentFormState = {
  parentSegmentId: '',
  segmentId: '',
  name: {},
  icon: '',
  iconBgColor: '#ff0000',
  enrolmentType: EnrolmentType.BY_SEGMENT,
  eligibleSegments: {
    designation: [],
  },
  eligibleAgents: [],
  excludedAgents: [],
  agentListFileName: '',
  region: '',
  channel: '',
  createdBy: '',
  updatedBy: '',
  createdAt: null,
  updatedAt: null,
  eligibleDesignations: undefined,
  enableInPruNews: true,
  enableInIncentives: true,
};

export const modulePrunews = 'prunews';

export const detailToStateConvertor = (detail: AgentSegmentItem): AgentSegmentFormState => {
  const eligibleDesignations =
    detail.eligibleSegments && detail.eligibleSegments.designation ? detail.eligibleSegments.designation : [];
  return {
    segmentId: detail._id,
    name: detail.name || {},
    icon: detail.icon,
    iconBgColor: detail.iconBgColor || '#ff0000',
    enrolmentType: detail.enrolmentType || detail.parentSegmentDetail?.enrolmentType || EnrolmentType.BY_SEGMENT,
    eligibleSegments: detail.eligibleSegments || { designation: [] },
    // eligibleAgents: detail.eligibleAgents || [],
    eligibleAgents: detail.enrolmentType === EnrolmentType.BY_SEGMENT ? detail.eligibleAgents : [],
    excludedAgents: detail.excludedAgents || [],
    agentListFileName: detail.agentListFileName,
    agentListUploaded: detail.enrolmentType === EnrolmentType.SPECIFIC ? detail.eligibleAgents : undefined,
    region: detail.region,
    channel: detail.channel,
    createdBy: detail.createdBy,
    updatedBy: detail.updatedBy,
    createdAt: detail.createdAt || null,
    updatedAt: detail.updatedAt || null,
    enableInPruNews: detail.modules?.includes(modulePrunews) || false,
    enableInIncentives: detail.enableInIncentives || false,
    eligibleDesignations,
    parentSegmentId: detail.parentSegmentDetail?._id,
  };
};

export type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof AgentSegmentFormState;
    value: any;
  };
};

export type ModifyLocaleFieldAction = {
  type: 'MODIFY_LOCALE_FIELD';
  payload: {
    field: keyof Pick<AgentSegmentFormState, 'name'>;
    locale: string;
    value: any;
  };
};

export type ToggleDesignationAction = {
  type: 'TOGGLE_DESIGNATION';
  payload: {
    value: string;
  };
};

type AgentSegmentFormAction = ModifyFieldAction | ToggleDesignationAction | ModifyLocaleFieldAction;

export const agentSegmentFormReducer = (
  state: AgentSegmentFormState,
  action: AgentSegmentFormAction,
): AgentSegmentFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };

    case 'MODIFY_LOCALE_FIELD':
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          [action.payload.locale]: action.payload.value,
        },
      };

    case 'TOGGLE_DESIGNATION':
      const newDesignation = [...(state.eligibleDesignations || [])];
      const itemIndex = newDesignation.findIndex((designation) => designation === action.payload.value);
      newDesignation[itemIndex] ? newDesignation.splice(itemIndex, 1) : newDesignation.push(action.payload.value);
      return {
        ...state,
        eligibleDesignations: newDesignation,
      };
  }
};
