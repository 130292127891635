import React, { useMemo, useState } from 'react';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { Button, Dialog, IconButton, Tooltip, Select, MenuItem } from '@mui/material';
import {
  NewsArticleListParam,
  fetchNewsArticleList,
  fetchNewsCategoryList,
  fetchNewsArticleItem,
} from '../../../../network/newsCrud';
import { NewsArticleItem, NewsArticlePaginateList, NewsCategoryPaginateList } from '../../../../types/news-type';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useServerLocale, getAvailableServerLocale } from 'src/app/i18n';
import { makeStyles, withStyles } from 'tss-react/mui';
import { getDefaultDisplayDate, useJWT, isPermitted } from 'src/app/common/utils';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface NewsArticleModalProps {
  selectId?: string;
  onSelectItem: (id?: string) => void;
  isView?: boolean;
  allAgentOnly?: boolean;
}

const initialState: Partial<NewsArticleListParam> = {
  limit: 5,
  page: 1,
  isPublished: 'true',
  isArchived: 'false',
  isCategoryActive: 'true',
};

const categoryListParam = {
  limit: 5,
  page: 1,
  activeOnly: true,
};

const paramsInitiator = (initialParams?: any, allAgentOnly?: boolean) => {
  if (allAgentOnly) {
    return {
      ...initialParams,
      isToAllAgents: 'true',
    };
  }
  return initialParams;
};

export const SpecificArticleDeepLink = (props: NewsArticleModalProps) => {
  const { onSelectItem, selectId, isView, allAgentOnly } = props;
  const [visible, setVisible] = useState(false);
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useServerLocale();
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const [filterState, setFilterState] = useState<any>(paramsInitiator(initialState, allAgentOnly));
  const [categoryList, setCategoryList] = useState<NewsCategoryPaginateList>();
  const [newsArticleList, setNewsArticleList] = useState<NewsArticlePaginateList>();
  const [selectedRow, setSelectedRow] = useState<NewsArticleItem>();
  const availableLocales = getAvailableServerLocale();
  const { isLoading, refreshData } = useDataProvider<NewsArticlePaginateList>(
    async () => {
      try {
        Object.keys(filterState).forEach((key) => {
          if (filterState[key] == null || filterState[key] === '') {
            delete filterState[key];
          }
        });
        return await fetchNewsArticleList(filterState, dispatch);
      } catch (err) {}
    },
    setNewsArticleList,
    false,
  );

  const reloadData = async () => {
    if (!selectedRow && selectId) {
      try {
        const res: any = await fetchNewsArticleItem(selectId, dispatch);
        setSelectedRow(res);
      } catch (err) {}
    }
  };

  useDataProvider<NewsCategoryPaginateList>(
    async () => {
      try {
        return await fetchNewsCategoryList(categoryListParam, dispatch);
      } catch (err) {}
    },
    setCategoryList,
    false,
  );

  const activeCategoryList = useMemo(() => {
    return categoryList?.categories?.filter((category) => (category as any).active !== false) || [];
  }, [categoryList]);

  const onBack = () => {
    setSelectedRow(undefined);
    setVisible(false);
  };

  const onSubmit = () => {
    if (selectedRow) {
      onSelectItem(selectedRow.newsId);
    }
    setVisible(false);
  };

  const onClear = () => {
    setSelectedRow(undefined);
    onSelectItem(undefined);
    setVisible(true);
  };

  React.useEffect(() => {
    reloadData();
  }, [selectId]);

  return (
    <>
      <div className={classes.buttonContainer}>
        {selectedRow && <div>{selectedRow.title[locale]}</div>}
        {!isView && (
          <>
            {selectedRow ? (
              <Button variant="contained" color="secondary" onClick={onClear}>
                {Translation('deeplink.clear.select.event.label')}
              </Button>
            ) : (
              <Button variant="contained" color="secondary" onClick={() => setVisible(true)}>
                {Translation('deeplink.select.article.label')}
              </Button>
            )}
          </>
        )}
      </div>

      <Dialog open={visible} fullWidth maxWidth="lg">
        <div className={classes.container}>
          <PruFilter
            title={Translation('newsArticle.filter')}
            itemDef={[
              {
                type: PruFilterItemType.MULTIPLE_DROPDOWN,
                style: { width: 200 },
                field: 'categoryId',
                initialValue: filterState.categoryId,
                displayName: Translation('newsArticle.common.category'),
                choices: [
                  ...(activeCategoryList?.map((category) => {
                    return {
                      displayName: category.name[locale],
                      value: category._id,
                    };
                  }) || []),
                ],
              },
              {
                type: PruFilterItemType.FREE_TEXT,
                style: { width: 250 },
                field: 'search',
                initialValue: filterState.search,
                displayName: Translation('newsArticle.common.keyword'),
              },
            ]}
            onChangeFilter={(data) => {
              setFilterState({
                ...filterState,
                categoryId: data.categoryId ?? undefined,
                search: data.search ?? undefined,
              });
            }}
            fetchData={refreshData}
          />
          <PruTable
            title={Translation('newsArticle.list')}
            subTitle={Translation(
              allAgentOnly
                ? 'deeplink.banner.select.article.subtitle'
                : 'deeplink.notification.select.article.subtitle',
            )}
            operationDef={[]}
            columnDef={[
              {
                isId: true,
                keyIndex: 'newsId',
                align: 'center',
                displayName: Translation('component.formLabel.id'),
                renderData: (row) => '',
                hidden: true,
              },
              {
                keyIndex: 'category',
                align: 'center',
                displayName: Translation('newsArticle.common.category'),
                renderData: (row) => row.category?.name[locale] || '-',
              },
              {
                keyIndex: `title${availableLocales[0]}`,
                align: 'center',
                displayName: Translation(`newsArticle.common.title.${availableLocales[0]}`),
                renderData: (row) => (row.title ? row.title[availableLocales[0]] : '-') || '-',
              },
              {
                keyIndex: 'publishDate',
                align: 'center',
                displayName: Translation('newsArticle.common.publishDate'),
                renderData: (row) => (row.publishDate ? getDefaultDisplayDate(row.publishDate, 'datetime') : '-'),
              },

              {
                keyIndex: 'isHeadline',
                align: 'center',
                displayName: Translation('newsArticle.common.isHeadline'),
                renderData: (row) =>
                  row.isHeadline ? (
                    <IconButton>
                      <CheckBoxIcon />
                    </IconButton>
                  ) : (
                    ''
                  ),
              },

              {
                keyIndex: 'status',
                align: 'center',
                displayName: Translation('newsArticle.common.status'),
                renderData: (row) => (row.status ? row.status : ''),
              },
              {
                keyIndex: 'updatedAt',
                align: 'center',
                displayName: Translation('newsArticle.common.updatedAt'),
                renderData: (row) => getDefaultDisplayDate(new Date(row.updatedAt), 'datetime'),
              },
            ]}
            isLoading={isLoading}
            onRefresh={refreshData}
            dataSource={newsArticleList?.newsList}
            totalPages={newsArticleList?.totalPages}
            totalRecords={newsArticleList?.totalRecords}
            onChangePage={(page, rowsPerPage) => {
              setFilterState({
                ...filterState,
                page,
                limit: rowsPerPage,
              });
              refreshData();
            }}
            singleSelect={true}
            currentSelectedRow={(data, parent, onResetRowSelected) => {
              setSelectedRow(data[0]);
            }}
          />
          <div className={classes.footerContainer}>
            <Button variant="contained" color="inherit" onClick={() => onBack()}>
              {Translation('app.button.cancel')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => onSubmit()}>
              {Translation('app.button.submit')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const useStyles = makeStyles()(() => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: 15,
    rowGap: 8,
    marginTop: 15,
  },
  container: {
    padding: 20,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: 20,
    marginTop: 48,
  },
}));
