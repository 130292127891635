import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { LayoutSplashScreen } from 'src/app/layout';
import { Box, Button, Typography } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { csvHandler, getConfigurations } from 'src/app/common/utils';
import * as XLSX from 'xlsx';
import { getTemplateFileData } from './schedule-template';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { ExamScheduleFileData } from '../../../types/license-exam-types';
import { createLicenseExamSchedule, fetchLicenseExamExamOptions } from '../../../network/license-exam-crud';
import { ExamTypeEnum } from '../../../enum/license-exam.enum';
import { TranslationWithParams, regionLocale, useLang } from 'src/app/i18n';
import { csvReader, dateFormatToServer } from '../../../utils';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import { createScheduleConfigs } from '../util/form-configs.util';
import { useRequest } from 'ahooks';
import { combineDateTime, formatParams } from './util/api-helper.util';
import { parseDate } from 'src/app/common/utils/parse-date';
export interface setScheduleFileDataParams {
  name: string;
  data: any[];
}

const initalScheduleFileData: setScheduleFileDataParams = {
  name: '',
  data: [],
};

const ExamScheduleUploadPage: FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const config = getConfigurations();
  const examModes = get(config, 'Recruitment.examModes', []);
  const currencies = get(config, 'Global.region.currencies', []) as { code: string; symbol: string }[];
  const [validatorErrors, setValidatorErrors] = useState<{ row: number; msg: string }[]>([]);

  const [scheduleFileData, setScheduleFileData] = useState(initalScheduleFileData);

  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });

  const locale = useLang();

  const {
    data: examTypeValues,
    loading,
    error,
  } = useRequest(
    async () => {
      return fetchLicenseExamExamOptions('EXAM_TYPE', { lang: locale }, dispatch);
    },
    { manual: false },
  );

  const examModeList = useMemo(() => {
    return examModes.map((mode: any) => Translation(mode.displayKey));
  }, []);

  async function xlsxHandler(file: File): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });

        // get the first sheet as default work sheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // transform the work sheet data to json
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });

        resolve(jsonData);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  const transformArray = (originalArray: Record<string, any>[]): Record<string, any>[] => {
    return originalArray.map((obj) => {
      return formatParams(obj);
    });
  };

  const getScheduleData = async (file: File) => {
    let result: ExamScheduleFileData[] = [];
    try {
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();
      let data;
      if (fileExtension === 'csv' || fileExtension === 'xls' || fileExtension === 'xlsx') {
        data = await xlsxHandler(file);
      } else {
        const errMsg = Translation('recruitment.exam.incorrect_file_type');
        throw new Error(errMsg);
      }
      const res = [];
      // map row header to row data
      const keys = data[0];
      const errorMsgs: any[] = [];

      const configs = createScheduleConfigs({ emptyMsgKey: 'recruitment.exam.missing_field_validator' });

      for (let i = 1; i < data.length; i++) {
        const exam: any = {};
        const rowData = data[i];
        const isEmpty = rowData.every((element: any) => {
          return element === undefined || element === null || element === '';
        });
        if (isEmpty) {
          continue;
        }
        for (let j = 0; j < keys.length; j++) {
          const key = keys[j];
          const transformedKey = ExamTypeEnum[key as keyof typeof ExamTypeEnum] || key;
          exam[transformedKey] = rowData[j];
        }

        // format date
        ['examDate', 'startTime', 'endTime', 'lastRegistrationDate', 'startRegistrationDate'].forEach((key) => {
          try {
            const val = exam[key];
            const examDate = exam['examDate'];

            if (val) {
              switch (key) {
                case 'examDate':
                  exam[key] = parseDate(val, ['DD/MM/YYYY']).toMoment()?.toISOString();
                  break;
                case 'startTime':
                  exam[key] = combineDateTime(examDate as string, val)?.toISOString();
                  break;
                case 'endTime':
                  exam[key] = combineDateTime(examDate as string, val)?.toISOString();
                  break;
                default:
                  exam[key] = parseDate(val, ['DD/MM/YYYY hh:mm']).toMoment()?.toISOString();
                  break;
              }
            }
          } catch (err) {
            console.log('format time error: ', err);
          }
        });

        const examType = exam['examType'];

        if (examType && examTypeValues?.indexOf(examType) === -1) {
          errorMsgs.push({
            row: i,
            msg: TranslationWithParams('recruitment.exam.examType_value_validator', { text: examTypeValues.join('/') }),
          });
        }

        // validate examMode, use default option if examMode is empty
        const examMode = exam['examMode'];
        if (!examMode) {
          exam['examMode'] = examModes.find((mode: any) => mode.default)?.code;
        } else {
          const mode = examModes.find((mode: any) => Translation(mode.displayKey) === examMode);
          if (mode) {
            exam['examMode'] = mode.code;
          } else {
            errorMsgs.push({
              row: i,
              msg: TranslationWithParams('recruitment.exam.examMode_value_validator', { text: examModeList.join('/') }),
            });
          }
        }

        // validate exam fee is number
        const examFee = exam['examFee'];
        if (examFee && isNaN(examFee)) {
          errorMsgs.push({
            row: i,
            msg: TranslationWithParams('recruitment.exam.exam_fee_validator', { examFee }),
          });
        }

        // validate currency code
        const currenyCode = exam['currencyCode'];
        if (currenyCode) {
          const currency = currencies.find((c) => c.code === currenyCode);
          const currencyCodeSetString = currencies.map((c) => c.code).join('/');
          if (!currency) {
            errorMsgs.push({
              row: i,
              msg: TranslationWithParams('recruitment.exam.currency_code_validator', { text: currencyCodeSetString }),
            });
          }
        }

        const form = {
          getFieldValue: (key: string) => exam[key],
        } as any;

        configs.forEach((config) => {
          const { name, rules, label } = config;
          const value = exam[name];

          rules.forEach((r) => {
            let rule = r;
            if (typeof r === 'function') {
              rule = r(form);
            }
            const { required, validator } = rule as any;

            if (validator) {
              validator({ required }, value, (msg: string) => {
                if (msg) {
                  errorMsgs.push({
                    row: i,
                    msg,
                    // value,
                    // name,
                  });
                }
              });
            } else {
              if (required && !value) {
                errorMsgs.push({
                  row: i,
                  msg: TranslationWithParams('recruitment.exam.missing_field_validator', { field: label }),
                  // value,
                  // name,
                });
              }
            }
          });
        });
        res.push(exam);
      }
      if (errorMsgs?.length > 0) {
        // only show 20 errors at max
        setValidatorErrors(errorMsgs.slice(0, 20));
        setScheduleFileData({
          name: file.name,
          data: [],
        });
      } else {
        //map result key to request param
        result = transformArray(res);
        setScheduleFileData({
          name: file.name,
          data: result,
        });
      }
    } catch (e: any) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: e?.message ?? '',
          },
        ]),
      );
    }
    return result;
  };

  const downLoadTemplate = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(getTemplateFileData());
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'template.xlsx');
  };

  const checkFile = (file: any, maxSize?: number, fileTypes?: string[]) => {
    if (!file) return false;
    if (fileTypes && !fileTypes.some((item) => file?.name?.toLowerCase().endsWith(item))) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: Translation('recruitment.exam.incorrect_file_type'),
          },
        ]),
      );
      return false;
    }
    if (maxSize && file.size > maxSize * 1024 * 1024) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: Translation('file_size_warn_label'),
          },
        ]),
      );
      return false;
    }
    return true;
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e?.target?.files?.[0];
      // set max size 10MB
      const maxSize = 10;
      if (!checkFile(file, maxSize, ['csv', 'xlsx', 'xls'])) {
        return;
      }
      const result = await getScheduleData(file);
      if (result && !isEmpty(result)) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: Translation('global.submit.success'),
              content: ``,
            },
          ]),
        );
      }
    }
  };

  const handleSubmit = useCallback(async () => {
    try {
      await createLicenseExamSchedule({ payload: scheduleFileData.data }, dispatch);
      history.goBack();
    } catch (err) {
      console.warn('schedule upload result: ', err);
    }
  }, [scheduleFileData, dispatch]);

  const handleRemove = useCallback(() => {
    setValidatorErrors([]);
    setScheduleFileData(initalScheduleFileData);
  }, []);

  return (
    <>
      {loading ? (
        <LayoutSplashScreen />
      ) : error ? null : (
        <div
          className="tw-relative tw-container tw-bg-white tw-p-5 tw-mb-5 tw-rounded-md tw-h-full tw-w-full"
          style={{ height: 'auto', minHeight: '100%', overflow: 'scroll' }}
        >
          <div className="tw-flex tw-items-center tw-p-1">
            <div className="tw-w-full tw-flex">
              <div className={commonClasses.header}>{Translation('recruitment.exam.schedule.title')}</div>
            </div>
          </div>
          <div className="tw-my-[21px]">
            <div className="tw-flex tw-w-full tw-items-center tw-mb-[21px]">
              <span className="tw-text-base tw-font-bold tw-mr-24">
                {Translation('recruitment.exam.schedule.upload')}
                <Typography component={'span'} color="#E8192C" fontSize={'12px'} fontWeight={400}>
                  *
                </Typography>
                :
              </span>
              {!scheduleFileData?.name ? (
                <>
                  <div>
                    <input
                      id="upload-file"
                      hidden
                      type="file"
                      accept=".csv,.xlsx,.xls"
                      onClick={(e) => {
                        const element = e.target as HTMLInputElement;
                        element.value = '';
                      }}
                      onChange={(e) => handleFile(e)}
                    />
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => document.getElementById('upload-file')!.click()}
                    >
                      {Translation('app.button.upload')}
                    </Button>
                  </div>
                  <div className="tw-flex tw-flex-col tw-items-start tw-ml-5">
                    <span className="tw-text-base tw-ml-2">
                      {Translation('recruitment.exam.registration.upload.file_limit')}
                    </span>
                    <Button onClick={downLoadTemplate} color="secondary">
                      <span className="tw-underline tw-font-bold">{Translation('download_template_button')}</span>
                    </Button>
                    <div className="tw-ml-2">{scheduleFileData.name || Translation('app.file.noFileChosen')}</div>
                  </div>
                </>
              ) : (
                <Box display="flex" flexDirection={'row'} alignItems={'center'}>
                  <Typography color="#000000" fontSize={'12px'} fontWeight={400} height={'20px'} marginRight={'20px'}>
                    {scheduleFileData?.name}
                  </Typography>
                  <Button variant="text" size="large" color="secondary" onClick={handleRemove}>
                    {Translation('app.button.remove')}
                  </Button>
                </Box>
              )}
            </div>
            {validatorErrors.length > 0 ? (
              <div>
                <Typography color="#E8192C" fontSize={'12px'} fontWeight={400} marginBottom={'14px'}>
                  *{Translation('recruitment.exam.schedule.file_upload_fail_validator')}
                </Typography>
                {validatorErrors.map((item, index) => (
                  <Typography key={index} color="#212B36" fontSize={'12px'} fontWeight={400} marginBottom={'12px'}>
                    {TranslationWithParams('recruitment.exam.schedule.file_upload_fail_detail_validator', {
                      ...item,
                      row: item.row + 1,
                    })}
                  </Typography>
                ))}
              </div>
            ) : null}
          </div>

          <div className="tw-absolute tw-bottom-10 tw-right-10">
            <div className="tw-space-x-4">
              <Button variant="contained" size="large" color="inherit" onClick={() => history.goBack()}>
                {Translation('app.button.back')}
              </Button>
              {validatorErrors?.length <= 0 ? (
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={!scheduleFileData?.name}
                  onClick={handleSubmit}
                >
                  {Translation('app.button.confirm')}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExamScheduleUploadPage;
