import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { getConfigurations } from 'src/app/common/utils';
import { TargetApplicantProps } from './target-applicant.component';
import { JsonObject } from 'src/app/common/components/data-uploader/data-uploader.component';
import { EnrolmentType, NewsArticleFormMode } from 'src/app/modules/News/types/news-type';

export const useTargetApplicant = (props: TargetApplicantProps) => {
  // i18n
  // const locale = useLang();
  // const intl = useIntl();
  // const Translation = (id: string) => intl.formatMessage({ id });

  // redux
  // const dispatch = useDispatch();
  const refInitEnrolmentType = useRef(props.formState.enrolmentType);

  const isShowIncludeDownload =
    props.formMode === NewsArticleFormMode.EDIT &&
    refInitEnrolmentType.current === EnrolmentType.BY_SEGMENT &&
    props.formState.eligibleAgents &&
    props.formState.eligibleAgents.length;

  const isShowExcludeDownload =
    props.formMode === NewsArticleFormMode.EDIT &&
    refInitEnrolmentType.current === EnrolmentType.BY_SEGMENT &&
    props.formState.excludedAgents &&
    props.formState.excludedAgents.length;

  const isShowAgentDownload =
    props.formMode === NewsArticleFormMode.EDIT &&
    refInitEnrolmentType.current === EnrolmentType.SPECIFIC &&
    props.formState.agentListUploaded &&
    props.formState.agentListUploaded.length;

  const handlePress = useCallback(() => {}, []);
  const validateUploadedData = useCallback((data: JsonObject[]) => {
    const arr = dataConvert(data);
    if (arr && arr.length) {
      return true;
    }
    return false;
  }, []);

  /**
   * get & convert Uploaded agent list to download format
   * data sample: ["00010001","00010007"]
   * @returns sample: [{agentCode: "00010001", agentCode: "00010007"}]
   */
  const getUploadedAgents = () => {
    const arr = props.formState?.agentListUploaded || [];
    return arr.map((agentCode) => {
      return { agentCode: agentCode };
    });
  };

  const getIncludeAgents = () => {
    const arr = props.formState?.eligibleAgents || [];
    return arr.map((agentCode) => {
      return { agentCode: agentCode };
    });
  };

  const getExcludeAgents = () => {
    const arr = props.formState?.excludedAgents || [];
    return arr.map((agentCode) => {
      return { agentCode: agentCode };
    });
  };

  return {
    isShowAgentDownload,
    isShowExcludeDownload,
    isShowIncludeDownload,
    handlePress,
    getUploadedAgents,
    getExcludeAgents,
    getIncludeAgents,
    validateUploadedData,
  };
};
/**
 * uploaded JsonObject array convert to string array
 * sample: [{agentCode: "00010001", agentCode: "00010007"}] => ["00010001",00010007]
 * @param arr
 */
export function dataConvert(agents: JsonObject[]) {
  const agentCodes = agents
    ? agents
        .map((agent) => agent.agentCode) // Extract agent codes
        .filter((code) => code !== undefined && code !== '') // Filter out undefined and empty string values
        .map((code) => String(code)) // to string
    : []; // Return an empty array

  return agentCodes;
}
