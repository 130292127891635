import React, { memo, useCallback, useMemo, useState } from 'react';
import { Button, TextField, CircularProgress, Autocomplete, Typography, MenuItem, Select } from '@mui/material';
import { useStyles } from './create-schedule.style';
import { CreateScheduleProps, useCreateSchedule } from './create-schedule.hook';
import Form from 'src/app/common/components/Form';
import { DatePicker, TimePicker, DateTimePicker } from '@mui/x-date-pickers';
import ButtonDialog from 'src/app/common/components/ButtonDialog';
import dayjs from 'dayjs';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import { CreateScheduleFields } from 'src/app/modules/Recruitment/types/license-exam-types';
import { disableField } from '../../../util/form.util';
import { ExamType } from './components/exam-type/exam-type.component';
import { RadioGroup } from 'src/app/common/components/RadioGroup';

const getEmptyMsg = (label: string) => `Please input ${label}`;

export const CreateSchedule: React.FC<CreateScheduleProps> = memo((props: CreateScheduleProps) => {
  const Translation = useTranslation();
  const { onCancel, initialValues, disabled, showSubmit, autoCompleteOptions } = props;

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const checkDisabled = useCallback((key: CreateScheduleFields) => disableField(key, disabled), [disabled]);

  const {
    form,
    examCodeConfig,
    examNameConfig,
    localExamNameConfig,
    examTypeConfig,
    examModeConfig,
    examDateConfig,
    startTimeConfig,
    endTimeConfig,
    venueConfig,
    addressConfig,
    startRegistrationDateConfig,
    lastRegistrationDateConfig,
    quotaConfig,
    remainingQuotaConfig,
    remarkConfig,
    onFinish,
    confirmLoading,
    examModeList,
    hasDeviceTypes,
    deviceTypeConfig,
    deviceTypeOptions,
    enableExamFeeInput,
    examFeeConfig,
    currencyCodeConfig,
    currencies,
    defaultCurrencyCode,
  } = useCreateSchedule(props);

  const renderAutoCompleteField = useCallback(
    (
      name: CreateScheduleFields,
      label: string,
      placeholder: string,
      options: string[] | { label: string; value: string }[] | undefined,
      disabled: boolean,
    ) => {
      return (
        <Form.Item
          style={{ marginBottom: '20px' }}
          name={name}
          label={label}
          rules={[{ required: true, message: getEmptyMsg(label) }]}
          getValueFromEvent={(event, newValue) => newValue}
        >
          <Autocomplete
            freeSolo
            options={options || []}
            disabled={disabled}
            fullWidth
            getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.label || '')}
            renderOption={(props, option: any) => {
              const label = typeof option === 'string' ? option : option.label;
              return <li {...props}>{label}</li>;
            }}
            onChange={(event, newValue: any) => {
              form.setFieldsValue({
                [name]: typeof newValue === 'string' ? newValue : newValue?.value || newValue?.label || '',
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder={placeholder}
                onBlur={(event) => {
                  const newValue: string = event.target.value;
                  form.setFieldsValue({
                    [name]: newValue ?? '',
                  });
                }}
              />
            )}
            sx={{
              '& .MuiAutocomplete-inputRoot': { width: '100%' },
              '& .MuiAutocomplete-popupIndicator': { marginRight: '8px' },
            }}
          />
        </Form.Item>
      );
    },
    [form],
  );

  const [fieldValues, setFieldValues] = useState(initialValues || ({} as any));

  const handleValuesChange = (changedValues: any, allValues: any) => {
    setFieldValues(allValues);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={{ ...initialValues, currencyCode: defaultCurrencyCode }}
      onValuesChange={handleValuesChange}
    >
      <div className={styles.wrap}>{Translation('recruitment.exam.schedule.schedule_setting')}</div>
      {renderAutoCompleteField(
        examCodeConfig.name,
        examCodeConfig.label,
        examCodeConfig.placeholder ?? '',
        autoCompleteOptions?.examCodeOptions || [],
        checkDisabled(examCodeConfig.name),
      )}
      {renderAutoCompleteField(
        examNameConfig.name,
        examNameConfig.label,
        examNameConfig.placeholder ?? '',
        autoCompleteOptions?.examNameOptions || [],
        checkDisabled(examNameConfig.name),
      )}
      {renderAutoCompleteField(
        localExamNameConfig.name,
        localExamNameConfig.label,
        localExamNameConfig.placeholder ?? '',
        autoCompleteOptions?.localExamNameOptions || [],
        checkDisabled(localExamNameConfig.name),
      )}
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={examTypeConfig.name}
        label={examTypeConfig.label}
        rules={examTypeConfig.rules}
        shouldUpdate
      >
        <ExamType disabled={checkDisabled(examTypeConfig.name)} />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={examModeConfig.name}
        label={examModeConfig.label}
        rules={examModeConfig.rules}
        shouldUpdate
      >
        <RadioGroup options={examModeList} disabled={checkDisabled(examModeConfig.name)} />
      </Form.Item>
      {hasDeviceTypes && (
        <Form.Item style={{ marginBottom: '20px' }} name={deviceTypeConfig.name} label={deviceTypeConfig.label}>
          {checkDisabled(deviceTypeConfig.name) ? (
            <span className="tw-text-gray-400">{initialValues?.[deviceTypeConfig.name]}</span>
          ) : (
            <Select
              sx={{ minWidth: 150 }}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return <em>{deviceTypeConfig.placeholder}</em>;
                }
                const selectedOption = deviceTypeOptions.find((option) => option.value === selected);
                return selectedOption ? selectedOption.label : '';
              }}
              defaultValue={initialValues?.[deviceTypeConfig.name]}
            >
              {deviceTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </Form.Item>
      )}
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={examDateConfig.name}
        label={examDateConfig.label}
        rules={examDateConfig.rules}
      >
        <DatePicker
          disabled={checkDisabled(examDateConfig.name)}
          minDate={dayjs()}
          slotProps={{ textField: { variant: 'outlined' } }}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={startTimeConfig.name}
        label={startTimeConfig.label}
        rules={startTimeConfig.rules}
      >
        <TimePicker
          disabled={checkDisabled(startTimeConfig.name)}
          slotProps={{ textField: { variant: 'outlined' } }}
          format="HH:mm"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={endTimeConfig.name}
        label={endTimeConfig.label}
        rules={endTimeConfig.rules}
      >
        <TimePicker
          disabled={checkDisabled(endTimeConfig.name)}
          slotProps={{ textField: { variant: 'outlined' } }}
          format="HH:mm"
        />
      </Form.Item>
      <div className={`${styles.wrap} ${styles.mt30}`}>{Translation('recruitment.exam.schedule.location')}</div>
      {renderAutoCompleteField(
        venueConfig.name,
        venueConfig.label,
        venueConfig.placeholder ?? '',
        autoCompleteOptions?.venueOptions || [],
        checkDisabled(venueConfig.name),
      )}
      {renderAutoCompleteField(
        addressConfig.name,
        addressConfig.label,
        addressConfig.placeholder ?? '',
        autoCompleteOptions?.addressOptions || [],
        checkDisabled(addressConfig.name),
      )}
      <div className={`${styles.wrap} ${styles.mt30}`}>{Translation('recruitment.exam.schedule.additional')}</div>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={startRegistrationDateConfig.name}
        label={startRegistrationDateConfig.label}
        rules={startRegistrationDateConfig.rules}
        shouldUpdate={(prevValues, currentValues) =>
          prevValues[lastRegistrationDateConfig.name] !== currentValues[lastRegistrationDateConfig.name]
        }
      >
        <DateTimePicker
          minDateTime={dayjs()}
          maxDateTime={
            fieldValues[lastRegistrationDateConfig.name]
              ? dayjs(fieldValues[lastRegistrationDateConfig.name])
              : undefined
          }
          disabled={checkDisabled(startRegistrationDateConfig.name)}
          slotProps={{ textField: { variant: 'outlined' } }}
          format="DD/MM/YYYY HH:mm"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={lastRegistrationDateConfig.name}
        label={lastRegistrationDateConfig.label}
        rules={lastRegistrationDateConfig.rules}
        shouldUpdate={(prevValues, currentValues) =>
          prevValues[startRegistrationDateConfig.name] !== currentValues[startRegistrationDateConfig.name]
        }
      >
        <DateTimePicker
          minDateTime={
            fieldValues[startRegistrationDateConfig.name]
              ? dayjs(fieldValues[startRegistrationDateConfig.name])
              : dayjs()
          }
          disabled={checkDisabled(lastRegistrationDateConfig.name)}
          slotProps={{ textField: { variant: 'outlined' } }}
          format="DD/MM/YYYY HH:mm"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={quotaConfig.name}
        label={quotaConfig.label}
        rules={quotaConfig.rules}
      >
        <TextField
          disabled={checkDisabled(quotaConfig.name)}
          margin="dense"
          variant="outlined"
          fullWidth
          type="number"
          placeholder={quotaConfig.placeholder}
          InputProps={{ inputProps: { min: 1 } }}
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        name={remainingQuotaConfig.name}
        label={remainingQuotaConfig.label}
        rules={remainingQuotaConfig.rules}
        required={false}
      >
        <TextField
          disabled={checkDisabled(remainingQuotaConfig.name)}
          margin="dense"
          variant="outlined"
          fullWidth
          type="number"
          placeholder={remainingQuotaConfig.placeholder}
          InputProps={{ inputProps: { min: 0 } }}
        />
      </Form.Item>
      {enableExamFeeInput && (
        <Form.Item
          style={{ marginBottom: '20px' }}
          name={examFeeConfig.name}
          label={examFeeConfig.label}
          required={examFeeConfig.required}
          rules={examFeeConfig.rules}
        >
          <div className="tw-flex tw-flex-row tw-items-baseline">
            {currencies.length > 0 && (
              <Form.Item name={currencyCodeConfig.name} style={{ width: undefined, marginRight: 8 }}>
                <Select
                  disabled={checkDisabled(currencyCodeConfig.name)}
                  sx={{ minWidth: 100 }}
                  defaultValue={defaultCurrencyCode}
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency.code} value={currency.code}>
                      {currency.symbol}
                    </MenuItem>
                  ))}
                </Select>
              </Form.Item>
            )}
            <TextField
              disabled={checkDisabled(examFeeConfig.name)}
              margin="dense"
              variant="outlined"
              fullWidth
              type="number"
              placeholder={examFeeConfig.placeholder}
              inputProps={{ min: 0, step: '0.01' }}
              defaultValue={initialValues?.[examFeeConfig.name]}
            />
          </div>
        </Form.Item>
      )}
      <Form.Item required={false} name={remarkConfig.name} label={remarkConfig.label} rules={remarkConfig.rules}>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          disabled={checkDisabled(remarkConfig.name)}
          placeholder={remarkConfig.placeholder}
        />
      </Form.Item>
      <div className={styles.footerContainer}>
        <ButtonDialog
          onOk={onCancel}
          dialogTxt={Translation('component.hint.cancel-warning')}
          btnTxt={Translation('app.button.cancel')}
        />
        {showSubmit && (
          <Button
            type="submit"
            disabled={confirmLoading}
            style={{ marginLeft: 20 }}
            variant="contained"
            color="secondary"
          >
            {Translation('app.button.confirm')}
            {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
          </Button>
        )}
      </div>
    </Form>
  );
});
