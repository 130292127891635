import { initI18nGenericData, RegionLocale, I18nGenericData } from 'src/app/i18n';
import { SessionTypeEnum, OnlineMeetingLinkDisplayEnum, SessionEnrollmentEnum } from 'src/app/modules/event-v2/types';

export type SessionFormState = {
  key: string;
  type: SessionTypeEnum;
  startTime: Date | null;
  endTime: Date | null;
  name: I18nGenericData<string>;
  venue: I18nGenericData<string>;
  onlineMeetingLink?: string;
  onlineMeetingLinkDisplay?: OnlineMeetingLinkDisplayEnum[];
  multiSubSession?: boolean;
  quota?: string | null;
  subSessions: SessionFormState[];
  subSessionEnrollment?: SessionEnrollmentEnum;
};

type SetFormStateAction = {
  type: 'SET_FORM_STATE';
  payload: {
    value: Partial<SessionFormState>;
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof SessionFormState;
    value: any;
  };
};

type FillInDetailAction = {
  type: 'FILL_IN_DETAIL';
  payload: {
    fromLocale: string;
    toLocale: string;
  };
};

type AddSubSessionAction = {
  type: 'ADD_SUB_SESSION';
  payload: {
    regionLocale: RegionLocale[];
  };
};

type ModifySubSessionAction = {
  type: 'MODIFY_SUB_SESSION';
  payload: {
    index: number;
    value: SessionFormState;
  };
};

type RemoveSubSessionAction = {
  type: 'REMOVE_SUB_SESSION';
  payload: {
    index: number;
  };
};

export type SessionFormAction =
  | SetFormStateAction
  | ModifyFieldAction
  | FillInDetailAction
  | AddSubSessionAction
  | ModifySubSessionAction
  | RemoveSubSessionAction;

export const sessionFormReducer = (state: SessionFormState, action: SessionFormAction): SessionFormState => {
  let newSubSessions = [...state.subSessions];
  switch (action.type) {
    case 'SET_FORM_STATE':
      return {
        ...state,
        ...action.payload.value,
      };
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'FILL_IN_DETAIL':
      const fromLocale = action.payload.fromLocale;
      const toLocale = action.payload.toLocale;
      return {
        ...state,
        name: {
          ...state.name,
          [toLocale]: state.name[fromLocale],
        },
        venue: {
          ...state.venue,
          [toLocale]: state.venue[fromLocale],
        },
      };
    case 'ADD_SUB_SESSION':
      const initI18nStringData = initI18nGenericData<string>(action.payload.regionLocale, '');
      newSubSessions.push({
        key: `${state.key}_sub${state.subSessions.length + 1}`,
        type: SessionTypeEnum.SUB_SESSION,
        startTime: null,
        endTime: null,
        name: initI18nStringData,
        venue: initI18nStringData,
        multiSubSession: undefined,
        quota: undefined,
        subSessions: [],
        subSessionEnrollment: undefined,
      });
      return {
        ...state,
        subSessions: newSubSessions,
      };
    case 'MODIFY_SUB_SESSION':
      newSubSessions[action.payload.index] = action.payload.value;
      return {
        ...state,
        subSessions: newSubSessions,
      };
    case 'REMOVE_SUB_SESSION':
      newSubSessions.splice(action.payload.index, 1);
      newSubSessions = newSubSessions.map((subSessionItem, index) => ({
        ...subSessionItem,
        key: `${state.key}_sub${index + 1}`,
      }));
      return {
        ...state,
        subSessions: newSubSessions,
      };
    default:
      return state;
  }
};
