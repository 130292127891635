import { FC, useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { PruForm, PruFormItemType } from 'src/app/common/components/PruForm';
import { checkI18nKeyExist, useLang } from 'src/app/i18n';
import moment from 'moment-timezone';
import { getDayFormat } from 'src/app/common/utils';
import { LoadingButton } from '@mui/lab';
import { TimeRangeOptsType } from 'src/app/modules/Report/pages/Recruitment/constants';
import { AlertType, appendAlertItem, ParamsProps } from '@pruforce/common-adminweb-sdk';
import apis from 'src/app/modules/Report/pages/Recruitment/apis';
import { getExamCodeAndName, getExamPassingRateReportExcel } from '../../../network/license-exam-crud';
import { PruToast } from 'src/app/common/components/pru-toast';
import { useDispatch } from 'react-redux';

type ExamPassingRateListPageProps = ParamsProps;

const TimeRangeOptsOrigin = [
  // YTD, Last 3 Months, Last 6 Months, Last 12 Months, Last 24 Months
  { value: TimeRangeOptsType.YTD, label: 'YTD' },
  // { value: TimeRangeOptsType.LastMonth, label: 'Last Month' },
  { value: TimeRangeOptsType.Last3Months, label: 'Last 3 Months' },
  { value: TimeRangeOptsType.Last6Months, label: 'Last 6 Months' },
  { value: TimeRangeOptsType.Last12Months, label: 'Last 12 Months' },
  { value: TimeRangeOptsType.Last24Months, label: 'Last 24 Months' },
  { value: TimeRangeOptsType.CustomizedTimeRange, label: 'Customized time Range' },
];
const PassingRateListPage: FC<ExamPassingRateListPageProps> = () => {
  const formRef = useRef<any>();
  const intl = useIntl();

  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const e = (s: string) => {
    return Translation('report.recruitment.exam.' + s.replace(/ /g, '_').toLowerCase());
  };
  // const { classes } = styles();
  const history = useHistory();
  const langOrigin = useLang();
  const lang = langOrigin;
  const currentYear = moment().year();
  const ytdTime = moment(`${currentYear}-01-01`).startOf('day').valueOf();
  const thisMonthEndTime = moment().endOf('month').endOf('day').valueOf();
  const filterInitData = {
    timeRange: [new Date(ytdTime), new Date(thisMonthEndTime)],
  };

  const [designationOpts, setDesignationOpts] = useState<{ label: string; value: any }[]>([]);
  const [filterOpts, setFilterOpts] = useState<any>({});
  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);

  const [examCodeOpts, setExamCodeOpts] = useState<{ label: string; value: any }[]>([{ label: 'All', value: '' }]);
  const [examNameOpts, setExamNameOpts] = useState<{ label: string; value: any }[]>([{ label: 'All', value: '' }]);

  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    page: 0,
    pageSize: 20,
  });
  // eslint-disable-next-line
  const [isDownLoading, setIsDownloading] = useState<boolean>(false);

  useEffect(() => {
    const aggregateFields: string[] = [];
    if (window.envConfig['ENABLE_ZONE'] === 'true') {
      aggregateFields.push('zone');
    }
    if (window.envConfig['ENABLE_BRANCH'] === 'true') {
      aggregateFields.push('branch');
    }
    if (window.envConfig['ENABLE_UNIT'] === 'true') {
      aggregateFields.push('unit');
    }
    if (aggregateFields.length) {
      apis.getAggregateFields({ paths: aggregateFields }).then((res: any) => {
        const aggregateFieldData = res.data || [];
        const _filterOpts: any = {};
        aggregateFields.forEach((path) => {
          _filterOpts[path] = [{ value: '', label: 'All' }].concat(
            (aggregateFieldData[path] ?? [])
              .filter((val: string) => val)
              .sort()
              .map((val: string) => ({ value: val, label: val as unknown as string })),
          );
        });
        setFilterOpts(_filterOpts);
      });
    }
    if (window.envConfig['ENABLE_DESIGNATION'] === 'true') {
      apis.getDesignation().then((res: any) => {
        const des = res.data || [];
        const opts = [{ value: '', label: 'All' }];
        setDesignationOpts(
          opts.concat(
            des.map((d: any) => {
              return { label: d.shortName, value: d.code };
            }),
          ),
        );
      });
    }
    moment.tz.guess(true); // clear cache
  }, []);

  // get exam code and name's options by api
  useEffect(() => {
    getExamCodeAndName().then((res: any) => {
      const { examCodeOptions, examNameOptions } = res ?? {};
      if (!examCodeOptions || !examNameOptions) return;
      setExamCodeOpts(
        [].concat(
          examCodeOptions.map((code: string) => {
            return { label: code, value: code };
          }),
        ),
      );
      setExamNameOpts(
        [].concat(
          examNameOptions.map((name: string) => {
            return { label: name, value: name };
          }),
        ),
      );
    });
  }, []);

  const TimeRangeOpts = TimeRangeOptsOrigin.map((opt) => {
    const labelKey = `Common.${opt.label.replace(/ /g, '_').toLowerCase()}`;
    return {
      value: opt.value,
      label: Translation(labelKey),
    };
  });
  const changeFilterData = (d: object) => {
    setFilterData({ ...filterData, ...d });
  };
  const onTimeTypeChange = (v: any) => {
    const currentYear = moment().year();

    // YTD, Last 3 Months, Last 6 Months, Last 12 Months, Last 24 Months
    const ytdTime = moment(`${currentYear}-01-01`).startOf('day').valueOf();
    // the first day of the last 3 months
    const last3MonthsStartTime = moment().subtract(2, 'months').startOf('month').startOf('day').valueOf();

    const last6MonthsStartTime = moment().subtract(5, 'months').startOf('month').startOf('day').valueOf();

    const last12MonthsStartTime = moment().subtract(11, 'months').startOf('month').startOf('day').valueOf();

    const last24MonthsStartTime = moment().subtract(23, 'months').startOf('month').startOf('day').valueOf();

    switch (parseInt(v)) {
      case TimeRangeOptsType.YTD:
        changeFilterData({
          timeRangeType: v,
          timeRange: [new Date(ytdTime), new Date(thisMonthEndTime)],
        });
        break;
      case TimeRangeOptsType.Last3Months:
        changeFilterData({
          timeRangeType: v,
          timeRange: [new Date(last3MonthsStartTime), new Date(thisMonthEndTime)],
        });
        break;
      case TimeRangeOptsType.Last6Months:
        changeFilterData({
          timeRangeType: v,
          timeRange: [new Date(last6MonthsStartTime), new Date(thisMonthEndTime)],
        });
        break;
      case TimeRangeOptsType.Last12Months:
        changeFilterData({
          timeRangeType: v,
          timeRange: [new Date(last12MonthsStartTime), new Date(thisMonthEndTime)],
        });
        break;
      case TimeRangeOptsType.Last24Months:
        changeFilterData({
          timeRangeType: v,
          timeRange: [new Date(last24MonthsStartTime), new Date(thisMonthEndTime)],
        });
        break;
      case TimeRangeOptsType.CustomizedTimeRange:
        changeFilterData({
          timeRangeType: v,
          timeRange: [null, null],
        });
        break;
      default:
        return;
    }
  };

  const { timeRangeType } = filterData;
  const filterConfig = () => {
    return [
      [
        { type: 'header', label: e('Passing Rate'), sm: true },
        {
          type: 'custom',
          sm: false,
          render: () => (
            <Button variant="contained" color="inherit" onClick={onReset}>
              {Translation('golbal.filters.reset')}
            </Button>
          ),
        },
        {
          type: 'custom',
          sm: false,
          render: () => (
            <LoadingButton loading={isDownLoading} variant="contained" color="secondary" onClick={onClickExport}>
              {Translation('export.list.text')}
            </LoadingButton>
          ),
        },
      ],
      [
        {
          type: 'select',
          label: Translation('Recruitment.exam_date_range'),
          opts: TimeRangeOpts,
          prop: 'timeRangeType',
          width: 400,
          onChange: onTimeTypeChange,
        },
        {
          type: timeRangeType === TimeRangeOptsType.CustomizedTimeRange + '' ? 'dateRange' : 'hide',
          label: '',
          prop: 'timeRange',
          format: 'MM/YYYY',
          width: 400,
          rules: [
            {
              type: 'array',
              len: 2,
              fields: {
                0: {
                  type: 'month',
                  required: true,
                  message: Translation('Recruitment.please_input_the_time_range'),
                  maxDate: new Date(),
                },
                1: {
                  type: 'month',
                  required: true,
                  message: Translation('Recruitment.please_input_the_time_range'),
                  maxDate: new Date(),
                },
              },
            },
          ],
        },
      ],
      [
        {
          type: PruFormItemType.autoComplete,
          opts: examCodeOpts || [],
          multiple: true,
          label: Translation('recruitment.exam.examCode'),
          prop: 'examCodes',
          styles: {
            display: 'flex',
            flexDirection: 'row',
          },
          width: 400,
        },
        {
          type: PruFormItemType.autoComplete,
          opts: examNameOpts || [],
          multiple: true,
          label: Translation('recruitment.exam.examName'),
          prop: 'examNames',
          styles: {
            display: 'flex',
            flexDirection: 'row',
          },
          width: 400,
        },
      ],
      [
        {
          type: 'input',
          label: Translation('recruit.personalParticular.recruiterName'),
          prop: 'recruiterName',
          styles: {
            display: 'flex',
            flexDirection: 'row',
          },
          width: 400,
        },
        {
          type: 'input',
          label: Translation('recruit.personalParticular.recruiterCode'),
          prop: 'recruiterCode',
          styles: {
            display: 'flex',
            flexDirection: 'row',
          },
          width: 400,
        },
      ],
      [
        {
          type: window.envConfig['ENABLE_DIVISION'] === 'true' ? 'input' : 'none',
          label: Translation('Common.division'),
          prop: 'division',
          width: 400,
        },
        {
          type: window.envConfig['ENABLE_AGENCY_CODE'] === 'true' ? 'input' : 'none',
          label: Translation('Common.agency_code'),
          prop: 'agencyCode',
          width: 400,
        },
        {
          type: window.envConfig['ENABLE_ZONE'] === 'true' ? PruFormItemType.select : 'none',
          label: Translation('zones.text'),
          prop: 'zone',
          opts: filterOpts.zone ?? [],
          width: 400,
        },
        {
          type: window.envConfig['ENABLE_DESIGNATION'] === 'true' ? PruFormItemType.select : 'none',
          label: Translation('designation.text'),
          prop: 'designation',
          opts: designationOpts,
          width: 400,
        },
        {
          type: window.envConfig['ENABLE_BRANCH'] === 'true' ? PruFormItemType.autoComplete : 'none',
          label: Translation('Common.agent_branch'),
          prop: 'branch',
          opts: filterOpts.branch ?? [],
          width: 400,
        },
        {
          type: window.envConfig['ENABLE_UNIT'] === 'true' ? PruFormItemType.autoComplete : 'none',
          label: Translation('Common.agent_unit'),
          prop: 'unit',
          opts: filterOpts.unit ?? [],
          width: 400,
        },
      ],
    ];
  };

  const mapToApi = (params: any) => {
    params.size = params.pageSize;
    params.page = params.page + 1 || undefined;
    params.timeZone = moment.tz.guess();
    delete params.pageSize;

    params.examDateFrom = params.timeRange && params.timeRange[0];
    params.examDateTo = params.timeRange && params.timeRange[1];
    params.agentCode = params.recruiterCode;
    params.agentName = params.recruiterName;
    // backend wants YYYY-MM-DD format
    if (params.examDateFrom) {
      params.examDateFrom = getDayFormat(params.examDateFrom);
    }
    if (params.examDateTo) {
      params.examDateTo = getDayFormat(params.examDateTo);
    }
    delete params.timeRange;
    delete params.timeRangeType;
    delete params.recruiterCode;
    delete params.recruiterName;
  };

  // use callback to avoid unnecessary re-render
  const exportReport = useCallback(
    (filterData: any) => {
      try {
        const conditions = { ...filterData, lang };
        mapToApi(conditions);
        let mandatoryRulesPassed = true;
        if (!conditions.examCodes?.length && !conditions.examNames?.length) {
          mandatoryRulesPassed = false;
        }

        if (!conditions.examDateFrom || !conditions.examDateTo) {
          mandatoryRulesPassed = false;
        }
        if (!mandatoryRulesPassed) {
          PruToast({
            // 'Please select exams and exams time period to generate report'
            message: Translation('Recruitment.please_select_exams_and_exams_time_period_to_generate_report'),
          });
          return;
        }
        let exceed24Months = false;
        if (conditions.examDateTo && conditions.examDateFrom) {
          const diff = moment(conditions.examDateTo).diff(moment(conditions.examDateFrom), 'months');
          if (diff > 23) {
            exceed24Months = true;
          }
        }

        if (exceed24Months) {
          PruToast({
            // 'Invalid time range, please select no more than 24 months'
            message: Translation('Recruitment.invalid_time_range_please_select_no_more_than_24_months'),
          });
          return;
        }
        setIsDownloading(true);
        getExamPassingRateReportExcel(conditions)
          .then((res: any) => {
            const blob = new Blob([res.data || res], { type: res.data.type });
            const fileName = `Passing Rate Report ${moment().format('DDMMYYYY')}.xlsx`;
            if ('msSaveOrOpenBlob' in navigator) {
              // @ts-ignore
              window.navigator.msSaveOrOpenBlob(blob, fileName);
              setIsDownloading(false);
              return;
            }
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = fileName;
            link.href = url;
            if ('download' in document.createElement('a')) {
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else {
              // Compatible with browsers that do not support download
              link.target = '_blank';
              link.click();
            }
            URL.revokeObjectURL(url);
            setIsDownloading(false);
          })
          .catch((err) => {
            const errMsgKey = err.message ? `Recruitment.${err.message}` : err.message;
            const i18nHasKey = checkI18nKeyExist(errMsgKey);
            const defaultErrMsg = Translation('Recruitment.data_retrieval_failure');

            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: 'Error',
                  content: i18nHasKey ? Translation(errMsgKey) : defaultErrMsg,
                },
              ]),
            );
            setIsDownloading(false);
          });
      } catch (error) {
        setIsDownloading(false);
      }
    },
    [lang],
  );

  const onClickExport = useCallback(() => {
    const newPageData = {
      ...pageData,
      page: 0,
    };
    setPageData(newPageData);
    exportReport(filterData);
  }, [filterData, pageData, exportReport]);

  const onReset = () => {
    setFilterData(filterInitData);
  };

  return (
    <>
      <PruForm
        ref={formRef}
        config={filterConfig()}
        data={filterData}
        onChange={setFilterData}
        space={20}
        style={{ padding: 20 }}
      ></PruForm>
    </>
  );
};

export default PassingRateListPage;
