import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { memo } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { Translation } from 'src/app/i18n';

type ComponentProps = ParamsProps & {
  title: string;
  description: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const dialogStyle = {
  margin: 10,
  display: 'flex',
  justifyContent: 'flex-end',
};

export const ConfirmModalComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { title, description, open, onClose, onConfirm } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>

      <DialogActions style={dialogStyle}>
        <Button onClick={onClose} variant="outlined" color="inherit">
          {Translation('global.text.cancel')}
        </Button>

        <Button onClick={onConfirm} variant="contained" color="secondary">
          {Translation('global.text.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
