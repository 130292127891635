import { FC } from 'react';
import { useIntl } from 'react-intl';
import { AgentSegmentItem, PaginateList } from 'src/app/common/types'; // might differ based on the api response
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { AGENT_SUB_SEGMENT_PATH, CONFIG_BASE_PATH } from 'src/app/modules/Config/constants';
import { useHistory } from 'react-router-dom';
import { getAvailableServerLocale, getLangAbbreviation, useServerLocale } from 'src/app/i18n';
import { getDefaultDisplayDate } from '@pruforce/common-adminweb-sdk';
import { get } from 'lodash';
import { modulePrunews } from '../../sub-segment-detail/sub-segment-form/util';

type AgentSubSegmentListProps = {
  isLoading: boolean;
  agentSubSegmentList?: PaginateList<AgentSegmentItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
};

export const AgentSubSegmentList: FC<AgentSubSegmentListProps> = ({
  isLoading,
  agentSubSegmentList,
  onRefresh,
  onChangePage,
  onSort,
}) => {
  const history = useHistory();
  // const availableLocales = getAvailableServerLocale();
  const locale = useServerLocale();
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });

  return (
    <PruTable
      title={t('subsegment.list')}
      disableBulkSelect
      defaultRowsPerPage={20}
      headerBtnDef={[
        {
          color: 'secondary',
          title: t('app.button.add'),
          onClick: () => {
            history.push(`${CONFIG_BASE_PATH}${AGENT_SUB_SEGMENT_PATH}/create`);
          },
        },
      ]}
      operationDef={[
        {
          title: t('section.common.operation.view'),
          tooltipText: 'View',
          onClick: (row) => {
            history.push(`${CONFIG_BASE_PATH}${AGENT_SUB_SEGMENT_PATH}/edit/${row._id}/1`);
          },
        },
        {
          title: t('section.common.operation.edit'),
          tooltipText: 'Edit',
          onClick: (row) => {
            history.push(`${CONFIG_BASE_PATH}${AGENT_SUB_SEGMENT_PATH}/edit/${row._id}/0`);
          },
        },
      ]}
      columnDef={[
        {
          keyIndex: 'sub-name',
          align: 'center',
          displayName: `${t('subsegment')} (${getLangAbbreviation(locale)})`,
          renderData: (row) => (row.name ? row.name[locale] : '-') || '-',
        },
        {
          keyIndex: 'sub-segmentId',
          align: 'center',
          displayName: t('subsegment.id'),
          renderData: (row) => row._id || '-',
        },
        {
          keyIndex: 'name',
          align: 'center',
          displayName: `${t('newsArticle.common.segment')} (${getLangAbbreviation(locale)})`,
          renderData: (row) => {
            const name = get(row, 'segment.name') || {};
            return name[locale] || '-';
          },
        },
        {
          keyIndex: 'segmentId',
          align: 'center',
          displayName: `${t('newsArticle.common.segment')} ${t('newsCategory.common.id')}`,
          renderData: (row) => row.segmentId || '-',
        },
        {
          keyIndex: 'createdAt',
          align: 'center',
          displayName: t('configAgentSegment.common.createdAt'),
          renderData: (row) => (row.createdAt ? getDefaultDisplayDate(row.createdAt, 'datetime') : '-'),
        },
        {
          keyIndex: 'updatedAt',
          align: 'center',
          displayName: t('configAgentSegment.common.updatedAt'),
          renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(row.updatedAt, 'datetime') : '-'),
        },
        {
          keyIndex: 'modules',
          align: 'center',
          displayName: t('configAgentSegment.common.isPRUNews'),
          renderData: (row) =>
            row.modules && row.modules.includes(modulePrunews)
              ? t('configAgentSegment.common.enable')
              : t('configAgentSegment.common.disable'),
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={agentSubSegmentList?.docs}
      totalPages={agentSubSegmentList?.totalPages}
      totalRecords={agentSubSegmentList?.totalDocs}
      onChangePage={onChangePage}
    />
  );
};
