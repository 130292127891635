import React, { FC, memo, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
  Select,
  MenuItem,
} from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
// import { useStyles } from './sub-segment-form.style';
import { useSubSegmentForm } from './sub-segment-form.hook';
import { ConfigAgentEditItem, ConfigAgentFormMode, ConfigAgentItem } from 'src/app/modules/Config/types/config-type';
import { AgentSegmentItem, EnrolmentType } from 'src/app/common/types';
import { useStyles } from 'src/app/modules/Config/pages/AgentSegment/View/components/AgentSegmentForm.style';
import { useErrorHandler, ErrorFieldType, preFillZeroList, toAbsoluteUrl } from 'src/app/common/utils';
import { agentSegmentFormReducer, AgentSegmentFormState, detailToStateConvertor, initialState } from './util';
import { useHistory } from 'react-router-dom';
import DisplayKeyValueTable from '../../../Agent/View/components/DisplayKeyValueTable';
import { TargetApplicantComponent } from 'src/app/modules/News/pages/Article/View/components/target-applicant/target-applicant.component';
import { getAvailableServerLocale, useServerLocale } from 'src/app/i18n';
import { AgentSegmentEditItem, AgentSegmentFormMode } from 'src/app/modules/Config/types/agent-segment-type';
import { get, map } from 'lodash';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { createSubSegment, updateSubSegment } from 'src/app/modules/Config/network/agent-sub-segment.network';
import { useDispatch } from 'react-redux';
import { isAddedAgent } from '../../../AgentSegment/View/components/util/segment.util';
import { SubmitAlertComponent } from '../submit-alert/submit-alert.component';
import { AGENT_SUB_SEGMENT_PATH, CONFIG_AGENT_SEGMENT_PATH, CONFIG_BASE_PATH } from 'src/app/modules/Config/constants';
import { LoadingComponent } from 'src/app/common/components/loading/loading.component';
import { SubmitAlertComponent as ConfirmAlert } from '../../../AgentSegment/View/components/submit-alert/submit-alert.component';

type ComponentProps = ParamsProps & {
  formMode: ConfigAgentFormMode;
  data?: AgentSegmentItem;
  id?: string;
  readonly?: boolean;
};

export const SubSegmentFormComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { formMode, data, id } = props;
  // i18n
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const availableLocales = getAvailableServerLocale();
  const locale = useServerLocale();
  const dispatch = useDispatch();
  // const t = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const [formState, formDispatch] = useReducer(
    agentSegmentFormReducer,
    data ? detailToStateConvertor(data) : initialState,
  );
  const [attachedDialog, setAttachedDialog] = useState<boolean>(false);

  const history = useHistory();

  const modifyTargetApplicant = (
    option: keyof Pick<
      AgentSegmentFormState,
      | 'enrolmentType'
      | 'eligibleAgents'
      | 'eligibleDesignations'
      | 'excludedAgents'
      | 'agentListFileName'
      | 'agentListUploaded'
    >,
    value: any,
  ) => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: option, value } });
  };

  const { segmentOption, onSelectSegment, articleCount, parentData } = useSubSegmentForm({
    modifyTargetApplicant,
    data,
  });

  const parentInfo = parentData || data?.parentSegmentDetail;
  const parentSegmentName = useMemo(() => {
    const name = get(data, 'parentSegmentDetail.name') || {};
    return name[locale];
  }, [data]);
  const isDisabled = props.readonly;

  const disabledByDesignation = isDisabled || parentInfo?.enrolmentType !== EnrolmentType.BY_SEGMENT;
  const disabledByUploadAgent = isDisabled || parentInfo?.enrolmentType !== EnrolmentType.SPECIFIC;

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    ...availableLocales.map((locale) => ({
      name: `name${locale}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        let flag = false;
        if (!formState.name || !formState.name[locale]) {
          flag = true;
        }
        return flag;
      },
    })),
    {
      name: 'parentSegmentId',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        if (!formState.parentSegmentId || formState.parentSegmentId == '') {
          return true;
        }
        return false;
      },
    },
    {
      name: 'iconColor',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        let flag = false;
        if (!formState.iconBgColor || formState.iconBgColor == '') {
          flag = true;
        }
        return flag;
      },
    },
    {
      name: 'designation',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        if (
          // by desigation
          formState.enrolmentType === EnrolmentType.BY_SEGMENT &&
          (!formState.eligibleDesignations || formState.eligibleDesignations?.length == 0)
          // &&
          // (!formState.eligibleAgents || formState.eligibleAgents?.length == 0) &&
          // (!formState.excludedAgents || formState.excludedAgents?.length == 0)
        ) {
          return true;
        } else if (
          // by upload agent list
          formState.enrolmentType === EnrolmentType.SPECIFIC &&
          (!formState.agentListUploaded || formState.agentListUploaded?.length === 0)
        ) {
          return true;
        }
        return false;
      },
    },
  ]);

  useEffect(() => {
    onDismissErrorHandler(
      'designation',
      !(
        (!formState.eligibleDesignations || formState.eligibleDesignations?.length == 0) &&
        (!formState.eligibleAgents || formState.eligibleAgents?.length == 0) &&
        (!formState.excludedAgents || formState.excludedAgents?.length == 0) &&
        (!formState.agentListFileName || formState.agentListFileName == '')
      ),
    );
    // eslint-disable-next-line
  }, [formState.eligibleDesignations, formState.eligibleAgents, formState.excludedAgents, formState.agentListFileName]);

  const getEligibleAgents = () => {
    if (formState.enrolmentType === EnrolmentType.BY_SEGMENT) {
      return preFillZeroList(formState.eligibleAgents || []);
    }
    return preFillZeroList(formState.agentListUploaded || []);
  };
  const refSubmitErrMsg = useRef<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmitForm = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      refSubmitErrMsg.current = '';
      let excludedAgent: string[] = [];
      let eligibleDesignations: string[] = [];
      // Designation & excludedAgent only for 'BY_SEGMENT'
      if (formState.enrolmentType === EnrolmentType.BY_SEGMENT) {
        excludedAgent = preFillZeroList(formState.excludedAgents || []);
        eligibleDesignations = formState.eligibleDesignations || [];
      }
      // Call Api and submit
      const details: AgentSegmentEditItem = {
        name: formState.name,
        icon: '',
        iconBgColor: '',
        enrolmentType: formState.enrolmentType,
        eligibleSegments: {
          designation: eligibleDesignations,
        },
        eligibleAgents: getEligibleAgents(),
        excludedAgents: excludedAgent,
        agentListFileName: formState.agentListFileName || '',
        enableInPruNews: formState.enableInPruNews,
        enableInIncentives: formState.enableInIncentives,
        segmentId: formState.parentSegmentId,
        modules: formState.enableInPruNews ? ['prunews'] : [],
      };

      const isAddedNewAgent = isAddedAgent({
        enrolmentType: details.enrolmentType,
        eligibleAgentsNew: details.eligibleAgents,
        excludedAgentsNew: details.excludedAgents,
        designationNew: details.eligibleSegments?.designation,
        eligibleAgentsOld: parentInfo?.eligibleAgents,
        excludedAgentsOld: parentInfo?.excludedAgents,
        designationOld: parentInfo?.eligibleSegments?.designation,
      });
      if (isAddedNewAgent) {
        // alert user: current added invalid new agent
        return setIsOpen(true);
      }
      try {
        if (formMode === ConfigAgentFormMode.CREATE) {
          // handled submit error in outter catch
          await createSubSegment(details, dispatch).then(() => {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: t('save_done'),
                },
              ]),
            );
          });
        } else if (id && formMode === ConfigAgentFormMode.EDIT) {
          // handled submit error in outter catch
          await updateSubSegment(id, details, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: t('save_done'),
              },
            ]),
          );
        }
      } catch (err: any) {
        // handle submit error
        refSubmitErrMsg.current = err?.message;
        setIsOpen(true);
        return;
      }
      // back to list
      history.replace(`${CONFIG_BASE_PATH}${AGENT_SUB_SEGMENT_PATH}/list`);
    }
  };

  const [dialogMsg, setDialogMsg] = useState<{
    title: string;
    content: string;
    onCancel?: () => void;
    onOk?: () => void;
  }>();

  return (
    <div className={classes.container}>
      <div className={classes.sectionMargin}>
        <div className={classes.divideMargin}></div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {t('newsArticle.common.segment')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          {formMode !== ConfigAgentFormMode.CREATE ? (
            <div className="flex-grow tw-flex-grow">
              <Select
                disabled
                fullWidth
                displayEmpty
                renderValue={() => {
                  return parentSegmentName;
                }}
              />
            </div>
          ) : (
            <div className="flex-grow tw-flex-grow">
              <Select
                fullWidth
                native
                value={formState.parentSegmentId}
                onChange={(e) => {
                  const value = e.target.value;
                  onSelectSegment(value);
                  onDismissErrorHandler(`parentSegmentId`, value);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'parentSegmentId', value: value } });
                }}
              >
                <option disabled value="">
                  {t('app.input.placeholder.please-select')}
                </option>
                {map(segmentOption, (option) => (
                  <option key={option.value} value={option.value}>
                    {option.displayName}
                  </option>
                ))}
              </Select>
              {errorState.mandatory.parentSegmentId && (
                <div className={classes.errorText}>{t('field_mandatory_helper_label')}</div>
              )}
            </div>
          )}
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {`${t('newsArticle.common.segment')} ${t('newsCategory.common.id')}`}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          {formState.parentSegmentId ? (
            <span
              className="tw-cursor-pointer"
              onClick={() => {
                history.push(`${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}/edit/${formState.parentSegmentId}`);
              }}
            >
              {formState.parentSegmentId}
            </span>
          ) : (
            `--`
          )}
        </div>

        {availableLocales.map((locale) => (
          <div key={locale} className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {t(`configAgentSegment.common.name.${locale}`)}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div className="flex-grow tw-flex-grow">
              <TextField
                inputProps={{ maxLength: 60 }}
                disabled={isDisabled}
                error={errorState.mandatory[`name${locale}`]}
                margin="dense"
                variant="outlined"
                fullWidth
                helperText={errorState.mandatory[`name${locale}`] && t('field_mandatory_helper_label')}
                value={formState.name[locale] || ''}
                onChange={(e) => {
                  onDismissErrorHandler(`name${locale}`, e.target.value);
                  formDispatch({
                    type: 'MODIFY_LOCALE_FIELD',
                    payload: { field: 'name', locale, value: e.target.value },
                  });
                }}
              />
            </div>
          </div>
        ))}

        {/* targetApplicants */}

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {t('configAgentSegment.common.targetApplicants')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div className="flex-grow tw-flex-grow">
            <TargetApplicantComponent
              modifyTargetApplicant={modifyTargetApplicant}
              enrolmentType={formState.enrolmentType}
              formState={formState as any}
              // errorState={errorState}
              disabled={isDisabled}
              disabledByUploadAgent={disabledByUploadAgent}
              disabledByDesignation={disabledByDesignation}
              formMode={formMode as any}
              formDispatch={formDispatch as any}
              onToggleDesignation={(value) => {
                formDispatch({ type: 'TOGGLE_DESIGNATION', payload: { value } });
              }}
            />

            {errorState.mandatory.designation && (
              <span className={classes.errorText}>{t('field_mandatory_helper_label')}</span>
            )}
          </div>
        </div>
      </div>

      {/* enable segment button in prunews */}
      <div className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>{t('segment.modules')} :</span>
        </div>

        <FormControlLabel
          control={
            <Checkbox
              disabled={isDisabled}
              checked={formState.enableInPruNews}
              onChange={async (e) => {
                // enabled -> disabled: check if have attached article
                if (!e.target.checked && articleCount && articleCount > 0) {
                  // confirm to disable it.
                  setDialogMsg({
                    onOk: () => {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'enableInPruNews', value: false },
                      });
                      setDialogMsg({ title: '', content: '' });
                    },
                    onCancel: () => setDialogMsg({ title: '', content: '' }),
                    title: t('reminder_title'),
                    content: t('news.subsegment.disable.confirm'),
                  });
                  // This subsegment is attached with articles. Are you sure you want to hide this from PRUNews
                  return;
                }
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'enableInPruNews', value: e.target.checked },
                });
              }}
            />
          }
          checked={formState.enableInPruNews === false}
          label={t('route.news')}
        />
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" disabled={isDisabled} onClick={onSubmitForm}>
          {t('app.button.submit')}
        </Button>
      </div>
      <SubmitAlertComponent open={isOpen} content={refSubmitErrMsg.current} onCancel={handleClose} />

      <ConfirmAlert
        open={!!dialogMsg?.title}
        title={dialogMsg?.title}
        content={dialogMsg?.content}
        onCancel={dialogMsg?.onCancel}
        onOk={dialogMsg?.onOk}
      />
    </div>
  );
});
