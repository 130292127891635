import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { memo, useState } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { Translation } from 'src/app/i18n';

type ComponentProps = ParamsProps & {
  title?: string;
  inputLabel?: string;
  open: boolean;
  onClose: () => void;
  onConfirm: (inputValue: string) => void;
};

const dialogStyle = {
  margin: 10,
  display: 'flex',
  justifyContent: 'flex-end',
};

export const InputModalComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { title, inputLabel, open, onClose, onConfirm } = props;
  const [inputValue, setInputValue] = useState('');

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          required
          label={inputLabel}
          variant="outlined"
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </DialogContent>

      <DialogActions style={dialogStyle}>
        <Button onClick={onClose} variant="outlined" color="inherit">
          {Translation('global.text.cancel')}
        </Button>

        <Button
          onClick={() => {
            onConfirm(inputValue);
          }}
          variant="contained"
          color="secondary"
        >
          {Translation('global.text.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
