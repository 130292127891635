import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { PruTableSortTypeEnum } from 'src/app/common/components/PruTable/PruTable';
import { UserManagementLogItem } from '../../../types/user-management-log-types'; // create user-management-log-types.ts in types folder
import {
  UserManagementLogListParam,
  fetchLogFilterOptions,
  fetchUserManagementLogList,
} from '../../../network/user-management-log-crud'; // create user-management-log-crud.ts in types folder
import { useIntl } from 'react-intl';

const initialState: UserManagementLogListParam = {
  page: 1,
  limit: 20,
};

export const userManagementLogFilterKeys = [];

type DynamicFilterOptionsItem = Record<string, { value: string; displayName: string }[]>;

const paramsInitiator = (initialParams?: Record<string, string>): UserManagementLogListParam => {
  return initialParams
    ? {
        ...initialParams,
        ...initialState,
      }
    : initialState;
};
export enum LogsModuleEnum {
  NEWS = 'NEWS',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
}

// both UserManagementLog & news-article-log.screen.tsx will use this hook
export const useUserManagementLogListingPage = ({ initialParams, onChangeQueryParams }: ParamsProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [filterState, setFilterState] = useState<UserManagementLogListParam>(paramsInitiator(initialParams));
  const [userManagementLogList, setUserManagementLogList] = useState<PaginateList<UserManagementLogItem>>();
  const [dynamicFilterOptions, setDynamicFilterOptions] = useState<DynamicFilterOptionsItem>({});

  const { isLoading, refreshData } = useDataProvider<PaginateList<UserManagementLogItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchUserManagementLogList(filterState, dispatch);
      } catch (err) {}
    },
    setUserManagementLogList,
    false,
  );

  const onSort = (newSortState: { key: string; value?: string }) => {
    const { key, value } = newSortState;
    if (value) {
      let newSort = '';
      switch (value) {
        case PruTableSortTypeEnum.ASC:
          newSort = key;
          break;
        case PruTableSortTypeEnum.DESC:
          newSort = `-${key}`;
          break;
      }
      setFilterState({ ...filterState, sort: newSort });
    } else {
      setFilterState({ ...filterState, sort: undefined });
    }
    refreshData();
  };

  useEffect(() => {
    fetchLogFilterOptions({ module: initialParams?.module }, dispatch).then(
      (
        filters: Array<{
          name: string;
          values: string[];
        }>,
      ) => {
        let filterData: any = {};
        filters.forEach((filter) => {
          filterData[filter.name] = [
            {
              value: '',
              displayName: Translation('component.status.all'),
            },
          ];
          filterData[filter.name].push(...filter.values.map((value) => ({ value, displayName: value })));
        });
        setDynamicFilterOptions(filterData);
      },
    );
    // only run one time, do not add initialParams dep here
  }, []);

  return {
    filterState,
    userManagementLogList,
    isLoading,
    dynamicFilterOptions,
    setFilterState,
    refreshData,
    onSort,
  };
};
