import React, { FC, useEffect, useMemo } from 'react';
import * as Survey from 'survey-knockout';
import * as SurveyJSCreator from 'survey-creator';
import 'survey-knockout/modern.css';
import 'survey-creator/survey-creator.css';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'react';
import { useStyles } from './survey-creator.style';

type SurveyCreatorProps = {
  content?: Record<string, unknown>;
  onChangeContent: (content: Record<string, unknown>) => void;
  module?: string;
};

const SurveyCreator: FC<SurveyCreatorProps> = ({ content, onChangeContent, module }) => {
  const dispatch = useDispatch();

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  async function handleFile(file: any, dispatch: Dispatch<any>) {
    const createBlobRes = await createBlob({ mimeType: file.type, accessLevel: 'public', module }, dispatch);
    await fileUpload(createBlobRes.url, file);
    const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
    return blobDetail[0];
  }
  useEffect(() => {
    SurveyJSCreator.StylesManager.applyTheme('darkblue');
    Survey.Serializer.findProperty('survey', 'locale').visible = false;
    // Survey.Serializer.removeProperty("survey", "logo");

    const options = {};

    const creator = new SurveyJSCreator.SurveyCreator('survey-creator', options);

    creator.showToolbox = 'right';
    creator.showJSONEditorTab = false;
    creator.showEmbededSurveyTab = false;
    creator.showLogicTab = true;
    creator.showPropertyGrid = 'right';
    creator.isAutoSave = true;
    creator.allowControlSurveyTitleVisibility = false;
    creator.showSurveyTitle = 'always';
    creator.onUploadFile.add(function (creator: any, option: any) {
      option.files.forEach(async function (file: any) {
        let result = await handleFile(file, dispatch);

        option.callback('success', result?.url);
      });
    });
    //  Enable Commercial License
    creator.haveCommercialLicense = true;

    if (content !== undefined) {
      creator.text = JSON.stringify(content);
    }

    creator.saveSurveyFunc = () => {
      onChangeContent(creator.JSON);
    };
    // eslint-disable-next-line
  }, []);

  return <div id="survey-creator" className={styles.surveyCreator} />;
};

export default SurveyCreator;
