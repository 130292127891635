import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import commonStyles from 'src/app/common/styles/page.module.css';
import { useStyles } from './add-schedule.style';
import { useAddSchedule } from './add-schedule.hook';
import { CreateSchedule } from '../components/create-schedule/create-schedule.component';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { CreateScheduleProps } from '../components/create-schedule/create-schedule.hook';
import { getExamFieldsOptions } from 'src/app/modules/Recruitment/network/license-exam-crud';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  } & ModulePermissionProps;

export const AddSchedulePage: React.FC<PageProps> = (props: PageProps) => {
  const Translation = useTranslation();

  const [options, setOptions] = React.useState<CreateScheduleProps['autoCompleteOptions']>({
    examCodeOptions: [] as string[],
    examNameOptions: [] as string[],
    localExamNameOptions: [] as string[],
    venueOptions: [] as string[],
    addressOptions: [] as string[],
  });

  React.useEffect(() => {
    const fetchOptions = async () => {
      const { examCodeOptions, examNameOptions, localExamNameOptions, venueOptions, addressOptions } =
        await getExamFieldsOptions();
      setOptions({
        examCodeOptions,
        examNameOptions,
        localExamNameOptions,
        venueOptions,
        addressOptions,
      });
    };

    fetchOptions();
  }, []);

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { initialValues, onSubmit } = useAddSchedule();

  return (
    <div className={`${commonStyles.container} ${styles.container}`}>
      <div className={commonStyles.headerContainer}>
        <div className={commonStyles.rowContainer}>
          <div className={commonStyles.header}>{Translation('recruitment.exam.schedule.add')}</div>
        </div>
        <Button variant="contained" color="inherit" onClick={props.history.goBack}>
          {Translation('app.button.back')}
        </Button>
      </div>
      <CreateSchedule
        showSubmit={props.enableCreate}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={() => {
          props.history.goBack();
        }}
        autoCompleteOptions={options}
      />
    </div>
  );
};
