import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import { useIntl } from 'react-intl';
import { DateView } from '@mui/x-date-pickers';

interface RangePickerProps {
  value?: any[];
  errors?: boolean[];
  helperTexts?: string[];
  onChange?: (e: any[]) => void;
  format?: string;
  disabled?: boolean;
  needLabel?: boolean;
  fields?: any[];
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const isDateFormat = (date: Date | null) => {
  return date instanceof Date && !isNaN(date.getTime());
};

// Add date processing tool functions
const adjustDateForMonthFormat = (date: Date | null, isEnd: boolean): Date | null => {
  if (!isDateFormat(date)) return null;

  const newDate = new Date(date as Date);
  if (isEnd) {
    // Set to the end of the month
    newDate.setDate(1);
    newDate.setMonth(newDate.getMonth() + 1);
    newDate.setDate(0); // Set to the day before the first day of next month, that is, the last day of this month
  } else {
    // Set to the beginning of the month
    newDate.setDate(1);
  }
  return newDate;
};

export default function RangePicker(props: RangePickerProps) {
  const { classes } = useStyles();
  const { value = [], onChange, needLabel = true, format, fields } = props;
  const views = useMemo(() => (format === 'MM/YYYY' ? (['year', 'month'] as DateView[]) : undefined), [format]);
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const handleChange = (key: number) => (date: Date | null) => {
    if (!isDateFormat(date)) {
      date = null;
    }
    // Adjust date when format is MM/YYYY
    if (format === 'MM/YYYY' && date) {
      date = adjustDateForMonthFormat(date, key === 1);
    }
    if (!onChange) return;
    const newValue = [...(value || [])];
    newValue[key] = date;
    onChange(newValue);
  };

  const placeSelect = Translation('app.input.placeholder.please-select');

  return (
    <div className={classes.container}>
      <PruDatePicker
        views={views}
        minDate={fields?.[0]?.minDate}
        maxDate={(value && value[1]) || fields?.[0]?.maxDate}
        slotProps={{
          textField: {
            error: props.errors && !!props.errors[0],
            helperText: props.helperTexts && props.helperTexts[0],
          },
        }}
        format={format ?? 'DD/MM/YYYY'}
        value={(value && value[0]) || null}
        disabled={props.disabled}
        onChange={handleChange(0)}
        label={needLabel ? placeSelect : undefined}
      />
      <div className={classes.divider}>-</div>
      <PruDatePicker
        views={views}
        minDate={(value && value[0]) || fields?.[1]?.minDate}
        maxDate={fields?.[1]?.maxDate}
        slotProps={{
          textField: {
            error: props.errors && !!props.errors[1],
            helperText: props.helperTexts && props.helperTexts[1],
          },
        }}
        format={format ?? 'DD/MM/YYYY'}
        value={(value && value[1]) || null}
        onChange={handleChange(1)}
        disabled={props.disabled}
        label={needLabel ? placeSelect : undefined}
      />
    </div>
  );
}
