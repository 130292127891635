import { PaginateList } from '../../../common/types/common-types';

export enum NewsCategoryFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum AgentSegmentFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export type CategoryName = Record<string, string>;

export type NewsCategoryEmailItem = {
  name: string;
  address: string;
};

export type NewsCategoryItem = {
  _id: string;
  name: CategoryName;
  emails: NewsCategoryEmailItem[];
  color?: string;
  order?: Number;
  createdAt: Date;
  updatedAt: Date;
  key?: string;
  maxNoOfHeadlines: number;
  maxArchiveDays: number;
};

export type NewsCategoryEditItem = {
  emails: NewsCategoryEmailItem[];
  color?: string;
  maxNoOfHeadlines: number;
  maxArchiveDays: number;
};

export type SegmentNameItem = Record<string, string>;
export type NewsTitleItem = Record<string, string>;

export type NewsContentItem = Record<string, string>;

export type NewsContentWordcountItem = Record<string, number>;

export type NewsAttachmentItem = {
  blobId: string;
  filename: string;
  url?: string;
};

export type NewsImageItem = {
  blobId: string;
  type?: string;
};

export type AgentSegmentEditItem = {
  name: SegmentNameItem;
  icon: string;
  iconBgColor: string;
  enrolmentType?: EnrolmentType;
  eligibleSegments: {
    designation: string[];
  };
  eligibleAgents: string[];
  excludedAgents: string[];
  agentListFileName?: string;
  enableInPruNews: boolean;
  enableInIncentives: boolean;
  segmentId?: string;
  modules?: string[];
};

export type NewsHeadlineItem = {
  newsId: string;
  categoryId?: string;
  publishDate?: Date;
  archiveDate?: Date;
  title?: NewsTitleItem;
  content?: NewsContentItem;
  likes?: number;
  bookmarked?: number;
  comments?: number;
  isSuggested?: boolean;
  isHeadline?: boolean;
  category?: NewsCategoryItem;
  createdAt?: Date;
  updatedAt?: Date;
  banner?: NewsImageItem;
  thumbnail?: NewsImageItem;
  tags?: string[];
  wordCount?: NewsContentWordcountItem;
  enquiryEmail?: string;
  createdBy?: string;
  updatedBy?: string;
  attachments?: NewsAttachmentItem[];
  triggerPush?: boolean;
  readCount?: number;
  status?: string;
};

export type NewsHeadlineItemList = {
  headlines?: NewsHeadlineItem[];
};

export type NewsCategoryPaginateList = PaginateList<NewsCategoryItem> & {
  categories: NewsCategoryItem[];
  totalRecords: number;
};

export type NewsHeadlinePaginateList = PaginateList<NewsHeadlineItem> & {
  newsList: NewsHeadlineItem[];
  totalRecords: number;
};

export enum EnrolmentType {
  BY_SEGMENT = 'SEGMENTS',
  SPECIFIC = 'AGENTS',
}
