import { FC, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { AgentSegmentItem } from 'src/app/common/types';
import { AgentSegmentFormMode, SegmentNameItem, AgentSegmentEditItem } from '../../../../types/agent-segment-type';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';

import { Button, TextField, FormControlLabel, Dialog, RadioGroup, Radio } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { CONFIG_BASE_PATH, CONFIG_AGENT_SEGMENT_PATH, AdminAgentSegmentPermissionType } from '../../../../constants';
import { useIntl } from 'react-intl';
import { getAvailableServerLocale } from 'src/app/i18n';
import {
  ErrorFieldType,
  useErrorHandler,
  useJWT,
  preFillZeroList,
  toAbsoluteUrl,
  isPermitted,
} from 'src/app/common/utils';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { useDispatch } from 'react-redux';
import {
  createNewAgentSegment,
  modifyAgentSegment,
  fetchCheckNewsAttached,
} from '../../../../network/agentSegmentCrud';
import { get, map } from 'lodash';
// import TargetApplicationSection from './TargetApplicantSection';
import TargetApplicationSection_MO_BROKER from './TargetApplicantSection_MO_BROKER';
import { EnrolmentType } from 'src/app/modules/News/types/news-type';
import { MuiColorInput } from 'mui-color-input';
import { TargetApplicantComponent } from 'src/app/modules/News/pages/Article/View/components/target-applicant/target-applicant.component';
import { hasActiveSubsegment, hasInactiveSubsegment, isRemovedAgent } from './util/segment.util';
import { SubmitAlertComponent } from './submit-alert/submit-alert.component';
import { useStyles } from './AgentSegmentForm.style';
import { getSubSegmentConfig } from 'src/app/modules/News/pages/Article/View/components/NewsDetail.util';

type AgentSegmentFormProps = {
  code?: string;
  formMode: AgentSegmentFormMode;
  agentSegment?: AgentSegmentItem;
  lastFileName: Pick<AgentSegmentItem, 'agentListFileName'>;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

export type AgentSegmentFormState = {
  SegmentId: string;
  name: SegmentNameItem;
  icon: string;
  iconBgColor: string;
  enrolmentType: EnrolmentType;
  eligibleSegments: {
    designation: string[];
  };
  eligibleDesignations?: string[];
  eligibleAgents: string[];
  excludedAgents: string[];
  agentListFileName?: string;
  agentListUploaded?: string[];
  region: string;
  channel: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  enableInPruNews: boolean;
  enableInIncentives: boolean;
};

const initialState: AgentSegmentFormState = {
  SegmentId: '',
  name: {},
  icon: '',
  iconBgColor: '#ff0000',
  enrolmentType: EnrolmentType.BY_SEGMENT,
  eligibleSegments: {
    designation: [],
  },
  eligibleAgents: [],
  excludedAgents: [],
  agentListFileName: '',
  region: '',
  channel: '',
  createdBy: '',
  updatedBy: '',
  createdAt: null,
  updatedAt: null,
  eligibleDesignations: undefined,
  enableInPruNews: true,
  enableInIncentives: true,
};

const detailToStateConvertor = (detail: AgentSegmentItem): AgentSegmentFormState => {
  const eligibleDesignations = get(detail, 'eligibleSegments.designation', []);
  // detail.eligibleSegments && detail.eligibleSegments.designation ? detail.eligibleSegments.designation : [];
  return {
    SegmentId: detail._id,
    name: detail.name || {},
    icon: detail.icon,
    iconBgColor: detail.iconBgColor || '#ff0000',
    enrolmentType: detail.enrolmentType,
    eligibleSegments: detail.eligibleSegments || { designation: [] },
    eligibleAgents: detail.enrolmentType === EnrolmentType.BY_SEGMENT ? detail.eligibleAgents : [],
    excludedAgents: detail.excludedAgents || [],
    agentListFileName: detail.agentListFileName,
    agentListUploaded: detail.enrolmentType === EnrolmentType.SPECIFIC ? detail.eligibleAgents : undefined,
    region: detail.region,
    channel: detail.channel,
    createdBy: detail.createdBy,
    updatedBy: detail.updatedBy,
    createdAt: detail.createdAt || null,
    updatedAt: detail.updatedAt || null,
    enableInPruNews: detail.enableInPruNews || false,
    enableInIncentives: detail.enableInIncentives || false,
    eligibleDesignations,
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof AgentSegmentFormState;
    value: any;
  };
};

type ModifyLocaleFieldAction = {
  type: 'MODIFY_LOCALE_FIELD';
  payload: {
    field: keyof Pick<AgentSegmentFormState, 'name'>;
    locale: string;
    value: any;
  };
};

type ToggleDesignationAction = {
  type: 'TOGGLE_DESIGNATION';
  payload: {
    value: string;
  };
};

type AgentSegmentFormAction = ModifyFieldAction | ToggleDesignationAction | ModifyLocaleFieldAction;

const agentSegmentFormReducer = (
  state: AgentSegmentFormState,
  action: AgentSegmentFormAction,
): AgentSegmentFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };

    case 'MODIFY_LOCALE_FIELD':
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          [action.payload.locale]: action.payload.value,
        },
      };

    case 'TOGGLE_DESIGNATION':
      const newDesignation = [...(state.eligibleDesignations || [])];
      const itemIndex = newDesignation.findIndex((designation) => designation === action.payload.value);
      newDesignation[itemIndex] ? newDesignation.splice(itemIndex, 1) : newDesignation.push(action.payload.value);
      return {
        ...state,
        eligibleDesignations: newDesignation,
      };
  }
};

const AgentSegmentForm: FC<AgentSegmentFormProps> = ({ agentSegment, code, formMode, onRouteTo }) => {
  const availableLocales = getAvailableServerLocale();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const [formState, formDispatch] = useReducer(
    agentSegmentFormReducer,
    agentSegment ? detailToStateConvertor(agentSegment) : initialState,
  );
  const [attachedDialog, setAttachedDialog] = useState<boolean>(false);

  const isDisabled = false;
  const jwt = useJWT() || {};
  const { region, channel } = jwt;

  let approverPermission = false;
  let archivePermission = false;
  if (jwt.permissions) {
    const jwtPermission = map(jwt.permissions, (_, key) => key);
    approverPermission = isPermitted([AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_APPROVE], jwtPermission);
    archivePermission = isPermitted([AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_ARCHIVE], jwtPermission);
  }

  const modifyTargetApplicant = (
    option: keyof Pick<
      AgentSegmentFormState,
      | 'enrolmentType'
      | 'eligibleAgents'
      | 'eligibleDesignations'
      | 'excludedAgents'
      | 'agentListFileName'
      | 'agentListUploaded'
    >,
    value: any,
  ) => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: option, value } });
  };

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    ...availableLocales.map((locale) => ({
      name: `name${locale}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        let flag = false;
        if (!formState.name || !formState.name[locale]) {
          flag = true;
        }
        return flag;
      },
    })),
    {
      name: 'icon',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        let flag = false;
        if (!formState.icon || formState.icon == '') {
          flag = true;
        }
        return flag;
      },
    },
    {
      name: 'iconColor',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        let flag = false;
        if (!formState.iconBgColor || formState.iconBgColor == '') {
          flag = true;
        }
        return flag;
      },
    },
    {
      name: 'designation',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        if (
          // by desigation
          formState.enrolmentType === EnrolmentType.BY_SEGMENT &&
          (!formState.eligibleDesignations || formState.eligibleDesignations?.length == 0)
          // update logic: desigation is MANDATORY, same as push-notification
          // (!formState.eligibleDesignations || formState.eligibleDesignations?.length == 0) &&
          // (!formState.eligibleAgents || formState.eligibleAgents?.length == 0) &&
          // (!formState.excludedAgents || formState.excludedAgents?.length == 0)
        ) {
          return true;
        } else if (
          // by upload agent list
          formState.enrolmentType === EnrolmentType.SPECIFIC &&
          (!formState.agentListUploaded || formState.agentListUploaded?.length === 0)
        ) {
          return true;
        }
        return false;
        // old logic
        // if (
        //   (!formState.eligibleDesignations || formState.eligibleDesignations?.length == 0) &&
        //   (!formState.eligibleAgents || formState.eligibleAgents?.length == 0) &&
        //   (!formState.excludedAgents || formState.excludedAgents?.length == 0) &&
        //   (!formState.agentListFileName || formState.agentListFileName == '')
        // ) {
        //   return true;
        // } else {
        //   return false;
        // }
      },
    },
  ]);

  useEffect(() => {
    onDismissErrorHandler(
      'designation',
      !(
        (!formState.eligibleDesignations || formState.eligibleDesignations?.length == 0) &&
        (!formState.eligibleAgents || formState.eligibleAgents?.length == 0) &&
        (!formState.excludedAgents || formState.excludedAgents?.length == 0) &&
        (!formState.agentListFileName || formState.agentListFileName == '')
      ),
    );
    // eslint-disable-next-line
  }, [formState.eligibleDesignations, formState.eligibleAgents, formState.excludedAgents, formState.agentListFileName]);

  const getEligibleAgents = () => {
    if (formState.enrolmentType === EnrolmentType.BY_SEGMENT) {
      return preFillZeroList(formState.eligibleAgents || []);
    }
    return preFillZeroList(formState.agentListUploaded || []);
  };

  const refSubmitData = useRef<AgentSegmentEditItem>();
  const handleOk = async () => {
    await modifyAgentSegment(code || '', refSubmitData.current as AgentSegmentEditItem, dispatch);
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: Translation('configAgentSegment.success.updated', { code: code || '' }),
        },
      ]),
    );
    onRouteTo(`${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}`);
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dialogMsg, setDialogMsg] = useState<{
    title: string;
    content: string;
    onCancel?: () => void;
    onOk?: () => void;
  }>();

  const handleClose = () => {
    setIsOpen(false);
  };

  // get config
  // const { enabledSubsegment } = useMemo(() => {
  //   return getSubSegmentConfig();
  // }, []);

  // not allow to change type when have sub-segment
  const haveSubSegment = get(agentSegment, 'subSegmentList.docs.length', 0) > 0 ? true : false;
  const haveActiveSubsegment = useMemo(() => {
    return hasActiveSubsegment(agentSegment);
  }, [agentSegment]);

  const onSubmitForm = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      let excludedAgent: string[] = [];
      let eligibleDesignations: string[] = [];
      // Designation & excludedAgent only for 'BY_SEGMENT'
      if (formState.enrolmentType === EnrolmentType.BY_SEGMENT) {
        excludedAgent = preFillZeroList(formState.excludedAgents || []);
        eligibleDesignations = formState.eligibleDesignations || [];
      }
      // Call Api and submit
      const details: AgentSegmentEditItem = {
        name: formState.name,
        icon: formState.icon,
        iconBgColor: formState.iconBgColor,
        enrolmentType: formState.enrolmentType,
        eligibleSegments: {
          designation: eligibleDesignations,
        },
        eligibleAgents: getEligibleAgents(),
        excludedAgents: excludedAgent,
        agentListFileName: formState.agentListFileName || '',
        enableInPruNews: formState.enableInPruNews,
        enableInIncentives: formState.enableInIncentives,
      };
      try {
        if (formMode === AgentSegmentFormMode.CREATE) {
          await createNewAgentSegment(details, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: Translation('configAgentSegment.success.saved'),
              },
            ]),
          );
          onRouteTo(`${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}`);
        } else if (code && formMode === AgentSegmentFormMode.EDIT) {
          refSubmitData.current = details;
          if (!haveSubSegment) {
            await handleOk();
            return;
          }

          const isAgentRemoved = isRemovedAgent({
            enrolmentType: details.enrolmentType,
            eligibleAgentsNew: details.eligibleAgents,
            excludedAgentsNew: details.excludedAgents,
            designationNew: details.eligibleSegments?.designation,
            eligibleAgentsOld: agentSegment?.eligibleAgents,
            excludedAgentsOld: agentSegment?.excludedAgents,
            designationOld: agentSegment?.eligibleSegments?.designation,
          });

          if (isAgentRemoved) {
            return setIsOpen(true);
          } else {
            await handleOk();
          }
        }
      } catch (err) {
        console.error('submit err:', err);
      }
    }
  };

  const enrolmentType = agentSegment?.enrolmentType;
  const disabledByDesignation = isDisabled || (haveActiveSubsegment && enrolmentType !== EnrolmentType.BY_SEGMENT);
  const disabledByUploadAgent = isDisabled || (haveActiveSubsegment && enrolmentType !== EnrolmentType.SPECIFIC);

  ///png handle
  const pngFiles = ['health.png', 'medical.png', 'reward.png', 'runner.png', 'team.png', 'tie.png', 'trophy.png'];
  const pngRadioButtons = Object.entries(pngFiles).map(([label, value]) => {
    const imageUrl = toAbsoluteUrl(`/media/icon/segments_icon/${value}`);
    const imageAlt = value;
    const labelContent = (
      <div className={classes.thumbnailContainer}>
        <img src={imageUrl} alt={imageAlt} className={classes.thumbnailImage} />
      </div>
    );

    return (
      <FormControlLabel
        key={value}
        value={value}
        control={<Radio disabled={false} />}
        label={labelContent}
        checked={formState.icon === value}
        onChange={() => {
          onDismissErrorHandler('icon', value);
          formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'icon', value: value } });
        }}
      />
    );
  });

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {formMode === AgentSegmentFormMode.CREATE
              ? Translation('configAgentSegment.common.newAgentSegment')
              : Translation('configAgentSegment.edit')}
          </div>
        </div>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            history.push(`${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}`);
          }}
        >
          {Translation('app.button.back')}
        </Button>
      </div>
      <div className={classes.sectionMargin}>
        <div className={classes.divideMargin}></div>
        {availableLocales.map((locale) => (
          <div key={locale} className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation(`configAgentSegment.common.name.${locale}`)}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                inputProps={{ maxLength: 100 }}
                disabled={isDisabled}
                error={errorState.mandatory[`name${locale}`]}
                margin="dense"
                variant="outlined"
                fullWidth
                helperText={errorState.mandatory[`name${locale}`] && Translation('field_mandatory_helper_label')}
                value={formState.name[locale] || ''}
                onChange={(e) => {
                  onDismissErrorHandler(`name${locale}`, e.target.value);
                  formDispatch({
                    type: 'MODIFY_LOCALE_FIELD',
                    payload: { field: 'name', locale, value: e.target.value },
                  });
                }}
              />
            </div>
          </div>
        ))}

        {/* working */}

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('configAgentSegment.common.icon')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>

          <RadioGroup aria-label="icon" name="icon" style={{ display: 'flex', flexDirection: 'row' }}>
            {pngRadioButtons}
          </RadioGroup>
          {errorState.mandatory.icon && (
            <span className={classes.errorText}>{Translation('field_mandatory_helper_label')}</span>
          )}
        </div>
        {/* working */}

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('configAgentSegment.common.color')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <MuiColorInput
            isAlphaHidden
            name="color"
            margin="dense"
            variant="outlined"
            format="hex"
            error={errorState.mandatory.color}
            helperText={errorState.mandatory.color && Translation('field_mandatory_helper_label')}
            value={formState.iconBgColor}
            onChange={(color) => {
              onDismissErrorHandler('color', color);
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'iconBgColor', value: color } });
            }}
          />
          {errorState.mandatory.iconColor && (
            <span className={classes.errorText}>{Translation('field_mandatory_helper_label')}</span>
          )}
        </div>
        {/* working */}

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer} style={{ alignSelf: 'flex-start', paddingTop: '8px' }}>
            <span className={classes.field}>
              {Translation('configAgentSegment.common.targetApplicants')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ width: '75%' }}>
            {channel == 'BROKER' && region == 'MO' ? (
              <TargetApplicationSection_MO_BROKER
                isDisabled={isDisabled}
                enrolmentType={formState.enrolmentType}
                eligibleDesignations={formState.eligibleDesignations || []}
                eligibleAgents={formState.eligibleAgents || []}
                excludedAgents={formState.excludedAgents || []}
                agentListFileName={formState.agentListFileName}
                onModifyTarget={modifyTargetApplicant}
                onToggleDesignation={(value) => {
                  formDispatch({ type: 'TOGGLE_DESIGNATION', payload: { value } });
                }}
              />
            ) : (
              <TargetApplicantComponent
                modifyTargetApplicant={modifyTargetApplicant}
                enrolmentType={formState.enrolmentType}
                formState={formState as any}
                // errorState={errorState}
                formMode={formMode as any}
                formDispatch={formDispatch as any}
                onToggleDesignation={(value) => {
                  formDispatch({ type: 'TOGGLE_DESIGNATION', payload: { value } });
                }}
                disabledByUploadAgent={disabledByUploadAgent}
                disabledByDesignation={disabledByDesignation}
              />
              // old
              // <TargetApplicationSection
              //   isDisabled={isDisabled}
              //   enrolmentType={formState.enrolmentType}
              //   eligibleDesignations={formState.eligibleDesignations || []}
              //   eligibleAgents={formState.eligibleAgents || []}
              //   excludedAgents={formState.excludedAgents || []}
              //   agentListFileName={formState.agentListFileName}
              //   onModifyTarget={modifyTargetApplicant}
              //   onToggleDesignation={(value) => {
              //     formDispatch({ type: 'TOGGLE_DESIGNATION', payload: { value } });
              //   }}
              // />
            )}

            {errorState.mandatory.designation && (
              <span className={classes.errorText}>{Translation('field_mandatory_helper_label')}</span>
            )}
          </div>
        </div>
      </div>

      {/* enable segment button in prunews */}
      <div className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>
            {Translation('configAgentSegment.common.isPRUNews')}
            <span className={classes.mandatory}>*</span> :
          </span>
        </div>
        <div style={{ width: '75%' }}>
          <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              value="Enable"
              control={<Radio disabled={false} />}
              label={Translation('configAgentSegment.common.enable')}
              checked={formState.enableInPruNews === true}
              onChange={() => {
                const haveInactiveSub = hasInactiveSubsegment(agentSegment);
                if (haveInactiveSub) {
                  setDialogMsg({
                    onOk: () => {
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enableInPruNews', value: true } });
                      setDialogMsg({ title: '', content: '' });
                    },
                    onCancel: () => setDialogMsg({ title: '', content: '' }),
                    title: Translation('reminder_title'),
                    content: Translation('news.segment.enable.confirm'),
                  });
                  return;
                } else {
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enableInPruNews', value: true } });
                }
              }}
            />
            <FormControlLabel
              value="Disable"
              control={<Radio disabled={false} />}
              checked={formState.enableInPruNews === false}
              label={Translation('configAgentSegment.common.disable')}
              onChange={async () => {
                if (formMode === AgentSegmentFormMode.EDIT) {
                  const isAttachedArticle = await fetchCheckNewsAttached(formState.SegmentId);
                  let alertContent = '';

                  if (haveActiveSubsegment) {
                    // only display when have active sub-segment
                    alertContent =
                      Translation('subsegment') + ': \n' + Translation('news.segment.disable.confirm') + '\n\n';
                  }
                  if (isAttachedArticle) {
                    alertContent +=
                      Translation('title.news.article') +
                      ': \n' +
                      Translation('configAgentSegment.dialog.newsAttached.title');
                    // setAttachedDialog(true);
                    // return;
                  }
                  if (alertContent) {
                    setDialogMsg({
                      onOk: () => {
                        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enableInPruNews', value: false } });
                        setDialogMsg({ title: '', content: '' });
                      },
                      onCancel: () => setDialogMsg({ title: '', content: '' }),
                      title: Translation('reminder_title'),
                      content: alertContent,
                    });
                    return;
                  }
                }
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enableInPruNews', value: false } });
              }}
            />
          </RadioGroup>
        </div>
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>
            {Translation('configAgentSegment.common.incentives')}
            <span className={classes.mandatory}>*</span> :
          </span>
        </div>
        <div style={{ width: '75%' }}>
          <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              value="Enable"
              control={<Radio disabled={false} />}
              label={Translation('configAgentSegment.common.enable')}
              checked={formState.enableInIncentives === true}
              onChange={() => {
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enableInIncentives', value: true } });
              }}
            />
            <FormControlLabel
              value="Disable"
              control={<Radio disabled={false} />}
              checked={formState.enableInIncentives === false}
              label={Translation('configAgentSegment.common.disable')}
              onChange={async () => {
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enableInIncentives', value: false } });
              }}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.footerContainer}>
        <Button style={{ marginLeft: 25 }} variant="contained" color="secondary" onClick={onSubmitForm}>
          {Translation('app.button.submit')}
        </Button>
      </div>

      <SubmitAlertComponent
        open={!!dialogMsg?.title}
        title={dialogMsg?.title}
        content={dialogMsg?.content}
        onCancel={dialogMsg?.onCancel}
        onOk={dialogMsg?.onOk}
      />
      <SubmitAlertComponent open={isOpen} onCancel={handleClose} onOk={handleOk} />
    </div>
  );
};

export default AgentSegmentForm;
