import { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { CONFIG_BASE_PATH, AGENT_SUB_SEGMENT_PATH } from '../../constants';

import { SubSegmentListPage } from './list/sub-segment-list.screen';
import { SubSegmentDetailPage } from './sub-segment-detail/sub-segment-detail.screen';

export const agentSubSegmentPath = `${CONFIG_BASE_PATH}${AGENT_SUB_SEGMENT_PATH}`;
SubSegmentDetailPage;
export const AgentSubSegmentRoutes: FC = () => {
  return (
    <Switch>
      <Redirect exact from={agentSubSegmentPath} to={`${agentSubSegmentPath}/list`} />
      <PruRoute exact path={`${agentSubSegmentPath}/list`} component={SubSegmentListPage} />
      <PruRoute exact path={`${agentSubSegmentPath}/edit/:id/:readonly`} component={SubSegmentDetailPage} />
      <PruRoute exact path={`${agentSubSegmentPath}/create`} component={SubSegmentDetailPage} />
    </Switch>
  );
};
