import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { LayoutSplashScreen } from 'src/app/layout';
import commonStyles from 'src/app/common/styles/page.module.css';
import { useStyles } from './sub-segment-detail.style';
import { useSubSegmentDetail } from './sub-segment-detail.hook';
import { ConfigAgentFormMode } from '../../../types/config-type';
import { SubSegmentFormComponent } from './sub-segment-form/sub-segment-form.component';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const SubSegmentDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { id, readonly } = useParams<{ id: string; readonly: string }>();
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const { formMode, pageName } = useMemo(() => {
    let formMode = ConfigAgentFormMode.CREATE;
    let pageName = `${t('roleControl.permission.create')} ${t('subsegment')}`;
    if (id) {
      formMode = ConfigAgentFormMode.EDIT;
      pageName =
        readonly === '1'
          ? `${t('subsegment')} ${t('global.view.text')}`
          : `${t('global.edit.text')} ${t('subsegment')}`;
    }
    return {
      formMode,
      pageName,
    };
  }, [id, readonly]);

  // custom-hook
  const { loading, data, run, handlePress } = useSubSegmentDetail({ id });

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.headerContainer}>
        <div className={commonStyles.rowContainer}>
          <div className={commonStyles.header}>{pageName}</div>
        </div>
        <Button variant="contained" color="inherit" onClick={props.history.goBack}>
          {t('app.button.back')}
        </Button>
      </div>
      {formMode === ConfigAgentFormMode.EDIT && loading && <LayoutSplashScreen />}

      {formMode === ConfigAgentFormMode.CREATE && <SubSegmentFormComponent formMode={formMode} />}

      {
        // render only when data is loaded
        formMode === ConfigAgentFormMode.EDIT && data && (
          <SubSegmentFormComponent formMode={formMode} id={id} data={data as any} readonly={readonly === '1'} />
        )
      }
    </div>
  );
};
