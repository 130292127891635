import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';

import { getConfigurations } from 'src/app/common/utils';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';

export const useDeepLinkList = () => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // redux
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  // get config
  const deeplinkList = useMemo(() => {
    const config = getConfigurations();
    const deeplinkList: { name: string; value: string }[] | null = get(config, 'Sales.news.deeplinkList') || null;
    return deeplinkList;
  }, []);

  // useEffect(() => {
  //   // manual call api request
  //   // run();
  // }, [sortKey]);

  const handleCopy = useCallback((url: string) => {
    // https and localhost can use copy
    if (window.isSecureContext) {
      try {
        navigator.clipboard.writeText(url).then(() => {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Copy',
                content: Translation('microsite_copy_success'),
              },
            ]),
          );
        });
      } catch (error) {
        alert(Translation('common.serviceError'));
      }
    } else {
      alert('security not allow to copy, please manual copy from the list');
    }
  }, []);

  return {
    deeplinkList,
    open,
    handleCopy,
    handleClickOpen,
    handleClose,
  };
};
