import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { getConfigurations } from 'src/app/common/utils';
import { fetchCompanyPickList } from 'src/app/modules/sales-kit/pages/company-pick/network/api';
import { createNewsCategory, modifyNewsCategory } from 'src/app/modules/News/network/newsCrud';

import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { useHistory } from 'react-router-dom';
import { NEWS_BASE_PATH, NEWS_CATEGORY_PATH } from 'src/app/modules/News/constants';
import { NewsCategoryEditItem } from 'src/app/modules/News/types/news-type';
import { NewsCategoryFormState } from '../NewsCategoryDetailForm';

interface HookProps {
  isEditMode: boolean;
  id: string;
  onRouteTo: (route: string) => void;
}

export const useCategoryForm = (props: HookProps) => {
  const { isEditMode, id, onRouteTo } = props;
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const history = useHistory();
  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);
  const refSubmitData = useRef<NewsCategoryEditItem>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmMsg, setConfirmMsg] = useState<{ title: string; content: string } | undefined>();

  const onCancel = () => {
    setConfirmMsg(undefined);
  };

  // confirm edit
  const onOk = () => {
    setConfirmMsg(undefined);
    submitEdit();
  };
  const onSubmitForm = (formState: NewsCategoryFormState, initActive: boolean | undefined) => {
    const details: NewsCategoryEditItem = {
      ...formState,
      color: formState.color,
      emails: formState.emails,
      maxNoOfHeadlines: parseInt(formState.maxNoOfHeadlines, 10),
      maxArchiveDays: parseInt(formState.maxArchiveDays, 10),
    };
    // temp save submit data
    refSubmitData.current = details;
    // return;
    if (!isEditMode) {
      submitCreate(details);
      return;
    }
    if (initActive !== formState.active) {
      if (formState.active) {
        setConfirmMsg({ title: t('news.activate.category.title'), content: t('news.activate.category.content') });
      } else {
        setConfirmMsg({ title: t('news.deactivated.category.title'), content: t('news.deactivated.category.content') });
      }
    } else {
      submitEdit();
    }
  };

  const submitEdit = () => {
    modifyNewsCategory(id, refSubmitData.current as NewsCategoryEditItem)
      .then(() => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: t('save_done'),
            },
          ]),
        );
        history.replace(`${NEWS_BASE_PATH}${NEWS_CATEGORY_PATH}/list`);
      })
      .catch((err) => {
        const msg = err.message || t('common.serviceError');
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'Error',
              content: msg,
            },
          ]),
        );
      });
  };

  // useEffect(() => {}, []);

  const submitCreate = useCallback(
    (data: NewsCategoryEditItem) => {
      createNewsCategory(data, dispatch)
        .then(() => {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: t('save_done'),
              },
            ]),
          );
          history.replace(`${NEWS_BASE_PATH}${NEWS_CATEGORY_PATH}/list`);
        })
        .catch((err) => {
          const msg = err.message || t('common.serviceError');
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: 'Error',
                content: msg,
              },
            ]),
          );
        });
    },
    [dispatch],
  );

  return {
    confirmMsg,
    onOk,
    onCancel,
    onSubmitForm,
  };
};
