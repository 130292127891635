import { Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { Template } from '../../../types/registration-template-types';
import { ConfirmModalComponent } from './components/modal/confirm-modal.component';
import TransferList from './components/transfer-list/transfer-list.component';
import { useSettingColumns } from './setting-columns.hook';
import { useStyles } from './setting-columns.style';
import { InputModalComponent } from './components/modal/input-modal.component';
import { Translation, TranslationWithParams } from 'src/app/i18n';

export interface SettingColumnsProps {
  open: boolean;
  onClose: () => void;
  onTemplateChange?: (defaultColumns: string[], template: Template | null) => void; // 新增的回调函数
}
const dialogStyle = {
  margin: 10,
  display: 'flex',
  justifyContent: 'flex-end',
};

export const SettingColumnsScreen = (props: SettingColumnsProps) => {
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const { open, onClose, onTemplateChange } = props;
  const {
    columnsTemplateMaxLength,
    templateList,
    columns,
    setColumns,
    handleDeleteClick,
    handleSaveTemplate,
    handleConfirm,
    isConfirmDialogOpen,
    isInputDialogOpen,
    setIsConfirmDialogOpen,
    setIsInputDialogOpen,
    selectedDeleteTemplate,
    handleDeleteConfrim,
    handleSaveAndConfirm,
    selectedTemplate,
    setSelectedTemplate,
  } = useSettingColumns(open, onClose, onTemplateChange);
  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle> {Translation('setting_columns')}</DialogTitle>
        <DialogContent>
          <div className={classes.wrap}>
            <div className={classes.verticalLine}></div>
            <span className={classes.text}>{Translation('my_columns_set')}</span>
          </div>
          <div className={classes.additionalBox}>
            <div className={classes.container}>
              <RadioGroup
                value={selectedTemplate ? selectedTemplate.templateId : 'none'}
                onChange={(e) => {
                  const selectedId = e.target.value;
                  if (selectedId === 'none') {
                    setSelectedTemplate(null);
                  } else {
                    const template = templateList?.templates.find((t) => t.templateId === selectedId);
                    setSelectedTemplate(template || null);
                  }
                }}
              >
                <ul className={classes.list}>
                  {templateList?.templates?.map((option) => (
                    <li key={option.templateId} className={classes.listItem}>
                      <FormControlLabel
                        control={<Radio className={classes.radio} />}
                        label={option?.templateName}
                        value={option.templateId}
                      />
                      <Delete className={styles.deleteIcon} onClick={() => handleDeleteClick(option)} />
                    </li>
                  ))}

                  <FormControlLabel
                    control={<Radio className={classes.radio} />}
                    label={
                      (templateList?.templates?.length ?? 0) >= columnsTemplateMaxLength
                        ? TranslationWithParams('none_above_remove_old_ones', { number: columnsTemplateMaxLength })
                        : Translation('none_above')
                    }
                    value="none"
                    disabled={(templateList?.templates?.length ?? 0) >= columnsTemplateMaxLength}
                  />
                </ul>
              </RadioGroup>
            </div>
          </div>
          <div className={classes.wrap}>
            <div className={classes.verticalLine}></div>
            <span className={classes.text}>{Translation('columns')}</span>
          </div>

          <TransferList
            disabled={!!selectedTemplate?.templateId}
            initialLeft={templateList?.allColumns.filter(
              (column) => !selectedTemplate?.fields?.some((field) => field.name === column),
            )}
            initialRight={selectedTemplate?.fields?.map((field) => field.name) || []}
            onListChange={(left, right) => {
              setColumns(right);
            }}
          />
        </DialogContent>

        <DialogActions style={dialogStyle}>
          <Button onClick={onClose} variant="outlined" color="inherit">
            {Translation('global.text.cancel')}
          </Button>
          <Button
            onClick={handleSaveTemplate}
            variant="contained"
            color="secondary"
            disabled={columns.length === 0 || selectedTemplate !== null}
          >
            {Translation('save_template')}
          </Button>
          <Button
            onClick={() => {
              handleConfirm();
              onClose();
            }}
            variant="contained"
            color="secondary"
            disabled={columns.length === 0}
          >
            {Translation('global.text.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmModalComponent
        title={Translation('reminder_title')}
        description={Translation('are_you_sure_to_delete_this_columns_set')}
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={() => handleDeleteConfrim(selectedDeleteTemplate as Template)}
      />

      <InputModalComponent
        title={Translation('columns_set_name')}
        inputLabel={Translation('input_the_name')}
        open={isInputDialogOpen}
        onClose={() => setIsInputDialogOpen(false)}
        onConfirm={(value: string) => handleSaveAndConfirm(value)}
      />
    </div>
  );
};
