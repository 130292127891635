import { NewsArticleItem, NewsCategoryPaginateList } from 'src/app/modules/News/types/news-type';

import { useRequest } from 'ahooks';
import { allCategoryParam, fetchNewsCategoryList } from 'src/app/modules/News/network/newsCrud';
import { useServerLocale } from 'src/app/i18n';
import { fetchAgentSegmentList } from 'src/app/common/network';
import { useDispatch } from 'react-redux';
import { useCallback, useRef, useState } from 'react';
import { fetchAgentSubSegmentList, SegmentModuleEnum } from 'src/app/modules/Config/network/agent-sub-segment.network';
import { groupedBySegmentId, GroupedSegment, SelectOption } from './NewsDetail.util';

export type HookProps = {
  articleDetail?: NewsArticleItem;
};
export const useNewsDetailHook = (props: HookProps) => {
  const locale = useServerLocale();
  // redux
  const dispatch = useDispatch();
  const refSelectedSegment = useRef<SelectOption[]>([]);
  const [segmentOption, setSegmentOption] = useState<SelectOption[]>();
  const [subsegmentOption, setSubsegmentOption] = useState<GroupedSegment>();

  const [categoryList, setCategoryList] = useState<NewsCategoryPaginateList>();

  useRequest(() => fetchNewsCategoryList(allCategoryParam), {
    manual: false,
    onSuccess: (data) => {
      setCategoryList(data);
    },
  });

  useRequest(() => fetchAgentSegmentList({ enableInPruNews: true, pagination: false }, dispatch), {
    manual: false,
    onSuccess: (data) => {
      let value: string[] = [];
      let option: { name: string; value: string }[] = [];
      let optionSelected: { name: string; value: string }[] = [];
      (data.docs || []).forEach((item) => {
        const id = item._id;
        value.push(id);
        option.push({
          name: item.name[locale],
          value: id,
        });
        if (props.articleDetail?.segments?.includes(id)) {
          optionSelected.push({
            name: item.name[locale],
            value: id,
          });
        }
      });
      setSegmentOption(option);
      refSelectedSegment.current = optionSelected;
    },
  });

  useRequest(() => fetchAgentSubSegmentList({ pagination: false, modules: [SegmentModuleEnum.prunews] }, dispatch), {
    manual: false,
    onSuccess: (data) => {
      const mapNameDocs = (data.docs || []).map((item) => {
        item.displayName = item.name[locale];
        return item;
      });
      const res = groupedBySegmentId(mapNameDocs);
      setSubsegmentOption(res);
    },
  });

  const handleChange = (option: { name: string; value: any }[] | any) => {
    refSelectedSegment.current = option;
  };
  return { categoryList, segmentOption, subsegmentOption, segmentSelected: refSelectedSegment.current, handleChange };
};
