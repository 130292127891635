import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Translation } from 'src/app/i18n';

import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { getConfigurations, useDataProvider } from 'src/app/common/utils';
import {
  addColumnsTemplate,
  deleteColumnsTemplateById,
  fetchRegistrationTemplates,
} from '../../../network/setting-columns-crud';
import { AddTemplateDto, Template, TemplateField, TemplateListData } from '../../../types/registration-template-types';

export const useSettingColumns = (
  open: boolean,
  onClose: () => void,
  onTemplateChange?: (defaultColumns: string[], template: Template | null) => void,
) => {
  // redux
  const dispatch = useDispatch();
  const [templateList, setTemplateList] = useState<TemplateListData>();
  const [columns, setColumns] = useState<string[]>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isInputDialogOpen, setIsInputDialogOpen] = useState(false);
  const [selectedDeleteTemplate, setSelectedDeleteTemplate] = useState<Template>(); //select whcih delete item
  const [confirmTemplate, setConfirmTemplate] = useState<Template | null>(() => {
    const storedTemplate = localStorage.getItem('confirmTemplate');
    return storedTemplate ? JSON.parse(storedTemplate) : null;
  });
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(confirmTemplate || null);
  const columnsTemplateMaxLength = getConfigurations()?.Recruitment?.columnsTemplateMaxLength || 8;

  const { isLoading, refreshData } = useDataProvider<TemplateListData>(
    async () => {
      try {
        return await fetchRegistrationTemplates(dispatch);
      } catch (err) {}
    },
    setTemplateList,
    false,
  );

  useEffect(() => {
    if (onTemplateChange) {
      onTemplateChange(templateList?.defaultColumns as string[], confirmTemplate);
    }
  }, [templateList, confirmTemplate, onTemplateChange]);

  useEffect(() => {
    if (open) {
      setSelectedTemplate(confirmTemplate);
    }
  }, [open, confirmTemplate]);

  const handleDeleteClick = useCallback((template: Template) => {
    setSelectedDeleteTemplate(template);
    setIsConfirmDialogOpen(true);
  }, []);

  const handleDeleteConfrim = useCallback(async (template: Template) => {
    try {
      await deleteColumnsTemplateById(template?.templateId as string, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: Translation('delete_successfully'),
          },
        ]),
      );
      refreshData();
      setSelectedTemplate(null);
      setIsConfirmDialogOpen(false);
    } catch (err) {}
  }, []);

  const handleSaveTemplate = useCallback(() => {
    setIsInputDialogOpen(true);
  }, []);
  const handleSaveAndConfirm = useCallback(
    async (columnName: string) => {
      const existSameName = templateList?.templates.some((template) => template.templateName === columnName);
      if (existSameName) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: Translation('global.submit.fail'),
              content: Translation('duplicate_columns_set_name'),
            },
          ]),
        );
      } else {
        const fields: TemplateField[] = columns.map((name, index) => ({
          name,
          order: index,
        }));
        const templateName = columnName;
        const addTemplateData: AddTemplateDto = {
          templateName,
          fields,
        };
        try {
          const template = await addColumnsTemplate(addTemplateData, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('global.submit.success'),
                content: Translation('update_successfully'),
              },
            ]),
          );
          setConfirmTemplate(template);
          localStorage.setItem('confirmTemplate', JSON.stringify(selectedTemplate));
          refreshData();
        } catch (err) {}
        setIsInputDialogOpen(false);
        onClose();
      }
    },
    [templateList, columns],
  );

  const handleConfirm = useCallback(async () => {
    if (!!selectedTemplate?.templateId) {
      setConfirmTemplate(selectedTemplate);
      localStorage.setItem('confirmTemplate', JSON.stringify(selectedTemplate));
    } else {
      // if not select the exit template
      const fields: TemplateField[] = columns.map((name, index) => ({
        name,
        order: index,
      }));
      const template: Template = {
        fields,
      };
      setConfirmTemplate(template);
      localStorage.setItem('confirmTemplate', JSON.stringify(template));
    }
  }, [selectedTemplate, columns]);

  return {
    columnsTemplateMaxLength,
    templateList,
    columns,
    isLoading,
    setColumns,
    handleDeleteClick,
    handleSaveTemplate,
    handleConfirm,
    isConfirmDialogOpen,
    setIsConfirmDialogOpen,
    selectedDeleteTemplate,
    handleDeleteConfrim,
    isInputDialogOpen,
    setIsInputDialogOpen,
    handleSaveAndConfirm,
    selectedTemplate,
    setSelectedTemplate,
    confirmTemplate,
  };
};
