import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { PruTableSortTypeEnum } from 'src/app/common/components/PruTable/PruTable';

import { AgentSegmentItem } from 'src/app/common/types/agent-segment-types';
import { AgentSubSegmentListParam, fetchAgentSubSegmentList } from '../../../network/agent-sub-segment.network';
import { useRequest } from 'ahooks';
import { fetchAgentSegmentList } from 'src/app/common/network';
import { useServerLocale } from 'src/app/i18n';
const initialState: AgentSubSegmentListParam = {
  page: 1,
  limit: 20,
  populateSegment: true,
};

export const agentSubSegmentFilterKeys = [];

const paramsInitiator = (initialParams?: Record<string, string>): AgentSubSegmentListParam => {
  return initialState;
  // return initialParams
  //   ? {
  //       page: 1,
  //       limit: 5,
  //     }
  //   : initialState;
};

export const useAgentSubSegmentListingPage = ({ initialParams, onChangeQueryParams }: ParamsProps) => {
  const dispatch = useDispatch();
  const locale = useServerLocale();

  const [filterState, setFilterState] = useState<AgentSubSegmentListParam>(paramsInitiator(initialParams));
  const [agentSubSegmentList, setAgentSubSegmentList] = useState<PaginateList<AgentSegmentItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<AgentSegmentItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchAgentSubSegmentList(filterState, dispatch);
      } catch (err) {}
    },
    setAgentSubSegmentList,
    false,
  );
  const [segmentValue, setSegmentValue] = useState<string[]>([]);
  const [segmentOption, setSegmentOption] = useState<{ displayName: string; value: string }[]>([]);
  const [subsegmentValue, setSubsegmentValue] = useState<string[]>([]);
  const [subsegmentOption, setSubsegmentOption] = useState<{ displayName: string; value: string }[]>([]);
  // const [parentData, setParentData] = useState<AgentSegmentItem>();

  const { loading: loadingSegment } = useRequest(() => fetchAgentSegmentList({ pagination: false }), {
    manual: false,
    onSuccess: (data) => {
      let value: string[] = [];
      let option: { displayName: string; value: string }[] = [];
      (data.docs || []).forEach((item) => {
        value.push(item._id);
        option.push({
          displayName: item.name[locale],
          value: item._id,
        });
      });

      // setSegmentValue(value); // dropdown init with []
      setSegmentOption(option);
    },
  });

  const { loading: loadingSubsegment } = useRequest(() => fetchAgentSubSegmentList({ pagination: false }), {
    manual: false,
    onSuccess: (data) => {
      let value: string[] = [];
      let option: { displayName: string; value: string }[] = [];
      (data.docs || []).forEach((item) => {
        value.push(item._id);
        option.push({
          displayName: item.name[locale],
          value: item._id,
        });
      });

      // setSubsegmentValue(value);// dropdown init with []
      setSubsegmentOption(option);
    },
  });

  const onSort = (newSortState: { key: string; value?: string }) => {
    const { key, value } = newSortState;
    if (value) {
      let newSort = '';
      switch (value) {
        case PruTableSortTypeEnum.ASC:
          newSort = key;
          break;
        case PruTableSortTypeEnum.DESC:
          newSort = `-${key}`;
          break;
      }
      setFilterState({ ...filterState });
    } else {
      setFilterState({ ...filterState });
    }
    refreshData();
  };

  return {
    filterState,
    agentSubSegmentList,
    isLoading,
    segmentValue,
    subsegmentValue,
    segmentOption,
    subsegmentOption,
    loadingSegment,
    loadingSubsegment,
    setFilterState,
    refreshData,
    onSort,
  };
};
