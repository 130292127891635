import { Dispatch } from 'react';
import apiClient from '../axios';
import { ADMIN_URL, NOTIFICATION_URL } from '../constants';
import { apiErrorHandler } from '../apiErrorHandler';
import { DesignationItem, ZoneItem } from '../../types';

const ENABLE_DESIGNATION = window.envConfig['ENABLE_DESIGNATION'] === 'true';
const ENABLE_ZONE = window.envConfig['ENABLE_ZONE'] === 'true';
const SESSION_DESIGNATIONS = 'SESSION_DESIGNATIONS';
const SESSION_ZONES = 'SESSION_ZONES';

export const getDesignations = async (dispatch?: Dispatch<any>): Promise<DesignationItem[]> => {
  // Check if data is already in sessionStorage
  const cachedDesignations = sessionStorage.getItem(SESSION_DESIGNATIONS);
  if (cachedDesignations) {
    return JSON.parse(cachedDesignations); // Return cached data if available
  }

  return apiClient
    .get<DesignationItem[]>(`${ADMIN_URL}/agents/designations`)
    .then((response) => {
      sessionStorage.setItem(SESSION_DESIGNATIONS, JSON.stringify(response.data)); // Save to sessionStorage
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getZones = async (dispatch?: Dispatch<any>): Promise<ZoneItem[]> => {
  // Check if data is already in sessionStorage
  const cachedZones = sessionStorage.getItem(SESSION_ZONES);
  if (cachedZones) {
    return JSON.parse(cachedZones); // Return cached data if available
  }

  return apiClient
    .get<ZoneItem[]>(`${ADMIN_URL}/agents/zones`)
    .then((response) => {
      sessionStorage.setItem(SESSION_ZONES, JSON.stringify(response.data)); // Save to sessionStorage
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export enum TargetApplicantGroup {
  RM_AND_UP = 'RM & Up',
  ALL_AGENT_EXCLUDE_LA = 'All Designations exclude LA',
  ALL_AGENT_INCLUDE_LA = 'All Designations include LA',
}

const getDesignationValueMap = (designationsData: any) => {
  const DesignationValueMap: any = {};

  designationsData &&
    designationsData.forEach((item: any) => {
      DesignationValueMap[item.shortName] = {
        code: item.code,
        description: item.description,
        departmentCode: item.departmentCode,
        shortName: item.shortName,
      };
    });

  return DesignationValueMap;
};

const formatDesignations = (DesignationValueMap: any) => {
  const RM_AND_UP_Items = ['SRD', 'RD', 'SRM', 'RM'];

  const AllDesignationExcludeLA: any[] = [];
  const AllDesignationIncludeLA: any[] = [];
  const RMupDesignations: any[] = [];

  DesignationValueMap &&
    Object.keys(DesignationValueMap).forEach((designation) => {
      if (RM_AND_UP_Items.includes(designation)) {
        RMupDesignations.push(DesignationValueMap[designation]);
      }

      if (designation !== 'LA' && !RM_AND_UP_Items.includes(designation)) {
        AllDesignationExcludeLA.push(DesignationValueMap[designation]);
      }

      if (designation === 'LA') {
        AllDesignationIncludeLA.push(DesignationValueMap[designation]);
      }
    });

  const designation = {
    [TargetApplicantGroup.RM_AND_UP]: RMupDesignations,
    [TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA]: AllDesignationExcludeLA,
    [TargetApplicantGroup.ALL_AGENT_INCLUDE_LA]: AllDesignationIncludeLA,
  };

  return designation;
};

const formatZones = (zonesData: any) => {
  const zones: string[] = [];
  zonesData &&
    zonesData.forEach((item: any) => {
      zones.push(item.code);
    });

  zones.sort();

  return zones;
};

export const fetchDesignationAndZone = async (dispatch?: Dispatch<any>): Promise<any> => {
  try {
    const designationsRes = ENABLE_DESIGNATION ? await getDesignations() : [];
    const zonesRes = ENABLE_ZONE ? await getZones() : [];

    const designationsData = designationsRes;
    const zonesData = zonesRes;

    const DesignationValueMap: any = getDesignationValueMap(designationsData);

    const designations = formatDesignations(DesignationValueMap);
    const zones = formatZones(zonesData);

    const dataMap = {
      designation: designations,
      designationValue: DesignationValueMap,
      zone: zones,
    };
    return dataMap;
  } catch (error: any) {
    throw apiErrorHandler(error, dispatch);
  }
};

export const fetchI18Config = async (dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get(`${NOTIFICATION_URL}/configs/langs`)
    .then((result: any) => {
      return result.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadAgentListTemplate = async (dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/agents/agent-list/template`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
