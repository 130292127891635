export enum EnrolmentType {
  BY_SEGMENT = 'SEGMENTS',
  SPECIFIC = 'AGENTS',
}

export type AgentSegmentItem = {
  _id: string;
  name: Record<string, string>;
  icon: string;
  iconBgColor: string;
  enrolmentType: EnrolmentType;
  eligibleSegments: {
    designation: string[];
  };
  eligibleAgents: string[];
  excludedAgents: string[];
  agentListFileName?: string;
  region: string;
  channel: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt: Date;
  updatedAt: Date;
  enableInPruNews: boolean;
  enableInIncentives: boolean;
  // sub-segment
  displayName?: string;
  segmentId?: string;
  modules?: string[];
  isActive?: boolean; // if modules include 'prunews'
  segment?: Omit<AgentSegmentItem, 'subSegmentList' | 'parentSegmentDetail' | 'segment'>;

  // below fields for segment response
  parentSegmentDetail?: Omit<AgentSegmentItem, 'parentSegmentDetail' | 'subSegmentList'>;
  subSegmentList?: Omit<AgentSegmentItem, 'subSegmentList' | 'parentSegmentDetail'>[];
};
