import React, { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { AUDIT_LOG_BASE_PATH, USER_MANAGEMENT_LOG_PATH, NEWSS_ARTICLE_LOG_PATH } from './constants';
import { userManagementLogFilterKeys } from './pages/user-management-log/list/user-management-log-listing-page.hook';
import { UserManagementLogListingPage } from './pages/user-management-log/list/user-management-log-listing-page.screen';
import { UserManagementLogDetailPage } from './pages/user-management-log-detail/user-management-log-detail.screen';
import { NewsArticleLogPage } from './pages/news-article-log/news-article-log.screen';
import { NewsArticleLogDetailPage } from './pages/news-article-log-detail/news-article-log-detail.screen';

export const userManagementLogPath = `${AUDIT_LOG_BASE_PATH}${USER_MANAGEMENT_LOG_PATH}`;
export const newsLogPath = `${AUDIT_LOG_BASE_PATH}${NEWSS_ARTICLE_LOG_PATH}`;
export const newsLogDetailPath = `${AUDIT_LOG_BASE_PATH}${NEWSS_ARTICLE_LOG_PATH}/detail`;

export const AuditLogRoutes: FC = () => {
  return (
    <Switch>
      <Redirect exact from={userManagementLogPath} to={`${userManagementLogPath}/list`} />
      <PruRoute exact path={newsLogPath} component={NewsArticleLogPage} />
      <PruRoute exact path={newsLogDetailPath} component={NewsArticleLogDetailPage} />
      <PruRoute
        exact
        path={`${userManagementLogPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={userManagementLogFilterKeys}>
            <UserManagementLogListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${userManagementLogPath}/detail`}
        render={(props) => <UserManagementLogDetailPage {...props} />}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};
