import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { TranslationWithParams, useLang } from 'src/app/i18n';
import * as XLSX from 'xlsx';

import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { ComponentProps, JsonObject } from './data-uploader.component';
import { downloadExcel } from './data-uploader.util';

export const defaultFileType = 'xlsx';
export const defaultFileName = 'uploaded_file';

export const useDataUploader = (props: ComponentProps) => {
  // default limit: 10 MB, 30000 rows data.
  const { onDownload, templateData, maxFileSize = 10, maxRowCount = 30000 } = props;

  const limit = TranslationWithParams('upload.limit', { maxFileSize, maxRowCount });
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  // redux
  const dispatch = useDispatch();
  const [isShowDownload, setIsShowDownload] = useState<boolean>(onDownload ? true : false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [value, setValue] = useState<JsonObject[]>();

  // useEffect(() => {
  //   // manual call api request
  // }, []);

  const onUploadFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = (e.target.files || [])[0];
    if (!file) {
      return;
    }
    if (!checkFile(file, maxFileSize, [defaultFileType])) {
      // reset input value for next change event triggered
      e.target.value = '';
      return;
    }
    setIsUploading(true);
    try {
      // Processing Excel
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target?.result;
        if (arrayBuffer) {
          const workbook = XLSX.read(arrayBuffer, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json<any>(worksheet);
          if (maxRowCount && jsonData.length > maxRowCount) {
            displayAlert(limit);
            setIsUploading(false);
            return;
          }
          const cleanedData = jsonData.map((item) => {
            const { __rowNum__: _, ...rest } = item; // Destructure to omit "__rowNum__"
            return rest; // Return the rest of the properties
          });
          if (props.validateData) {
            const isValid = props.validateData(cleanedData);
            if (!isValid) {
              alertInvalidDownload();
              setIsUploading(false);
              return;
            }
          }
          // setData(jsonData);
          setValue(cleanedData);
          setIsShowDownload(true); //display download link after upload done
          props.onChange?.(cleanedData);
        }
        setIsUploading(false);
      };
      reader.readAsArrayBuffer(file); // Use readAsArrayBuffer instead
    } catch (error) {
      setIsUploading(false);
    }
  }, []);

  const deleteFile = useCallback(() => {
    setIsShowDownload(false);
    props.onChange?.([]);
  }, []);

  const alertInvalidDownload = useCallback(() => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('global.submit.fail'),
          content: Translation('agencyCampaign.lead.noRecord'),
        },
      ]),
    );
  }, []);

  const displayAlert = useCallback((content?: string) => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.WARNING,
          title: Translation('global.submit.fail'),
          content: content || Translation('agencyCampaign.lead.noRecord'),
        },
      ]),
    );
  }, []);

  const handleDownloadTemplate = useCallback(() => {
    if (templateData && templateData.length) {
      downloadExcel(templateData, 'upload_template');
    } else {
      alertInvalidDownload();
    }
  }, [templateData]);
  const handleDownload = useCallback(async () => {
    if (value) {
      if (!value.length) {
        alertInvalidDownload();
      } else {
        downloadExcel(value);
      }
    } else {
      const res = await onDownload?.();
      if (!res || !res.length) {
        alertInvalidDownload();
      } else {
        downloadExcel(res);
      }
    }
  }, [value, onDownload]);

  const checkFile = (file: any, maxSize?: number, fileTypes?: string[]) => {
    if (!file) return false;
    if (fileTypes && !fileTypes.some((item) => file.name.toLowerCase().endsWith(item))) {
      displayAlert(TranslationWithVariable('global.max.file.type.allow', fileTypes.join('/')));

      return false;
    }

    if (maxSize && file.size > maxSize * 1024 * 1024) {
      displayAlert(limit);
      return false;
    }
    return true;
  };

  return {
    isUploading,
    value,
    isShowDownload,
    limit,
    onUploadFile,
    deleteFile,
    handleDownload,
    handleDownloadTemplate,
  };
};
