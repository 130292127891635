import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { PaginateList, AgentSegmentItem } from 'src/app/common/types';
import { ADMIN_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import { AgentSegmentEditItem } from '../types/agent-segment-type';

export type AgentSubSegmentListParam = {
  search?: string;
  segmentIds?: string[];
  subSegmentIds?: string[];
  enableInPruNews?: boolean;
  enableInIncentives?: boolean;
  pagination?: boolean;
  toAllAgents?: boolean;
  page?: number;
  limit?: number;
  createdDateFrom?: Date | null;
  createdDateTo?: Date | null;
  populateSegment?: boolean;
  modules?: SegmentModuleEnum[];
};
export enum SegmentModuleEnum {
  prunews = 'prunews',
  incentive = 'incentive',
}

export const fetchAgentSubSegmentList = (
  params: AgentSubSegmentListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<AgentSegmentItem>> => {
  const url = `${ADMIN_URL}/agent-sub-segment`;
  // const url = `http://localhost:1111/agent-sub-segment`;
  return apiClient
    .get<PaginateList<AgentSegmentItem>>(url, { params: params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchSubSegmentDetail = (id: string, dispatch?: Dispatch<any>): Promise<AgentSegmentItem> => {
  let url = `${ADMIN_URL}/agent-sub-segment/${id}`;
  // const url = `http://localhost:1111/agent-sub-segment/${id}`;
  return apiClient
    .get(url)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const createSubSegment = (data: AgentSegmentEditItem, dispatch?: Dispatch<any>) => {
  const url = `${ADMIN_URL}/agent-sub-segment`;
  // const url = `http://localhost:1111/agent-sub-segment`;
  return apiClient.post(url, data); // no need catch here, page will handle it manually
};

export const updateSubSegment = (id: string, data: AgentSegmentEditItem, dispatch?: Dispatch<any>) => {
  const url = `${ADMIN_URL}/agent-sub-segment/${id}`;
  // const url = `http://localhost:1111/agent-sub-segment/${id}`;
  return apiClient.patch(url, data); // no need catch here, page will handle it manually
};
