export const AUDIT_LOG_BASE_PATH = '/auditlog';
export const USER_MANAGEMENT_LOG_PATH = '/user-management-log';
export const NEWSS_ARTICLE_LOG_PATH = '/news-article-log';

export const AUDIT_LOG_TITLE = 'auditLog.title';
export const USER_MANAGEMENT_LOG_TITLE = 'auditLog.userManagementLog.title';

export const USER_MANAGEMENT_LOG_NAMESPACE = 'USER-MANAGEMENT-LOG';
export const NEWS_LOG_NAMESPACE = 'NEWS-LOG';

export enum AdminAuditLogPermissionType {
  USER_MANAGEMENT_LOG_READ = 'READ_ANY_USER-MANAGEMENT-LOG',
  USER_MANAGEMENT_LOG_CREATE = 'CREATE_ANY_USER-MANAGEMENT-LOG',
  USER_MANAGEMENT_LOG_UPDATE = 'UPDATE_ANY_USER-MANAGEMENT-LOG',
}

// news article
export enum AdminNewsPermissionType {
  NEWS_LOG_READ = 'READ_ANY_NEWS-LOG',
  // NEWS_LOG_CREATE = 'CREATE_ANY_NEWS-LOG',
  // NEWS_LOG_UPDATE = 'UPDATE_ANY_NEWS-LOG',
}
