import { AgentSegmentItem } from 'src/app/common/types/agent-segment-types';
import {
  NewsArticleEditItem,
  NewsArticleItem,
  NewsArticleFormMode,
  NewsCategoryItem,
  NewsCategoryPaginateList,
  NewsImageItem,
  NewsTitleItem,
  NewsAttachmentItem,
  NewsContentItem,
  NewsContentWordcountItem,
  NewsArticleStatus,
  EnrolmentType,
  newsType,
} from '../../../../types/news-type';
import { getConfigurations } from 'src/app/common/utils';
import { get } from 'lodash';
export type NewsDetailProps = {
  code?: string;
  formMode: NewsArticleFormMode;
  newsArticle?: NewsArticleItem;
  lastFileName: Pick<NewsArticleItem, 'agentListFileName'>;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

export type NewsArticleFormState = {
  code?: string;
  contentWordCount: NewsContentWordcountItem;
  content: NewsContentItem;
  title: NewsTitleItem;
  categoryId: string;
  archiveDate: Date | null;
  noArchiveDate: boolean;
  publishDate: Date | null;
  bannerFileId?: string;
  bannerFileName?: string;
  bannerFileUrl?: string;
  banner?: NewsImageItem;
  bannerFileType?: string;
  thumbnailFileId?: string;
  thumbnailFileName?: string;
  thumbnailFileUrl?: string;
  thumbnail?: NewsImageItem;
  tags?: string[];
  isSuggested: boolean;
  enquiryEmail?: string;
  attachments?: NewsAttachmentItem[];
  enrolmentType: EnrolmentType;
  eligibleDesignations?: string[];
  eligibleAgents?: string[];
  excludedAgents?: string[];
  agentListFileName?: string;
  agentListUploaded?: string[]; // uploaded agent list
  newsType: newsType;
  segments: string[];
  subSegments?: string[];
  segmentNames: Record<string, string>[];
  isHeadline: boolean;
};

export const initialState: NewsArticleFormState = {
  code: undefined,
  title: {},
  content: {},
  contentWordCount: {},
  categoryId: '',
  archiveDate: null,
  noArchiveDate: false,
  publishDate: null,
  banner: { blobId: '' },
  bannerFileId: '',
  bannerFileName: '',
  bannerFileUrl: '',
  bannerFileType: 'Image',
  thumbnail: { blobId: '' },
  thumbnailFileId: '',
  thumbnailFileName: '',
  thumbnailFileUrl: '',
  tags: [],
  isSuggested: false,
  enquiryEmail: undefined,
  attachments: [],
  eligibleDesignations: undefined,
  eligibleAgents: undefined,
  excludedAgents: undefined,
  enrolmentType: EnrolmentType.BY_SEGMENT,
  agentListFileName: undefined,
  newsType: newsType.GENERAL,
  segments: [],
  segmentNames: [],
  isHeadline: false,
};

export const detailToStateConvertor = (detail: NewsArticleItem): NewsArticleFormState => {
  const eligibleDesignations =
    detail.eligibleSegments && detail.eligibleSegments.designation ? detail.eligibleSegments.designation : [];
  return {
    content: detail.content || {},
    title: detail.title || {},
    contentWordCount: detail.wordCount || {},
    categoryId: detail.categoryId,
    archiveDate: detail.archiveDate || null,
    noArchiveDate: !!detail.noArchiveDate,
    publishDate: detail.publishDate || null,
    banner: detail.banner,
    thumbnail: detail.thumbnail,
    tags: detail.tags,
    isSuggested: detail.isSuggested ? detail.isSuggested : false,
    enquiryEmail: detail.enquiryEmail || undefined,
    attachments: detail.attachments || [],
    eligibleDesignations,
    // eligibleAgents: detail.eligibleAgents,
    eligibleAgents: detail.enrolmentType === EnrolmentType.BY_SEGMENT ? detail.eligibleAgents : [],
    excludedAgents: detail.excludedAgents,
    enrolmentType: detail.enrolmentType,
    agentListFileName: detail.agentListFileName,
    agentListUploaded: detail.enrolmentType === EnrolmentType.SPECIFIC ? detail.eligibleAgents : undefined,
    newsType: detail.newsType,
    segments: detail.segments || [],
    subSegments: detail.subSegments || [],
    segmentNames: detail.segmentNames || [],
    isHeadline: detail.isHeadline || false,
  };
};

export type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof NewsArticleFormState;
    value: any;
  };
};

export type ModifyLocaleFieldAction = {
  type: 'MODIFY_LOCALE_FIELD';
  payload: {
    field: keyof Pick<NewsArticleFormState, 'title' | 'content' | 'contentWordCount'>;
    locale: string;
    value: any;
  };
};

export type UploadBannerImageAction = {
  type: 'UPLOAD_BANNER_IMAGE';
  payload: {
    id: string;
    fileUrl: string;
    fileType: string;
  };
};

export type UploadThumbnailImageAction = {
  type: 'UPLOAD_THUMBNAIL_IMAGE';
  payload: {
    id: string;
    fileUrl: string;
  };
};

export type AddAttachmentRowAction = {
  type: 'ADD_ATTACHMENT_ROW';
};

export type ModifyAttachmentRowAction = {
  type: 'MODIFY_ATTACHMENT_ROW';
  payload: {
    index: number;
    field: keyof NewsAttachmentItem;
    value: string;
  };
};

export type DeleteAttachmentRowAction = {
  type: 'DELETE_ATTACHMENT_ROW';
  payload: {
    index: number;
  };
};

export type ToggleDesignationAction = {
  type: 'TOGGLE_DESIGNATION';
  payload: {
    value: string;
  };
};

export type SegmentCheckAction = {
  type: 'SEGMENT_CHECK';
  payload: {
    value: string[];
  };
};
export type SubSegmentCheckAction = {
  type: 'SUB_SEGMENT_CHECK';
  payload: {
    value: string[];
  };
};

export type NewsArticleFormAction =
  | ModifyFieldAction
  | ModifyLocaleFieldAction
  | UploadBannerImageAction
  | UploadThumbnailImageAction
  | AddAttachmentRowAction
  | ModifyAttachmentRowAction
  | DeleteAttachmentRowAction
  | ToggleDesignationAction
  | SegmentCheckAction
  | SubSegmentCheckAction;

export const newsArticleFormReducer = (
  state: NewsArticleFormState,
  action: NewsArticleFormAction,
): NewsArticleFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_LOCALE_FIELD':
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          [action.payload.locale]: action.payload.value,
        },
      };
    case 'UPLOAD_BANNER_IMAGE':
      return {
        ...state,
        bannerFileUrl: action.payload.fileUrl,
        bannerFileType: action.payload.fileType,
      };

    case 'UPLOAD_THUMBNAIL_IMAGE':
      return {
        ...state,
        thumbnailFileUrl: action.payload.fileUrl,
      };

    case 'ADD_ATTACHMENT_ROW':
      return {
        ...state,
        attachments: state.attachments
          ? [...state.attachments, { filename: '', url: '', blobId: '' }]
          : [{ filename: '', url: '', blobId: '' }],
      };
    case 'MODIFY_ATTACHMENT_ROW':
      if (state.attachments && state.attachments[action.payload.index]) {
        let item = state.attachments[action.payload.index];
        item[action.payload.field] = action.payload.value;
      }
      return { ...state };
    case 'DELETE_ATTACHMENT_ROW':
      if (state.attachments && state.attachments[action.payload.index]) {
        let attachmentsArr = state.attachments;
        attachmentsArr.splice(action.payload.index, 1);
      }
      return { ...state };

    case 'TOGGLE_DESIGNATION':
      let newDesignation = [...(state.eligibleDesignations || [])];
      let itemIndex = newDesignation.findIndex((designation) => designation === action.payload.value);
      newDesignation[itemIndex] ? newDesignation.splice(itemIndex, 1) : newDesignation.push(action.payload.value);
      return {
        ...state,
        eligibleDesignations: newDesignation,
      };
    case 'SEGMENT_CHECK': {
      // let newSegments = [...(state.segments || [])];
      // let itemIndex = newSegments.findIndex((designation) => designation === action.payload.value);
      // newSegments[itemIndex] ? newSegments.splice(itemIndex, 1) : newSegments.push(action.payload.value);
      return {
        ...state,
        segments: action.payload.value,
        // segments: newSegments,
      };
    }
    case 'SUB_SEGMENT_CHECK': {
      return {
        ...state,
        subSegments: action.payload.value,
      };
    }
  }
};

export function isValidDate(date: string | undefined, ignoreNull?: boolean) {
  if (ignoreNull) {
    return date !== 'Invalid Date';
  }
  return !!(date && date !== 'Invalid Date');
}

export const categoryListParam = {
  limit: 5,
  page: 1,
};

export const segmentListParam = {
  limit: 5,
  page: 1,
};

// object to 2D array
/*

// example:
source = {"id1": [{"segmentId": "yyy1","displayName": "en1","_id": "xxx1"}...], "id2": [{"segmentId2": "yyy2","displayName": "en2","_id": "xxx2"}...]}
target = [[{"segmentId": "yyy1","displayName": "en","_id": "xxx1"}...],[{"segmentId2": "yyy2","displayName": "en2","_id": "xxx2"}...]]
*/
export function groupedBySegmentId(data: AgentSegmentItem[]): GroupedSegment {
  const result: GroupedSegmentObject = data.reduce((acc, curr) => {
    const { segmentId = 'undefined', displayName, _id } = curr; // pick required fields

    // Create a new object with only the desired fields
    const pickedFields = { segmentId, name: displayName, value: _id }; // Add any other fields you want to include

    if (!acc[segmentId]) {
      acc[segmentId] = [];
    }
    acc[segmentId].push(pickedFields);
    return acc;
  }, {} as any);
  // Convert to a 2D array
  const formatData = Object.values(result).map((group) => group.map((item) => item));
  return formatData;
}
type GroupedSegmentObject = {
  [key: string]: SegmentItem[];
};
export type SegmentItem = {
  segmentId: string;
  name: string;
  value: string;
};

export type GroupedSegment = SegmentItem[][];
export type SelectOption = { name: string; value: string };

export function getSelectedSegment(ids: string[], option: SelectOption[]): string[] {
  return option
    .filter((opt) => ids.includes(opt.value)) // Filter options based on ids
    .map((opt) => opt.value); // Map to get the values
}
/**
 * 2D array: get selected-subsegment-array for each sub-segment dropdown
 * example return value: [ [],["67b818156ac48de9199e1dce"],[],[],[] ]
 * @param ids
 * @param option
 * @returns
 */
export function getSelectedSubSegment(ids: string[], option: GroupedSegment): string[][] {
  ids = ids || []; // avoid null
  return option.map(
    (group) =>
      group
        .filter((segment) => ids.includes(segment.value)) // Filter based on ids
        .map((segment) => segment.value), // Map to get the values
  );
  // no need to remove empty group, each sub-segment dropdown must have an selected item array
  // .filter((group) => group.length > 0); // Remove empty groups
}
/**
 * get parent segment name.
 * @param segmentId
 * @param option
 * @returns
 */
export function getSegmentNameById(segmentId: string, option: SelectOption[]) {
  const idx = option.findIndex((item) => item.value === segmentId);
  if (idx !== -1) {
    return option[idx].name;
  }
  return undefined;
}

export function getSubSegmentConfig() {
  const config = getConfigurations();
  const enabledSubsegment = get(config, 'Sales.news.enabledSubsegment') || false;
  return { enabledSubsegment };
}
