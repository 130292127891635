import { useCallback, useMemo, useState } from 'react';
import { CreateScheduleFields, examModesType } from 'src/app/modules/Recruitment/types/license-exam-types';
import { useForm } from 'src/app/common/components/Form';
import { createScheduleConfigs } from '../../../util/form-configs.util';
import { useIntl } from 'react-intl';
import { getConfigurations } from 'src/app/common/utils';
import { get } from 'lodash';
import { Rule } from 'rc-field-form/es/interface';

export interface CreateScheduleProps {
  initialValues?: Record<CreateScheduleFields, any>;
  disabled?: Record<CreateScheduleFields, boolean>;
  onSubmit: (value: Record<CreateScheduleFields, any>) => Promise<void>;
  onCancel: () => void;
  showSubmit: boolean;
  autoCompleteOptions?: {
    examCodeOptions: string[] | { label: string; value: string }[];
    examNameOptions: string[] | { label: string; value: string }[];
    localExamNameOptions: string[] | { label: string; value: string }[];
    venueOptions: string[] | { label: string; value: string }[];
    addressOptions: string[] | { label: string; value: string }[];
  };
}

export const useCreateSchedule = ({ onSubmit, initialValues }: CreateScheduleProps) => {
  const [form] = useForm();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const config = getConfigurations();
  const examModes: examModesType[] = get(config, 'Recruitment.examModes', []);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const hasDeviceTypes = useMemo(() => {
    const selectedMode = form.getFieldValue(CreateScheduleFields.examMode) ?? initialValues?.examMode;
    if (!selectedMode) return false;
    const deviceTypes = examModes.find((mode) => mode.code === selectedMode)?.deviceTypes;
    return deviceTypes && deviceTypes.length > 0;
  }, [examModes, form]);

  const deviceTypeConfig = useMemo(
    () => ({
      name: CreateScheduleFields.deviceType,
      label: Translation('recruitment.exam.deviceType'),
      placeholder: Translation('app.input.placeholder.please-enter'),
    }),
    [],
  );

  const deviceTypeOptions = useMemo(() => {
    const selectedMode = form.getFieldValue(CreateScheduleFields.examMode);
    const mode = examModes.find((m) => m.code === selectedMode);
    return mode?.deviceTypes?.map((dt) => ({ label: dt, value: dt })) || [];
  }, [examModes, form]);

  const enableExamFeeInput = get(config, 'Recruitment.enableExamFeeInput', false);
  const currencies = get(config, 'Global.region.currencies', []) as { code: string; symbol: string }[];
  const defaultCurrencyCode = currencies.length > 0 ? currencies[0].code : '';

  const initialCurrencyCode = initialValues?.currencyCode || defaultCurrencyCode;

  const currencyCodeConfig = useMemo(
    () => ({
      name: CreateScheduleFields.currencyCode,
      label: '',
    }),
    [],
  );

  const examFeeConfig = useMemo(
    () => ({
      name: CreateScheduleFields.examFee,
      required: false,
      label: Translation('recruitment.exam.examFee'),
      rules: [
        {
          required: false,
          validator(rule: any, value: any, callback: (error?: string) => void) {
            if (value && !/^\d*\.?\d+$/.test(value)) callback(Translation('Common.non_negative_number'));
            callback();
          },
        },
      ] as Rule[],
      placeholder: Translation('app.input.placeholder.please-enter'),
    }),
    [],
  );

  const onFinish = useCallback(
    async (values: any) => {
      setConfirmLoading(true);
      await onSubmit(values);
      setConfirmLoading(false);
    },
    [onSubmit],
  );

  const examModeList = useMemo(() => {
    return examModes.map((mode: examModesType) => ({
      label: Translation(mode.displayKey),
      value: mode.code,
    }));
  }, [examModes]);

  const [
    examCodeConfig,
    examNameConfig,
    localExamNameConfig,
    examTypeConfig,
    examModeConfig,
    examDateConfig,
    startTimeConfig,
    endTimeConfig,
    venueConfig,
    addressConfig,
    startRegistrationDateConfig,
    lastRegistrationDateConfig,
    quotaConfig,
    remainingQuotaConfig,
    remarkConfig,
  ] = useMemo(() => createScheduleConfigs(), []);

  return {
    form,
    examCodeConfig,
    examNameConfig,
    localExamNameConfig,
    examTypeConfig,
    examModeConfig,
    examDateConfig,
    startTimeConfig,
    endTimeConfig,
    venueConfig,
    addressConfig,
    startRegistrationDateConfig,
    lastRegistrationDateConfig,
    quotaConfig,
    remainingQuotaConfig,
    remarkConfig,
    onFinish,
    confirmLoading,
    examModeList,
    deviceTypeConfig,
    deviceTypeOptions,
    enableExamFeeInput,
    examFeeConfig,
    currencyCodeConfig,
    currencies,
    defaultCurrencyCode: initialCurrencyCode,
    examModes,
    hasDeviceTypes,
  };
};
