import { Palette } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    marginTop: '16px',
  },
  verticalLine: {
    height: '20px',
    width: '4px',
    backgroundColor: 'red',
    marginRight: '10px',
  },
  text: {
    color: 'black',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '100%',
    letterSpacing: '0px',
    textAlign: 'center',
  },
  additionalBox: {
    flex: 1,
    height: '160px',
    borderRadius: '12px',
    border: '1px solid #ccc',
    paddingTop: '8px',
    paddingRight: '20px',
    paddingBottom: '8px',
    paddingLeft: '20px',
    maxHeight: '160px',
    overflowY: 'auto',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '16px',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  button: {
    marginLeft: '10px',
  },
  radio: {
    '&.Mui-checked': {
      color: 'red',
    },
  },
  deleteIcon: {
    verticalAlign: 'middle',
    cursor: 'pointer',
    color: '#4386e5',
  },
}));
