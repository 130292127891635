import { FC } from 'react';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { LogsModuleEnum, useUserManagementLogListingPage } from './user-management-log-listing-page.hook';
import { UserManagementLogList } from './components/user-management-log-list.component';

type UserManagementLogListingPageProps = ParamsProps;

export const UserManagementLogListingPage: FC<UserManagementLogListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { filterState, userManagementLogList, dynamicFilterOptions, isLoading, setFilterState, refreshData, onSort } =
    useUserManagementLogListingPage({
      initialParams: { ...initialParams, module: LogsModuleEnum.USER_MANAGEMENT },
      onChangeQueryParams,
    });

  return (
    <>
      <PruFilter
        title={Translation('auditLog.userManagementLog.list.filter.title')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'search',
            initialValue: filterState.search,
            displayName: Translation('auditLog.userManagementLog.list.filter.search'),
            tooltip: Translation('audit.log.tooltip'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'activity',
            choices: dynamicFilterOptions.activity ?? [],
            displayName: Translation('auditLog.userManagementLog.list.activity'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'activityStatus',
            choices: dynamicFilterOptions.activityStatus ?? [],
            displayName: Translation('auditLog.userManagementLog.list.status'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'service',
            choices: dynamicFilterOptions.service ?? [],
            displayName: Translation('auditLog.userManagementLog.list.filter.service'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'lastUpdatedBy',
            initialValue: filterState.lastUpdatedBy,
            displayName: Translation('auditLog.userManagementLog.list.lastUpdatedBy'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldTo: 'createdAtTo',
            fieldFrom: 'createdAtFrom',
            displayName: Translation('auditLog.userManagementLog.list.createdDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            ...data,
          })
        }
        fetchData={refreshData}
      />
      <UserManagementLogList
        isLoading={isLoading}
        userManagementLogList={userManagementLogList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={onSort}
      />
    </>
  );
};
