import { FC, useState } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';
import { Button, Dialog, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { PaginateList, AgentSegmentItem } from 'src/app/common/types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate, useJWT, isPermitted } from 'src/app/common/utils';
import {
  CONFIG_BASE_PATH,
  CONFIG_AGENT_SEGMENT_PATH,
  AdminAgentSegmentPermissionType,
} from 'src/app/modules/Config/constants';
import { useServerLocale, getLangAbbreviation } from 'src/app/i18n';
import { useDispatch } from 'react-redux';
import { map } from 'lodash';

type AgentSegmentListProps = {
  isLoading: boolean;
  agentSegmentList?: PaginateList<AgentSegmentItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const LargerFontTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    fontSize: 12,
  },
}));

const useStyles = makeStyles()((theme) => ({
  dialogContainer: {
    padding: '20px',
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorContainer: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  errorHeader: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  errorFooter: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const AgentSegmentList: FC<AgentSegmentListProps> = ({ isLoading, agentSegmentList, onRefresh, onChangePage }) => {
  const dispatch = useDispatch();
  const locale = useServerLocale();
  const history = useHistory();
  const intl = useIntl();
  const { classes } = useStyles();
  const Translation = (id: string) => intl.formatMessage({ id });

  let updateHeadlinePermission = false;
  let updateNewsPermission = false;
  let createNewsPermission = false;
  const jwt = useJWT() || {};

  if (jwt.permissions) {
    const jwtPermission = map(jwt.permissions, (_, key) => key);
    updateNewsPermission = isPermitted([AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_UPDATE], jwtPermission);
    createNewsPermission = isPermitted([AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_CREATE], jwtPermission);
    updateHeadlinePermission = isPermitted(
      [
        AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_CREATE,
        AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_UPDATE,
      ],
      jwtPermission,
    );
  }

  const [errorDialog, setErrorDialog] = useState({
    show: false,
    message: '',
  });

  return (
    <>
      <PruTable
        title={Translation('configAgentSegment.list')}
        disableBulkSelect
        defaultRowsPerPage={20}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('configAgentSegment.common.newAgentSegment'),
            onClick: () => {
              history.push(`${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}/create`);
              //console.log(`${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}/create`)
            },
            condition: () => createNewsPermission,
          },
        ]}
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Article Detail',
            onClick: (row) => {
              //console.log(`${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}/edit/${row._id}`)
              history.push(`${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}/edit/${row._id}`);
            },
            condition: () => updateNewsPermission,
          },
        ]}
        columnDef={[
          {
            keyIndex: 'category',
            align: 'center',
            displayName: 'Segment ID',
            renderData: (row) => (row._id ? row._id : ''),
          },
          {
            keyIndex: 'name',
            align: 'center',
            displayName: `Name (${getLangAbbreviation(locale)})`,
            renderData: (row) => (row.name ? row.name[locale] : '-') || '-',
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('configAgentSegment.common.createdAt'),
            renderData: (row) => (row.createdAt ? getDefaultDisplayDate(row.createdAt, 'datetime') : '-'),
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('configAgentSegment.common.updatedAt'),
            renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(row.updatedAt, 'datetime') : '-'),
          },
          {
            keyIndex: 'enableInPruNews',
            align: 'center',
            displayName: Translation('configAgentSegment.common.isPRUNews'),
            renderData: (row) =>
              row.enableInPruNews
                ? Translation('configAgentSegment.common.enable')
                : Translation('configAgentSegment.common.disable'),
          },
          {
            keyIndex: 'enableInIncentives',
            align: 'center',
            displayName: Translation('configAgentSegment.common.incentives'),
            renderData: (row) =>
              row.enableInIncentives
                ? Translation('configAgentSegment.common.enable')
                : Translation('configAgentSegment.common.disable'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={agentSegmentList?.docs}
        totalPages={agentSegmentList?.totalPages}
        totalRecords={agentSegmentList?.totalDocs}
        onChangePage={onChangePage}
      />

      {/** Error Dialog */}
      <Dialog
        onClose={() => {
          setErrorDialog((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        open={errorDialog.show}
      >
        <div className={classes.errorContainer}>
          <div>{errorDialog.message}</div>
          <div className={classes.errorFooter}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setErrorDialog((prev) => ({
                  ...prev,
                  show: false,
                }));
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AgentSegmentList;
