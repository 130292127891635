import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  surveyCreator: {
    '& svd-splitter': {
      minWidth: 4,
      width: 4,
      maxWidth: 4,
      padding: 0,
    },
  },
}));
