import React, { FC, useEffect, useState, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Dialog, Button, CircularProgress, Modal, debounce } from '@mui/material';
import { refreshToken, getRegionChannelList } from '../../modules/Auth/_redux/authCrud';
import { useDispatch, useSelector } from 'react-redux';
import {
  authenticateFailed,
  logout,
  setExpired,
  setRegionChannelList,
  AuthenticationState,
  AuthApiRes,
} from '@pruforce/common-adminweb-sdk';
import moment from 'moment';
import { RootState } from '../../../redux/store';
import { useHistory } from 'react-router-dom';
import ifvisible from 'ifvisible';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const TokenHandler: FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  // const currentPath = useHistory().location.pathname;

  const isExpired = useSelector<RootState, boolean>((state) => !!state.auth.isExpired);
  const { regionChannelList } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const onLogout = () => {
    dispatch(logout());
  };

  const autoLogout = () => {
    dispatch(authenticateFailed());
    dispatch(logout());
  };

  const refreshTokenHandle = (res: AuthApiRes) => {
    window.localStorage.setItem('jwt', res.accessToken);
    window.localStorage.setItem('refreshToken', res.refreshToken);
    const expireDate = moment(new Date()).add(res.expiresIn, 's').toDate();
    // abondon the Session after 1 day, will popup refresh session dialog
    const abondonSession = moment(new Date()).add(1, 'd').toDate();
    window.localStorage.setItem('expireDate', expireDate.toISOString());
    window.localStorage.setItem('abondonSession', abondonSession.toISOString());
  };

  const checkSessionExpiration = () => {
    const token = window.localStorage.getItem('refreshToken');
    const expiredDate = window.localStorage.getItem('expireDate');

    // check if need to open "refresh session" dialog
    const abondonSession = window.localStorage.getItem('abondonSession');
    const abondonFlag = abondonSession && moment(new Date()).isAfter(moment(abondonSession));
    const openDialogFlag =
      !isExpired &&
      expiredDate &&
      moment(new Date()).isAfter(moment(expiredDate)) &&
      moment(new Date()).isBefore(moment(abondonSession));
    if (abondonFlag) {
      dispatch(logout());
      return;
    }
    if (openDialogFlag) {
      dispatch(setExpired(true));
      return;
    }
    if (isExpired) {
      // Refresh Session dialog is opened, need user manual action click [Yes/No]
      return;
    }
    // Refresh 5 minutes before expiration."
    const REFRESH_TIME_DIFF_IN_MIN = 5;
    const willExpireSoon =
      !expiredDate || moment(new Date()).isAfter(moment(expiredDate).subtract(REFRESH_TIME_DIFF_IN_MIN, 'm'));
    if (willExpireSoon) {
      if (token) {
        refreshToken(token, dispatch)
          .then(refreshTokenHandle)
          .catch(() => {});
      } else {
        autoLogout();
      }
    }
  };

  useEffect(() => {
    /**
     * check session once when page mounts, then if page is visible, check session every x seconds.
     */
    checkSessionExpiration();
    // This method is smart and it will stop executing when the page is not active
    ifvisible.onEvery(30, checkSessionExpiration);

    // Clean up the event listener on component unmount
    return () => {
      ifvisible.off('every'); // Clear the interval on unmount
    };
  }, []);

  //  Click button on dialog to refresh token
  const onRefreshToken = () => {
    const token = window.localStorage.getItem('refreshToken');
    if (token) {
      setIsRefreshing(true);
      refreshToken(token, dispatch)
        .then(refreshTokenHandle)
        .catch(() => {
          // refreshToken error, logout to avoid keep displaying refresh-token dialog
          autoLogout();
        })
        .finally(() => {
          dispatch(setExpired(false));
          setIsRefreshing(false);
          // window.location.reload(); // why reload page?
        });
    } else {
      autoLogout();
    }
  };

  return (
    <Modal open={isExpired}>
      <Dialog open={true}>
        <div className={classes.root}>
          <div className={classes.header}>Refresh Session</div>
          <div style={{ marginBottom: 20 }}>Your session has expired. Do you want to refresh the session?</div>
          <div className={classes.btnContainer}>
            <Button style={{ marginRight: 20 }} variant="contained" color="secondary" onClick={onRefreshToken}>
              {`Yes `}
              {isRefreshing && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
            </Button>
            <Button variant="contained" color="inherit" onClick={onLogout}>
              {`No`}
            </Button>
          </div>
        </div>
      </Dialog>
    </Modal>
  );
};

export default TokenHandler;
