import { get } from 'lodash';
import { getConfigurations } from 'src/app/common/utils';

const templateFileData = [
  [
    'Exam Type',
    'Exam Code',
    'Exam Name(Local Language)',
    'Exam Name(English)',
    'Exam Mode',
    'Device Type',
    'Exam Date',
    'Start Time',
    'End Time',
    'Venue',
    'Address',
    'Exam Fee',
    'Exam Fee Currency Code',
    'Start Registration Date',
    'Last Registration Date',
    'Quota',
    'Minimum Quota',
    'Remarks',
  ],
  [
    'F',
    'AAJI',
    'Exam Paper 1',
    'Exam Paper 1',
    'Computer',
    'talbet',
    '26/08/2025',
    '09:30',
    '15:30',
    'Jakarta Exam Centre',
    'Jakarta',
    '123.50',
    'HKD',
    '14/07/2025 12:30',
    '14/08/2025 12:30',
    50,
    40,
    undefined,
  ],
  [
    'S',
    'AASI',
    'Exam Paper 1',
    'Exam Paper 1',
    'Pencil and Paper',
    '',
    '26/08/2025',
    '14:30',
    '15:30',
    'Jakarta Exam Centre',
    'Jakarta',
    '123.50',
    'HKD',
    '14/07/2025 08:40',
    '14/08/2025 08:40',
    50,
    40,
    undefined,
  ],
];

export const getTemplateFileData = () => {
  const config = getConfigurations();
  const enableExamFeeInput = get(config, 'Recruitment.enableExamFeeInput', false);
  if (!enableExamFeeInput) {
    const examFeeIndex = templateFileData[0].indexOf('Exam Fee');
    const currencyCodeIndex = templateFileData[0].indexOf('Exam Fee Currency Code');
    for (let i = 0; i < templateFileData.length; i++) {
      if (currencyCodeIndex > examFeeIndex) {
        templateFileData[i].splice(currencyCodeIndex, 1);
        templateFileData[i].splice(examFeeIndex, 1);
      } else {
        templateFileData[i].splice(examFeeIndex, 1);
        templateFileData[i].splice(currencyCodeIndex, 1);
      }
    }
  }
  return templateFileData;
};
