import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';

type ComponentProps = ParamsProps & {
  onCancel?: () => void;
  onOk?: () => void;
  open: boolean;
  title?: string;
  content?: string;
};

export const SubmitAlertComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { open, onCancel, onOk, title, content } = props;
  // i18n
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title || t('common.warning')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content
            ? content.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : t('segment.remove.agent')}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="tw-mb-4 tw-mr-4">
        <Button onClick={onCancel} variant="outlined" color="inherit">
          {t('global.text.cancel')}
        </Button>
        <Button onClick={onOk} autoFocus variant="contained" color="secondary">
          {t('global.text.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
