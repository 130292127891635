import React from 'react';
import Form, { Field, useForm } from 'rc-field-form';
import { FieldProps } from 'rc-field-form/lib/Field.d';
import RefForm from 'rc-field-form/lib/index.d';

const Error = ({ children, label }: { children: React.ReactNode[]; label?: React.ReactNode }) => (
  <ul style={{ color: 'red', marginLeft: label ? 160 : 0, paddingLeft: 0, listStyle: 'none' }}>
    {((children && children.length && [children[0]]) || []).map((error: React.ReactNode, index: number) => (
      <li key={index}>{error}</li>
    ))}
  </ul>
);

type RefFormType = typeof RefForm;
export interface NewRefForm extends RefFormType {
  Item: React.FunctionComponent<LabelFieldProps>;
}

interface LabelFieldProps extends FieldProps {
  label?: React.ReactNode;
  noStyle?: boolean;
  required?: boolean;
  style?: any;
  hideError?: boolean;
  hideDot?: boolean;
  onChange?: any;
  labelTop?: boolean;
  getValueFromEvent?: (...args: any[]) => any; // Function to extract value from event
  valuePropName?: string; // Specify the value property of the component, default is 'value'
}

const Item: React.FunctionComponent<LabelFieldProps> = ({
  name,
  label,
  children,
  noStyle,
  required = true,
  hideError = false,
  hideDot = false,
  style = {},
  onChange: parentOnChange,
  labelTop = false,
  getValueFromEvent,
  valuePropName = 'value', // Default bound to value property
  ...restProps
}) => {
  // Default value extraction logic, only used for common single-parameter components
  const defaultGetValueFromEvent = (...args: any[]) => {
    const event = args[0];
    if (event && event.target) {
      return event.target.value !== undefined ? event.target.value : event.target.checked;
    }
    return args[0]; // Default return the first parameter
  };

  const extractValue = getValueFromEvent || defaultGetValueFromEvent;

  return (
    <Field name={name} {...restProps}>
      {(control, meta, form) => {
        const childNode =
          typeof children === 'function'
            ? children({ ...control }, meta, form)
            : React.cloneElement(children as React.ReactElement, {
                ...control,
                [valuePropName]: control.value, // Dynamically bind value
                onChange: (...args: any[]) => {
                  const value = extractValue(...args); // Use custom or default logic to extract value
                  control.onChange(value); // Update form value
                  if (children && typeof children !== 'function') {
                    const childProps = (children as React.ReactElement).props;
                    if (childProps.onChange) {
                      childProps.onChange(...args); // Call original onChange
                    }
                  }
                  if (parentOnChange) {
                    parentOnChange(...args); // Call parent onChange
                  }
                },
              });

        return noStyle ? (
          childNode
        ) : (
          <div className="pru-form-item" style={{ position: 'relative', width: '100%', ...style }}>
            <div style={{ display: 'flex', alignItems: labelTop ? 'flex-start' : 'center' }}>
              {label === undefined ? null : (
                <label style={{ flex: 'none', width: 160, marginTop: labelTop ? '9px' : '0' }}>
                  {label}
                  {required && !hideDot && <span style={{ color: 'red' }}>*</span>}
                </label>
              )}
              {childNode}
            </div>
            {hideError ? null : <Error label={label}>{meta.errors}</Error>}
          </div>
        );
      }}
    </Field>
  );
};

export { Item, useForm };

const temp: any = Form;
temp.Item = Item;
const NewForm: NewRefForm = temp;

export default NewForm;
