import React, { FC, useReducer } from 'react';
import { PermissionItem, PermissionType, PermissionPossession, YesOrNo } from '../../../types/permission-types';
import { makeStyles } from 'tss-react/mui';
import { Checkbox, FormControl, Select, MenuItem } from '@mui/material';
import { map, difference } from 'lodash';
import { useUpdateEffect } from 'src/app/common/utils';

type ModulePermissionItemProps = {
  title: string;
  namespace: string;
  possession: PermissionPossession;
  permissions?: PermissionItem[];
  onUpdate: (resource: string, permissions: PermissionItem[]) => void;
  extendOptions?: any;
};

const useStyles = makeStyles()(() => ({
  root: {},
  divider: {
    width: 2,
    height: 25,
    backgroundColor: '#DDDDDD',
    opacity: 0.8,
  },
  fieldContainer: {
    width: 150,
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '8px 15px 8px 15px',
    borderRadius: 8,
  },
  inputBox: {
    paddingTop: 6,
    paddingBottom: 8,
  },
}));

type PermissionStateItem = {
  action: string;
  possession: string;
  resource: string;
  attributes: string[];
};

type PermissionState = PermissionStateItem[];

type ModifyPermission = {
  type: 'MODIFY_PERMISSION';
  payload: {
    namespace: string;
    permissionType: PermissionType;
    possessions: PermissionPossession[];
  };
};

type PermissionAction = ModifyPermission;

const permissionReducer = (state: PermissionState, action: PermissionAction) => {
  switch (action.type) {
    case 'MODIFY_PERMISSION':
      if (!action.payload.permissionType || !action.payload.possessions) {
        return [...state];
      }
      const possessions = action.payload.possessions;
      const permissionType = action.payload.permissionType;

      const originalItems = state.filter((item) => item.action.toLowerCase() !== permissionType.toLowerCase());
      const tempArr = state.filter((item) => item.action.toLowerCase() === permissionType.toLowerCase());

      const deleteItems: string[] = difference(
        tempArr.map((item) => item.possession),
        possessions,
      );
      const newAddedStr: string[] = difference(
        possessions,
        tempArr.map((item) => item.possession),
      );
      const newAddedItems = newAddedStr.map((str) => ({
        action: action.payload.permissionType,
        possession: str,
        resource: action.payload.namespace.toLowerCase(),
        attributes: ['*'],
      }));

      const newTemp = tempArr.filter((tempItem) => !!!deleteItems.find((item) => item === tempItem.possession));

      return [...originalItems, ...newAddedItems, ...newTemp];
    default:
      return [...state];
  }
};

const ModulePermissionItem: FC<ModulePermissionItemProps> = ({
  title,
  namespace,
  possession,
  permissions,
  onUpdate,
  extendOptions,
}) => {
  const { classes } = useStyles();
  const [permissionState, permissionDispatch] = useReducer(
    permissionReducer,
    (permissions || []).filter(
      (permission) => namespace && permission.resource.toLowerCase() === namespace.toLowerCase(),
    ),
  );

  const permissionExtractor = (action: PermissionType) => {
    const items = permissionState.filter(
      (permissionItem) => permissionItem.action.toLowerCase() === action.toLowerCase(),
    );
    return items.map((item) => item.possession);
  };

  useUpdateEffect(() => {
    onUpdate(
      namespace.toLowerCase(),
      permissionState.map((stateItem) => ({
        ...stateItem,
        action: stateItem.action.toLowerCase(),
        possession: stateItem.possession.toLowerCase(),
        resource: stateItem.resource.toLowerCase(),
      })),
    );
  }, [permissionState]);

  const renderOptionItem = (
    option: PermissionType,
    extendOptions: { enableApprove: boolean; enableArchive: boolean; disableCreate: boolean; disableUpdate: boolean },
  ) => {
    if (option === PermissionType.APPROVE) {
      if (!extendOptions || !extendOptions.enableApprove) {
        return;
      }
    } else if (option === PermissionType.ARCHIVE) {
      if (!extendOptions || !extendOptions.enableArchive) {
        return;
      }
    } else if (option === PermissionType.CREATE && extendOptions?.disableCreate) {
      return;
    } else if (option === PermissionType.UPDATE && extendOptions?.disableUpdate) {
      return;
    }

    return (
      <div key={`permission-type-${option}`} className={classes.rowContainer}>
        <span>{`${option} :`}</span>
        <FormControl style={{ margin: '0 15px 0 15px' }} margin="dense" variant="outlined">
          <Checkbox
            checked={permissionExtractor(option)[0] && permissionExtractor(option)[0] === possession ? true : false}
            onChange={(e) => {
              permissionDispatch({
                type: 'MODIFY_PERMISSION',
                payload: {
                  namespace,
                  permissionType: option,
                  possessions: e.target.checked ? [possession] : [],
                },
              });
            }}
          />
        </FormControl>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.fieldContainer}>{title}</div>
        <div className={classes.section}>
          {map(PermissionType, (option) => {
            return renderOptionItem(option, extendOptions);
          })}
        </div>
      </div>
    </div>
  );
};

export default ModulePermissionItem;
