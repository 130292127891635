import React, { FC } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import { NEWS_BASE_PATH, NEWS_CATEGORY_PATH } from '../../constants';
import PruRoute from '../../../../common/components/PruRoute';
import NewsCategoryDetailPage from './View/NewsCategoryDetailPage';
import NewsCategoryListPage from './List/NewsCategoryListPage';
import { ModulePermissionProps } from '../../../../common/module/types';

const newsCategoryPath = `${NEWS_BASE_PATH}${NEWS_CATEGORY_PATH}`;

const NewsCategoryPage: FC<RouteComponentProps & ModulePermissionProps> = (props) => {
  return (
    <Switch>
      <Redirect exact from={newsCategoryPath} to={`${newsCategoryPath}/list`} />
      <PruRoute exact path={`${NEWS_BASE_PATH}${NEWS_CATEGORY_PATH}/list`} component={NewsCategoryListPage} />
      // category add or edit
      <PruRoute exact path={`${newsCategoryPath}/edit/:id?`} component={NewsCategoryDetailPage} />
    </Switch>
  );
};

export default NewsCategoryPage;
