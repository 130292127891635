import { FC, useState } from 'react';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { AgentSegmentListParam, fetchAgentSegmentList } from 'src/app/common/network';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList, AgentSegmentItem } from 'src/app/common/types';
import { useDispatch } from 'react-redux';
import ConfigAgentSegmentList from './components/ConfigAgentSegmentList';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { useServerLocale } from 'src/app/i18n';

type AgentSegmentListingPageProps = {} & ParamsProps;

const initialState: AgentSegmentListParam = {
  limit: 20,
  page: 1,
  search: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): AgentSegmentListParam => {
  return initialParams
    ? {
        ...initialState,
        search: initialParams.search ?? '',
      }
    : initialState;
};

export const agentSegmentFilterKeys = ['categoryId', 'search', 'isHeadline', 'isPublished'];

const ConfigAgentSegmentListingPage: FC<AgentSegmentListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const locale = useServerLocale();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [filterState, setFilterState] = useState<AgentSegmentListParam>(paramsInitiator(initialParams));

  const [agentSegmentList, setAgentSegmentList] = useState<PaginateList<AgentSegmentItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateList<AgentSegmentItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchAgentSegmentList(filterState, dispatch);
      } catch (err) {}
    },
    setAgentSegmentList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('configAgentSegment.search')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 250 },
            field: 'search',
            initialValue: filterState.search,
            displayName: Translation('configAgentSegment.search.name'),
          },
          // {
          //   type: PruFilterItemType.FREE_TEXT,
          //   style: { width: 250 },
          //   field: "search",
          //   initialValue: filterState.search,
          //   displayName: Translation("newsArticle.common.keyword")
          // }
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            search: data.search,
          })
        }
        fetchData={refreshData}
      />
      <ConfigAgentSegmentList
        isLoading={isLoading}
        agentSegmentList={agentSegmentList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default ConfigAgentSegmentListingPage;
