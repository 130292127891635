import moment, { Moment } from 'moment';

// List of supported date formats, ordered by specificity (most precise first)
const POSSIBLE_FORMATS = [
  // Date + Time (most precise, with seconds)
  'YYYY/MM/DD HH:mm:ss',
  'MM-DD-YYYY HH:mm:ss',
  'DD.MM.YYYY HH:mm:ss',
  'YYYY.MM.DD HH:mm:ss',
  'DD/MM/YYYY HH:mm:ss',
  'MM/DD/YYYY HH:mm:ss',
  'YYYY-MM-DD HH:mm:ss',
  'DD-MM-YYYY HH:mm:ss',
  'MMMM DD, YYYY HH:mm:ss',
  'DD MMM YYYY HH:mm:ss',
  // ISO formats (with time)
  'YYYY-MM-DDTHH:mm:ssZ', // e.g., 2007-02-21T14:30:00Z
  'YYYY-MM-DDTHH:mm:ss', // e.g., 2007-02-21T14:30:00
  // Date + Time (hours and minutes only)
  'DD/MM/YYYY HH:mm',
  'MM/DD/YYYY HH:mm',
  'YYYY-MM-DD HH:mm',
  'DD-MM-YYYY HH:mm',
  'MMMM DD, YYYY HH:mm',
  'DD MMM YYYY HH:mm',
  // Date only (least precise)
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'YYYY-MM-DD',
  'DD-MM-YYYY',
  'MMMM DD, YYYY', // e.g., February 21, 2007
  'DD MMM YYYY', // e.g., 21 Feb 2007
  'YYYY/MM/DD',
  'MM-DD-YYYY',
  'DD.MM.YYYY',
  'YYYY.MM.DD',
] as const;

// Define supported format type
type DateFormat = (typeof POSSIBLE_FORMATS)[number];

// Input type, supporting string, Date, and Moment
type DateInput = string | Date | Moment;

// Define the return type for chainable methods
interface DateParser {
  toDate: () => Date | null;
  format: (outputFormat: string) => string | null;
  isValid: () => boolean;
  toMoment: () => Moment | null;
}

/**
 * Parse any date format, supporting string, Date, and Moment inputs, returning a chainable date object
 * @param input - The input date, which can be a string, Date, or Moment
 * @param customFormats - Optional array of custom formats, takes precedence over default formats, only applies to string input
 * @returns DateParser - A chainable date parsing object
 */
export function parseDate(input: DateInput, customFormats: string[] = []): DateParser {
  let parsedMoment: Moment = null as any;

  // Handle input based on its type
  if (typeof input === 'string') {
    // String input: attempt parsing with custom and default formats
    const formats = [...customFormats, ...POSSIBLE_FORMATS];

    // Try parsing with each format, prioritizing more specific matches
    for (const format of formats) {
      const tempMoment: Moment = moment(input, format, true); // Strict mode
      if (tempMoment.isValid()) {
        // Ensure the format fully matches the input length and structure
        const formattedBack = tempMoment.format(format);
        if (formattedBack === input || input.length <= format.length) {
          parsedMoment = tempMoment;
          break;
        }
      }
    }
    // If no match found, mark as invalid
    parsedMoment = parsedMoment || moment.invalid();
  } else if (input instanceof Date) {
    // Date input: directly convert to Moment
    parsedMoment = moment(input);
  } else if (moment.isMoment(input)) {
    // Moment input: use a clone to avoid modifying the original
    parsedMoment = input.clone();
  } else {
    // Invalid input: return an invalid Moment object
    parsedMoment = moment.invalid();
  }

  return {
    /**
     * Convert the parsed result to a JavaScript Date object
     * @returns Date | null - Returns Date if valid, otherwise null
     */
    toDate: (): Date | null => {
      if (!parsedMoment.isValid()) return null;
      return parsedMoment.toDate();
    },

    /**
     * Format the parsed result into a specified string
     * @param outputFormat - The output format, e.g., "YYYY-MM-DD HH:mm:ss"
     * @returns string | null - Returns formatted string if valid, otherwise null
     */
    format: (outputFormat: string): string | null => {
      if (!parsedMoment.isValid()) return null;
      return parsedMoment.format(outputFormat);
    },

    /**
     * Check if the date is valid
     * @returns boolean - Whether the date is valid
     */
    isValid: (): boolean => parsedMoment.isValid(),

    /**
     * Return a Moment object for more complex operations
     * @returns Moment | null - Returns Moment object if valid, otherwise null
     */
    toMoment: (): Moment | null => {
      if (!parsedMoment.isValid()) return null;
      return parsedMoment.clone();
    },
  };
}
