import { Dispatch } from 'react';
import { downloadFile } from 'src/app/common/utils';
import { ADMIN_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import { PaginateList } from '../types/recruit-generic-types';
import {
  ExamResultsFileData,
  ExamScheduleFileData,
  GetScheduleResponse,
  LicenseExamRegistrationExportParam,
  LicenseExamResultsListParam,
  RegistrationItemDetail,
  RegistrationListParam,
  UpdateExamScheduleData,
  examOptionsParam,
  examPassingRateParam,
  examResultsParam,
  examScheduleParam,
} from '../types/license-exam-types';
import qs from 'qs';
import moment from 'moment';
import { getConfig } from 'src/app/i18n/I18nConfig';
import { localLanguage } from 'src/app/i18n';

const licenseExamSchedule = 'license-exam-schedule';
const licenseExamRegistration = 'license-exam-registration';
const licenseExamResults = 'license-exam-results';

// const ADMIN_URL = 'http://localhost:3000';

export const createLicenseExamSchedule = async (
  body: { payload: ExamScheduleFileData[] },
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${licenseExamSchedule}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateLicenseExamSchedule = async (
  id: string,
  body: { payload: UpdateExamScheduleData },
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${licenseExamSchedule}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteLicenseExamSchedule = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${licenseExamSchedule}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getLicenseExamSchedule = async (id: string, dispatch?: Dispatch<any>): Promise<GetScheduleResponse> => {
  return apiClient
    .get<GetScheduleResponse>(`${ADMIN_URL}/${licenseExamSchedule}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLicenseExamExamScheduleList = async (
  params: examScheduleParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<LicenseExamResultsListParam>> => {
  const queryPath = `${ADMIN_URL}/${licenseExamSchedule}`;
  return apiClient
    .get<PaginateList<LicenseExamResultsListParam>>(queryPath, { params: params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLicenseExamExamOptions = async (
  type: string,
  params: examOptionsParam,
  dispatch?: Dispatch<any>,
): Promise<string[]> => {
  const queryPath = `${ADMIN_URL}/${licenseExamSchedule}/options/${type}`;
  return apiClient
    .get<string[]>(queryPath, { params: params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createLicenseExamResults = async (body: ExamResultsFileData, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${licenseExamResults}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLicenseExamResultsList = async (
  params: examResultsParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<examResultsParam>> => {
  const queryPath = `${ADMIN_URL}/${licenseExamResults}`;
  return apiClient
    .get<PaginateList<examResultsParam>>(queryPath, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const exportLicenseExamPassingRateList = async (
  params: examPassingRateParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<examPassingRateParam>> => {
  const queryPath = `${ADMIN_URL}/${licenseExamResults}`;
  return apiClient
    .get<PaginateList<examResultsParam>>(queryPath, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const exportRegistration = async (
  params: LicenseExamRegistrationExportParam,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  const query = qs.stringify(params);
  const queryPath = `${ADMIN_URL}/${licenseExamRegistration}/download?${query}`;
  const fileName = `Exam Registration ${moment().format('DDMMYYYY')}`;
  return downloadFile(fileName, queryPath);
};

export const fetchRegistrationList = async (params: RegistrationListParam, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<PaginateList<RegistrationListParam>>(`${ADMIN_URL}/${licenseExamRegistration}`, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchRegistrationDetail = async (
  registrationId: string,
  dispatch?: Dispatch<any>,
): Promise<RegistrationItemDetail> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${licenseExamRegistration}/${registrationId}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateRegistrationStatus = async (
  body: {
    registrationApprovalEvents: string;
    remark: string;
  },
  registrationId: string,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${licenseExamRegistration}/${registrationId}/approvals`, body)
    .then((response) => {
      response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const editRegistrationResult = async (
  body: {
    result: string;
    score: string;
  },
  registrationId: string,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${licenseExamRegistration}/${registrationId}/results`, body)
    .then((response) => {
      response.data;
    })
    .catch((err) => {
      console.log({ err });
      throw apiErrorHandler(err, dispatch);
    });
};

export const getExamPassingRateReportExcel = async (params?: any, dispatch?: Dispatch<any>) => {
  try {
    const response = await apiClient.get(`${ADMIN_URL}/${licenseExamResults}/reports-passing-rate`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      responseType: 'blob',
      headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    });
    return response;
  } catch (err: any) {
    if (err instanceof Blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorData = JSON.parse(reader.result as string);
            const { message } = errorData;
            // console.error(`Error ${statusCode}: ${message}`, details);
            reject(new Error(message)); // throw specific error message
          } catch (parseError) {
            reject(new Error('An unknown error occurred'));
          }
        };
        reader.onerror = () => {
          reject(new Error('An unknown error occurred'));
        };
        reader.readAsText(err);
      });
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const getExamCodeAndName = async (
  dispatch?: Dispatch<any>,
): Promise<{
  examCodeOptions: string[];
  examNameOptions: string[];
}> => {
  const lang = getConfig().selectedLang;
  // using promise all to get both examCode and examName
  return Promise.all([
    apiClient.get<string[]>(`${ADMIN_URL}/${licenseExamSchedule}/options/EXAM_CODE?lang=${lang}`),
    apiClient.get<string[]>(`${ADMIN_URL}/${licenseExamSchedule}/options/EXAM_NAME?lang=${lang}`),
  ])
    .then((response) => {
      return {
        examCodeOptions: response[0].data,
        examNameOptions: response[1].data,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getExamFieldsOptions = async (dispatch?: Dispatch<any>): Promise<any> => {
  const lang = getConfig().selectedLang;
  // using promise all to get both examCode and examName
  return Promise.all([
    apiClient.get<string[]>(`${ADMIN_URL}/${licenseExamSchedule}/options/EXAM_CODE?lang=${lang}`),
    apiClient.get<string[]>(`${ADMIN_URL}/${licenseExamSchedule}/options/EXAM_NAME?lang=en`),
    apiClient.get<string[]>(`${ADMIN_URL}/${licenseExamSchedule}/options/EXAM_NAME?lang=${localLanguage}`),
    apiClient.get<string[]>(`${ADMIN_URL}/${licenseExamSchedule}/options/EXAM_VENUE?lang=${lang}`),
    apiClient.get<string[]>(`${ADMIN_URL}/${licenseExamSchedule}/options/EXAM_ADDRESS?lang=${lang}`),
  ])
    .then((response) => {
      const [
        { data: examCodeOptions },
        { data: examNameOptions },
        { data: localExamNameOptions },
        { data: venueOptions },
        { data: addressOptions },
      ] = response;
      return {
        examCodeOptions,
        examNameOptions,
        localExamNameOptions,
        venueOptions,
        addressOptions,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
