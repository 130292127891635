import { Dispatch } from 'react';
import { ADMIN_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import { AddTemplateDto, TemplateListData } from '../types/registration-template-types';

const colummnsSettingURl = 'columns-setting';

export const fetchRegistrationTemplates = async (dispatch?: Dispatch<any>): Promise<TemplateListData> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${colummnsSettingURl}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteColumnsTemplateById = async (templateId: string, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${colummnsSettingURl}/${templateId}`;
  return apiClient
    .delete(url)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const addColumnsTemplate = async (body: AddTemplateDto, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${colummnsSettingURl}`;
  return apiClient
    .post(url, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
