import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';

import { UserManagementLogDetailPage } from '../user-management-log-detail/user-management-log-detail.screen';

type PageProps = ParamsProps & RouteComponentProps;

export const NewsArticleLogDetailPage: React.FC<PageProps> = (props: PageProps) => {
  // reuse same detail page, but the top breadcrumb need to match this page
  return <UserManagementLogDetailPage {...props} />;
};
