import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRequest } from 'ahooks';
import { useDispatch } from 'react-redux';

import { fetchAgentSegmentList } from 'src/app/common/network';
import { useServerLocale } from 'src/app/i18n';
import { allCategoryParam, fetchNewsCategoryList } from '../../../network/newsCrud';
import { getSubSegmentConfig } from '../View/components/NewsDetail.util';
import { fetchAgentSubSegmentList } from 'src/app/modules/Config/network/agent-sub-segment.network';

export const useNewsListingPage = () => {
  // const dispatch = useDispatch();
  // const t = useTranslation();
  const locale = useServerLocale();
  const [segmentList, setSegmentList] = useState<string[]>([]);
  const [segmentOption, setSegmentOption] = useState<{ displayName: string; value: string }[]>([]);

  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [categoryOption, setCategoryOption] = useState<{ displayName: string; value: string }[]>([]);

  // get config
  const { enabledSubsegment } = useMemo(() => {
    return getSubSegmentConfig();
  }, []);

  const { loading: loadingSubsegment, data: subsegmentList } = useRequest(
    () => fetchAgentSubSegmentList({ pagination: false }),
    {
      manual: false,
    },
  );

  const {
    data: unicodeList,
    run: fetchSegmentList,
    loading: loadingSegmentList,
  } = useRequest(() => fetchAgentSegmentList({ page: 1, limit: 200 }), {
    manual: false,
    onSuccess: (data) => {
      let value: string[] = [];
      let option: { displayName: string; value: string }[] = [];
      (data.docs || []).forEach((item) => {
        value.push(item._id);
        option.push({
          displayName: item.name[locale],
          value: item._id,
        });
      });

      // setSegmentList(value); // dropdown init with []
      setSegmentOption(option);
    },
  });

  const { loading: loadingCategory } = useRequest(() => fetchNewsCategoryList(allCategoryParam), {
    manual: false,
    onSuccess: (data) => {
      // console.log('datav=====:', JSON.stringify(data));
      let value: string[] = [];
      let option: { displayName: string; value: string }[] = [];
      (data.categories || []).forEach((item) => {
        value.push(item._id);
        option.push({
          displayName: item.name[locale],
          value: item._id,
        });
      });

      // setCategoryList(value); // dropdown init with []
      setCategoryOption(option);
    },
  });
  /**
   * get sub-segment by ids, only get active item names.
   * return ['-'] if not found.
   * @param ids
   * @returns
   */
  const getSubsegmentNames = (ids: string[]) => {
    if (!Array.isArray(ids)) {
      return ['-'];
    }
    const name = subsegmentList?.docs
      .filter((item) => item.isActive)
      .map((item) => {
        if (ids.includes(item._id)) {
          return item.name[locale];
        }
        return null;
      })
      .filter((item) => item);
    return name && name.length > 0 ? name : ['-'];
  };

  // useEffect(() => {}, []);

  return {
    segmentList,
    segmentOption,
    categoryList,
    categoryOption,
    loadingSegmentList,
    loadingCategory,
    enabledSubsegment,
    getSubsegmentNames,
  };
};
