import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { getConfigurations } from 'src/app/common/utils';
import { AgentSubSegmentListParam, fetchSubSegmentDetail } from '../../../network/agent-sub-segment.network';

interface HookProps {
  id: string;
  // other params
}

const defaultParam = {
  page: 1,
  pageSize: 20,
  limit: 5,
};

export const useSubSegmentDetail = ({ id }: HookProps) => {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const locale = useLang();

  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);

  // get runtime-config
  const { enableFeature, statusEnum } = useMemo(() => {
    const config = getConfigurations();
    const enableFeature = get(config, 'Module.path.enableFeature') || false;
    const statusEnum = get(config, 'Module.path.statusEnum') || [];
    return { enableFeature, statusEnum };
  }, []);

  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);

  // useRequest to wrap api request.
  // DO NOT use useDataProvider except for <PruFilter />
  const {
    data,
    error,
    loading = false,
    run,
    runAsync,
  } = useRequest(() => fetchSubSegmentDetail(id, dispatch), {
    manual: true,
  });

  useEffect(() => {
    // manual call api request
    if (id) {
      run();
    }
  }, [id]);

  const handlePress = useCallback(() => {
    setSortKey([{ key: 'updatedAt', value: 'desc' }]);
  }, []);

  return {
    loading,
    data,
    run,
    handlePress,
  };
};
