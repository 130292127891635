import { utils, writeFile, write } from 'xlsx';
import { uploadExcel } from '../redux/notificationCrud';

export enum NotificationFormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export const isDateValid = (date: Date) => {
  return !Number.isNaN(date.getTime());
};

export const uploadMockAgentListFile = async (agentCodes: string[], inclusive: boolean) => {
  // Create a worksheet with the AgentCode data
  if (agentCodes.length === 0) {
    return;
  }
  const ws_data = [['agentCode'], ...agentCodes.map((code) => [code])];
  // Create a worksheet
  const ws = utils.aoa_to_sheet(ws_data);
  // Create a workbook
  const wb = { SheetNames: ['Sheet1'], Sheets: { Sheet1: ws } };

  const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });

  const fileName = `AgentCode_${new Date().getTime()}.xlsx`;
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const file = new File([blob], fileName, {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const params: any = {
    file: file,
    inclusive: inclusive,
    contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    fileName: fileName,
  };
  const res = await uploadExcel({ ...params });
  const url = res.url;
  const httpUrl = url?.includes('http') ? url : `https:${url}`;
  return {
    ...res,
    url: httpUrl,
    importId: res.id,
    fileName,
  };
};
