import React, { FC, useMemo, useReducer, useState } from 'react';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';
import { NEWS_BASE_PATH, NEWS_CATEGORY_PATH } from '../../../../constants';
import { Button, TextField, Dialog, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useErrorHandler, ErrorFieldType } from 'src/app/common/utils';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  NewsCategoryEditItem,
  NewsCategoryEmailItem,
  NewsCategoryFormMode,
  NewsCategoryItem,
  NewsTitleItem,
} from '../../../../types/news-type';
import { MuiColorInput } from 'mui-color-input';
import { modifyNewsCategory } from '../../../../network/newsCrud';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { getAvailableServerLocale } from 'src/app/i18n';
import { useStyles } from './style';
import { get, isEmpty, isNil } from 'lodash';
import { useCategoryForm } from './hook/category-form.hook';
import { ModifyLocaleFieldAction } from 'src/app/modules/Config/pages/agent-sub-segment/sub-segment-detail/sub-segment-form/util';
import { SegmentNameItem } from 'src/app/modules/Config/types/agent-segment-type';
import { SubmitAlertComponent } from 'src/app/modules/Config/pages/AgentSegment/View/components/submit-alert/submit-alert.component';

type NewsCategoryDetailFormProps = {
  formMode: NewsCategoryFormMode;
  newsCategoryDetail?: NewsCategoryItem;
  code: string;
  onRouteTo: (route: string) => void;
};
export type NewsCategoryFormState = {
  color?: string;
  maxNoOfHeadlines: string;
  maxArchiveDays: string;
  active?: boolean;
  name?: SegmentNameItem;
  emails?: NewsCategoryEmailItem[]; // old field, not use anymore?
  // dynamic field: i18n category-name
  // [key: string]: string | undefined | boolean;
};

const initialState: NewsCategoryFormState = {
  color: '#caabbb',
  maxNoOfHeadlines: '1',
  maxArchiveDays: '90',
  active: true, // default true
  name: {},
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof NewsCategoryFormState;
    value: any;
  };
};

type NewsCategoryFormAction = ModifyFieldAction | ModifyLocaleFieldAction;

const newsCategoryReducer = (state: NewsCategoryFormState, action: NewsCategoryFormAction) => {
  switch (action.type) {
    // i18n field, example: { name: { "enUs": "xx","zhHk": "yy"} }
    case 'MODIFY_LOCALE_FIELD':
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          [action.payload.locale]: action.payload.value,
        },
      };
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return initialState;
  }
};

const detailToStateConvertor = (newsCategoryDetail?: NewsCategoryItem): NewsCategoryFormState => {
  return newsCategoryDetail
    ? {
        name: newsCategoryDetail.name || {},
        active: newsCategoryDetail.active,
        // isNil(newsCategoryDetail.active) || isEmpty(newsCategoryDetail.active)
        //   ? true
        //   : Boolean(newsCategoryDetail.active),
        color: newsCategoryDetail.color,
        maxNoOfHeadlines:
          typeof newsCategoryDetail.maxNoOfHeadlines === 'number' ? newsCategoryDetail.maxNoOfHeadlines.toString() : '',
        maxArchiveDays:
          typeof newsCategoryDetail.maxNoOfHeadlines === 'number' ? newsCategoryDetail.maxArchiveDays.toString() : '',
      }
    : initialState;
};

function validatePositiveInteger(input: string) {
  const trimmedInput = String(input).trim();
  const regex = /^[1-9]\d*$/;
  const isValid = regex.test(trimmedInput);

  return isValid;
}

const NewsCategoryDetailForm: FC<NewsCategoryDetailFormProps> = ({ formMode, newsCategoryDetail, code, onRouteTo }) => {
  const isEditMode = formMode === NewsCategoryFormMode.EDIT;
  const availableLocales = getAvailableServerLocale();
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const [errorDialog, setErrorDialog] = useState({
    show: false,
    message: '',
  });
  const initDetail = useMemo(() => detailToStateConvertor(newsCategoryDetail), [newsCategoryDetail]);
  const [formState, formDispatch] = useReducer(newsCategoryReducer, initDetail);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    ...availableLocales.map((locale) => ({
      name: `name${locale}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        let flag = false;
        if (!formState.name || !formState.name[locale]) {
          flag = true;
        }
        return flag;
      },
    })),
    {
      name: 'color',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'maxNoOfHeadlines',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !validatePositiveInteger(formState.maxNoOfHeadlines);
      },
    },
    {
      name: 'maxArchiveDays',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !validatePositiveInteger(formState.maxArchiveDays);
      },
    },
  ]);
  const { onOk, onCancel, onSubmitForm, confirmMsg } = useCategoryForm({ isEditMode, onRouteTo, id: code });
  const onSubmit = async () => {
    let { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      onSubmitForm(formState, initDetail.active);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {isEditMode ? `${Translation('newsCategory.editMain')}` : Translation('component.formTitle.add-category')}
            </div>
          </div>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              onRouteTo(`${NEWS_BASE_PATH}${NEWS_CATEGORY_PATH}`);
            }}
          >
            {Translation('app.button.back')}
          </Button>
        </div>

        {availableLocales.map((locale) => (
          <div key={locale} className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation(`newsCategory.common.name.${locale}`)}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div className={classes.flexGrow}>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 30 }}
                error={errorState.mandatory[`name${locale}`]}
                helperText={errorState.mandatory[`name${locale}`] && Translation('field_mandatory_helper_label')}
                value={get(formState, `name.${locale}`) || ''}
                onChange={(e) => {
                  onDismissErrorHandler(`name${locale}`, e.target.value);
                  formDispatch({
                    type: 'MODIFY_LOCALE_FIELD',
                    payload: { field: 'name', locale, value: e.target.value },
                    // payload: { field: `title${locale}`, value: e.target.value },
                  });
                }}
              />
            </div>
          </div>
        ))}
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('newsCategory.common.color')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <MuiColorInput
            isAlphaHidden
            style={{ width: 200 }}
            name="color"
            margin="dense"
            variant="outlined"
            format="hex"
            error={errorState.mandatory.color}
            helperText={errorState.mandatory.color && Translation('field_mandatory_helper_label')}
            value={formState.color || ''}
            onChange={(color) => {
              onDismissErrorHandler('color', color);
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'color', value: color } });
            }}
          />
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('newsCategory.common.maxHeadLines')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <TextField
            style={{ width: 200 }}
            error={errorState.mandatory.maxNoOfHeadlines}
            margin="dense"
            variant="outlined"
            helperText={
              errorState.mandatory.maxNoOfHeadlines && Translation('newsCategory.error.mandatory.maxHeadLines')
            }
            inputProps={{
              className: classes.numberInput,
            }}
            type="number"
            value={formState.maxNoOfHeadlines}
            onChange={(e) => {
              onDismissErrorHandler('maxNoOfHeadlines', true);
              formDispatch({
                type: 'MODIFY_FIELD',
                payload: { field: 'maxNoOfHeadlines', value: e.target.value },
              });
            }}
          />
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('newsCategory.common.maxArchivedDays')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <TextField
            style={{ width: 200 }}
            error={errorState.mandatory.maxArchiveDays}
            margin="dense"
            variant="outlined"
            helperText={
              errorState.mandatory.maxArchiveDays && Translation('newsCategory.error.mandatory.maxArchivedDays')
            }
            inputProps={{
              className: classes.numberInput,
            }}
            type="number"
            value={formState.maxArchiveDays}
            onChange={(e) => {
              onDismissErrorHandler('maxArchiveDays', true);
              formDispatch({
                type: 'MODIFY_FIELD',
                payload: { field: 'maxArchiveDays', value: e.target.value },
              });
            }}
          />
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('component.formSelectItem.active')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <RadioGroup className={classes.flexRow}>
            <FormControlLabel
              value="Enable"
              control={<Radio disabled={false} />}
              label={Translation('app.checkbox.yes')}
              checked={formState.active === true}
              onChange={() => {
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'active', value: true } });
              }}
            />
            <FormControlLabel
              value="Disable"
              control={<Radio disabled={false} />}
              checked={formState.active === false}
              label={Translation('app.checkbox.no')}
              onChange={() => {
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'active', value: false } });
              }}
            />
          </RadioGroup>
        </div>

        <div className={classes.footerContainer}>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            {Translation('app.button.submit')}
          </Button>
        </div>
      </div>

      <SubmitAlertComponent
        open={!!confirmMsg}
        title={confirmMsg?.title}
        content={confirmMsg?.content}
        onCancel={onCancel}
        onOk={onOk}
      />

      {/** Error Dialog
      <Dialog
        onClose={() => {
          setErrorDialog((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        open={errorDialog.show}
      >
        <div className={classes.container}>
          <div>{errorDialog.message}</div>
          <div className={classes.footer}>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                setErrorDialog((prev) => ({
                  ...prev,
                  show: false,
                }));
              }}
            >
              {Translation('global.text.okay')}
            </Button>
          </div>
        </div>
      </Dialog>
      */}
    </>
  );
};
export default NewsCategoryDetailForm;
