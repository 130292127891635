import { get } from 'lodash';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getConfigurations } from 'src/app/common/utils';
import { useLang } from 'src/app/i18n';
import { RegistrationApprovalStatesEnum } from 'src/app/modules/Recruitment/enum/license-exam.enum';
import { exportRegistration } from 'src/app/modules/Recruitment/network/license-exam-crud';
import {
  PaginateList,
  RegistrationItem,
  RegistrationListParam,
  statusType,
} from 'src/app/modules/Recruitment/types/license-exam-types';
import { Template } from 'src/app/modules/Recruitment/types/registration-template-types';
import { dateFormatToLocal, handleDowloadFileError, utcToLocalDate } from 'src/app/modules/Recruitment/utils';
import { SettingColumnsScreen } from '../../setting-columns/setting-columns.screen';
import { examRegistrationBasePath } from '../registration-routes';

type RegistrationListProps = {
  isLoading: boolean;
  registrationList?: PaginateList<RegistrationItem>;
  formState: RegistrationListParam;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
  enableUpdate: boolean;
  enableApproval: boolean;
  onTemplateChange?: (template: Template | null) => void;
};

export const RegistrationList: FC<RegistrationListProps> = ({
  isLoading,
  registrationList,
  onRefresh,
  onChangePage,
  onSort,
  formState,
  enableUpdate,
  onTemplateChange,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const Translation = useCallback((id: string) => intl.formatMessage({ id }), [intl]);
  const locale = useLang();
  const config = getConfigurations();
  const enablePaymentInfo = get(config, 'Recruitment.enableLicenseExamPayment', false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [template, setTemplate] = useState<Template | null>(null);
  const [defaultColumns, setDefaultColumns] = useState<string[]>([]);

  // Download registration data
  const downloadRegistration = useCallback(async () => {
    try {
      await exportRegistration(
        {
          ...formState,
          examDateFrom: formState.examDateFrom ? moment(formState.examDateFrom).format('YYYY-MM-DD') : undefined,
          examDateTo: formState.examDateTo ? moment(formState.examDateTo).format('YYYY-MM-DD') : undefined,
          examName: formState.examName as string,
          lang: locale ?? 'en',
          fields: template?.fields
            ? template.fields.map((field) => `${field.name},${field.order}`).join('|')
            : undefined,
        },
        dispatch,
      );
    } catch (err) {
      handleDowloadFileError(err, dispatch);
    }
  }, [formState, dispatch, locale, template]);

  // Open the setting columns dialog
  const settingColumns = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  // Handle template change
  const handleTemplateChange = useCallback((defaultColumns: string[], template: Template | null) => {
    setTemplate(template);
    setDefaultColumns(defaultColumns);
  }, []);

  useEffect(() => {
    if (onTemplateChange) {
      onTemplateChange(template);
    }
  }, [template, onTemplateChange]);

  // Define special fields
  const specialFields = useMemo(
    () => ({
      examName: {
        keyIndex: 'examName',
        displayName: Translation('recruitment.exam.examName'),
        renderData: (row: RegistrationItem) => row.examName?.[locale] || row.examName?.['en'] || '-',
      },
      examDate: {
        renderData: (row: RegistrationItem) => dateFormatToLocal(row.examDate as string) || '-',
        sortable: true,
        onSort: (sort: Record<string, string>) => {
          onSort({ key: 'EXAM_DATE', value: sort['examDate']?.toLocaleUpperCase() });
        },
      },
      status: {
        keyIndex: 'status',
        displayName: Translation('recruitment.exam.registration.status'),
        renderData: (row: RegistrationItem) =>
          Translation(
            `recruitment.exam.registration.status.${
              RegistrationApprovalStatesEnum[row.registrationApprovalStates as statusType]
            }`,
          ),
      },
      submissionTime: {
        renderData: (row: RegistrationItem) => utcToLocalDate(row.submissionTime) || '-',
        sortable: true,
        onSort: (sort: Record<string, string>) => {
          onSort({ key: 'SUBMISSION_TIME', value: sort['submissionTime']?.toLocaleUpperCase() });
        },
      },
      lastUpdatedAt: {
        renderData: (row: RegistrationItem) => utcToLocalDate(row.lastUpdatedAt) || '-',
        sortable: true,
        onSort: (sort: Record<string, string>) => {
          onSort({ key: 'LAST_UPDATED_AT', value: sort['lastUpdatedAt']?.toLocaleUpperCase() });
        },
      },
    }),
    [Translation, locale, onSort],
  );

  // Generate column definitions dynamically
  const generateColumnDef = useCallback(
    (template: Template | null, specialFields: Record<string, any>, enablePaymentInfo: boolean) => {
      const fields =
        template?.fields ||
        defaultColumns?.map((name, index) => ({
          name,
          order: index,
        }));

      if (!fields || fields.length === 0) {
        return [];
      }

      return fields.map((field: { name: string }) => {
        const { name } = field;

        // Handle payment-related fields
        if (enablePaymentInfo && (name === 'examFeeReceiptNo' || name === 'licenseFeeReceiptNo')) {
          return {
            keyIndex: name,
            displayName: Translation(name),
            renderData: (row: RegistrationItem) => row[name] || '-',
          };
        }

        // Handle special fields
        if (specialFields[name]) {
          return {
            keyIndex: name,
            displayName: Translation(name),
            ...specialFields[name],
          };
        }

        // Handle regular fields
        return {
          keyIndex: name,
          displayName: Translation(name),
          renderData: (row: RegistrationItem) => row[name] || '-',
        };
      });
    },
    [Translation, defaultColumns, enablePaymentInfo],
  );

  // Generate column definitions
  const columnDef = useMemo(
    () => generateColumnDef(template, specialFields, enablePaymentInfo),
    [generateColumnDef, template, specialFields, enablePaymentInfo],
  );

  return (
    <div>
      <PruTable
        title={Translation('recruitment.exam.registration.list')}
        disableBulkSelect
        emptyColSpan={19}
        operationSticky={true}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('recruitment.exam.registration.uploadResult'),
            onClick: () => history.push(`${examRegistrationBasePath}/upload`),
            condition: () => enableUpdate,
          },
          {
            color: 'primary',
            title: 'Setting Columns',
            onClick: settingColumns,
          },
          {
            color: 'primary',
            title: Translation('app.button.download'),
            onClick: downloadRegistration,
          },
        ]}
        operationDef={[
          {
            title: Translation('section.common.operation.view'),
            tooltipText: 'View',
            onClick: (row) => history.push(`${examRegistrationBasePath}/view/${row.registrationId}`),
          },
        ]}
        columnDef={columnDef}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={registrationList?.data}
        totalPages={registrationList?.totalPages}
        totalRecords={registrationList?.totalNumbers}
        onChangePage={onChangePage}
        defaultRowsPerPage={20}
      />
      <SettingColumnsScreen
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onTemplateChange={handleTemplateChange}
      />
    </div>
  );
};
