import React, { FC, memo, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
// import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import {
  getSegmentNameById,
  getSelectedSegment,
  getSelectedSubSegment,
  getSubSegmentConfig,
  GroupedSegment,
  SelectOption,
} from '../NewsDetail.util';
import { useMount } from 'ahooks';
import { every, isEmpty } from 'lodash';

type ReturnData = { ids: string[]; subIds: string[] };
type ComponentProps = ParamsProps & {
  subsegmentOption?: GroupedSegment;
  segmentOption?: SelectOption[];
  selectedIds?: string[];
  selectedSubSegmentIds?: string[];
  onChange?: (data: ReturnData) => void;
};

export const SegmentSelectComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { subsegmentOption = [], segmentOption = [], selectedIds = [], selectedSubSegmentIds = [], onChange } = props;
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const lbAll = useMemo(() => Translation('component.status.all'), []); // menu-item label: All
  // get config
  const { enabledSubsegment } = useMemo(() => {
    // return { enabledSubsegment: false };
    return getSubSegmentConfig();
  }, []);

  const [selectedSegmentIds, setSelectedSegmentIds] = React.useState<string[]>(
    getSelectedSegment(selectedIds, segmentOption),
  );
  const [selectedSubIds, setSelectedSubIds] = React.useState<{ [key: number]: string[] }>(
    getSelectedSubSegment(selectedSubSegmentIds, subsegmentOption),
  );

  useMount(() => {
    if (isEmpty(selectedIds) && isEmpty(selectedSubSegmentIds)) {
      return;
    }
    // edit mode: check if both segment, sub-segment not selected, call onChange update form state
    // sub-segment all empty
    const isAllEmpty = every(selectedSubIds, (subArray) => isEmpty(subArray));
    // all sub-segment empty + segment empty
    if (isAllEmpty && isEmpty(selectedSegmentIds)) {
      onChange?.({ ids: [], subIds: [] });
    }
  });

  const handleChangeSegment = (event: SelectChangeEvent<typeof selectedSegmentIds>) => {
    const {
      target: { value },
    } = event;

    const segmentIds = segmentOption.map((item) => {
      return item.value;
    });

    let curSelected;

    // Check if "All" is selected
    if (value.includes('All')) {
      curSelected = selectedSegmentIds.length === segmentOption.length ? [] : segmentIds;
      // If "All" is selected, set all options
      setSelectedSegmentIds(curSelected);
    } else {
      curSelected = typeof value === 'string' ? value.split(',') : value;
      // Otherwise, handle the regular selection
      setSelectedSegmentIds(curSelected);
    }
    const allIds = getAllSelectedIds(curSelected);
    onChange?.(allIds);
  };
  const handleChangeSubSegment = (event: SelectChangeEvent<typeof selectedSegmentIds>, index: number) => {
    const {
      target: { value },
    } = event;

    const curSelectedSub = selectedSubIds?.[index] || [];
    const curAllSub = (subsegmentOption?.[index] || []).map((item) => {
      return item.value;
    });
    let curSelected;
    // Check if "All" is selected
    if (value.includes('All')) {
      // If "All" is selected, set all options
      curSelected = curSelectedSub.length === subsegmentOption?.[index].length ? [] : curAllSub;
    } else {
      // Otherwise, handle the regular selection
      curSelected = typeof value === 'string' ? value.split(',') : value;
      // setSelectedSubIds({ ...selectedSubIds, [index]: curSelected });
    }
    setSelectedSubIds({ ...selectedSubIds, [index]: curSelected });
    const allIds = getAllSelectedIds(undefined, { ...selectedSubIds, [index]: curSelected });
    onChange?.(allIds);
  };
  // get all selected segment & sug-segment ids
  const getAllSelectedIds = (ids?: string[], subIds?: { [key: number]: string[] }): ReturnData => {
    const segmentIds = ids || selectedSegmentIds;
    const subSegmentIds = subIds || selectedSubIds;
    const allSubIds = Object.values(subSegmentIds || {}).flat();
    return { ids: segmentIds, subIds: allSubIds };
  };

  let wrapClassName = '';
  let labelClassName = 'tw-hidden';
  if (enabledSubsegment) {
    wrapClassName = 'tw-border tw-border-gray-300 tw-p-4 tw-rounded-sm';
    labelClassName = 'tw-block tw-pb-2';
  }

  return (
    <div className={wrapClassName}>
      <FormControl sx={{ m: 1 }} fullWidth>
        <span className={labelClassName}> {Translation('newsArticle.common.segment')}</span>
        <Select
          labelId="multiple-checkbox-label"
          id="segment-multiple-checkbox"
          multiple
          color="secondary"
          className="tw-flex-grow tw-mr-4"
          value={selectedSegmentIds}
          onChange={handleChangeSegment}
          renderValue={(selected) => renderValue(selected, segmentOption)}
        >
          <MenuItem value="All" className={selectedSegmentIds.length === segmentOption.length ? 'Mui-selected' : ''}>
            <Checkbox checked={selectedSegmentIds.length === segmentOption.length} />
            <ListItemText primary={lbAll} />
          </MenuItem>
          {segmentOption.map((segment) => (
            <MenuItem key={segment.value} value={segment.value}>
              <Checkbox checked={selectedSegmentIds.includes(segment.value)} />
              <ListItemText primary={segment.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div>
        {enabledSubsegment && subsegmentOption?.length > 0 ? (
          <>
            <span className={labelClassName + ' tw-ml-2 tw-pt-4 tw--mb-6'}> {Translation('subsegment')}</span>
            {subsegmentOption.map((subsegment, index) => {
              const curSelectedSub = selectedSubIds?.[index] || [];
              const curAllSub = (subsegmentOption?.[index] || []).map((item) => {
                return item.value;
              });
              const isSelectedAll = curSelectedSub.length === curAllSub.length;
              const parentSegmentName =
                getSegmentNameById(subsegment[0].segmentId, segmentOption) || Translation('subsegment');
              return (
                <FormControl sx={{ m: 1, width: 260 }} key={index}>
                  <span className="tw-block tw-py-2"> {parentSegmentName}</span>
                  <Select
                    color="secondary"
                    id={`sub-multiple-checkbox-${index}`}
                    labelId={`sub-multiple-checkbox-label-${index}`}
                    multiple
                    value={curSelectedSub}
                    onChange={(e) => {
                      handleChangeSubSegment(e, index);
                    }}
                    renderValue={(selected) => renderValue(selected, subsegmentOption?.[index])}
                  >
                    <MenuItem value="All" className={isSelectedAll ? 'Mui-selected' : ''}>
                      <Checkbox checked={isSelectedAll} />
                      <ListItemText primary={lbAll} />
                    </MenuItem>
                    {subsegment.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        <Checkbox checked={curSelectedSub.includes(item.value)} />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            })}
          </>
        ) : null}
      </div>
    </div>
  );
});
/**
 * render select selected value.
 * @param selected
 * @param allData
 * @returns
 */
function renderValue(selected: string[], allData: SelectOption[]) {
  return selected
    .map((value) => {
      const item = allData.find((item) => item.value === value);
      return item ? item.name : value; // Fallback to value if not found
    })
    .join(', ');
}
