import { get, set } from 'lodash';
import { AgentSegmentItem, EnrolmentType } from 'src/app/common/types';
import { modulePrunews } from '../../../../agent-sub-segment/sub-segment-detail/sub-segment-form/util';

interface Params {
  id: number;
  name: string;
}

export function isRemovedAgent(param: {
  enrolmentType?: EnrolmentType;
  eligibleAgentsNew?: string[];
  excludedAgentsNew?: string[];
  designationNew?: string[];
  eligibleAgentsOld?: string[];
  excludedAgentsOld?: string[];
  designationOld?: string[];
}): boolean {
  const {
    enrolmentType,
    eligibleAgentsNew,
    excludedAgentsNew,
    designationNew,
    eligibleAgentsOld,
    excludedAgentsOld,
    designationOld,
  } = param;
  let isRemoved = false;
  if (enrolmentType === EnrolmentType.BY_SEGMENT) {
    isRemoved = hasElementsRemoved(designationNew, designationOld);
    if (isRemoved) {
      return true;
    }
    // remove include item
    if ((eligibleAgentsNew?.length || 0) < (eligibleAgentsOld?.length || 0)) {
      return true;
    }
    // add more include item
    if ((excludedAgentsNew?.length || 0) > (excludedAgentsOld?.length || 0)) {
      return true;
    }
    return isRemoved;
  } else {
    // remove include item
    if ((eligibleAgentsNew?.length || 0) < (eligibleAgentsOld?.length || 0)) {
      return true;
    }
  }
  return isRemoved;
}

export function isAddedAgent(param: {
  enrolmentType?: EnrolmentType;
  eligibleAgentsNew?: string[];
  excludedAgentsNew?: string[];
  designationNew?: string[];
  eligibleAgentsOld?: string[];
  excludedAgentsOld?: string[];
  designationOld?: string[];
}): boolean {
  const {
    enrolmentType,
    eligibleAgentsNew,
    excludedAgentsNew,
    designationNew,
    eligibleAgentsOld,
    excludedAgentsOld,
    designationOld,
  } = param;
  let isAddedNew = false;
  if (enrolmentType === EnrolmentType.BY_SEGMENT) {
    isAddedNew = hasElementsAdded(designationNew, designationOld);
    if (isAddedNew) {
      return true;
    }
    // add include item
    if ((eligibleAgentsNew?.length || 0) > (eligibleAgentsOld?.length || 0)) {
      return true;
    }
    // remove exclude item
    if ((excludedAgentsNew?.length || 0) < (excludedAgentsOld?.length || 0)) {
      return true;
    }
    return isAddedNew;
  } else {
    // add include item
    if ((eligibleAgentsNew?.length || 0) > (eligibleAgentsOld?.length || 0)) {
      return true;
    }
  }
  return isAddedNew;
}
/**
 * compare 2 array, check if has element removed from oldArray
 * @param newArray
 * @param oldArray
 * @returns
 */
function hasElementsRemoved(newArray?: string[], oldArray?: string[]) {
  // Check if oldArray is empty
  if (!oldArray || oldArray.length === 0) {
    return false; // Nothing can be removed from an empty array
  }
  // Check if newArray is empty
  if (!newArray || newArray.length === 0) {
    return true; // All elements are removed if newArray is empty
  }
  return oldArray.some((item) => !newArray.includes(item));
}
/**
 * compare 2 array, check if has element added into newArray
 * @param newArray
 * @param oldArray
 * @returns
 */
function hasElementsAdded(newArray?: string[], oldArray?: string[]): boolean {
  // Check if newArray is empty
  if (!newArray || newArray.length === 0) {
    return false; // If newArray is empty, there are no new items
  }
  // Check if oldArray is empty
  if (!oldArray || oldArray.length === 0) {
    if (newArray.length > 0) {
      return true; // If oldArray is empty, newArray has new items
    } else {
      return false;
    }
  }
  // Check if newArray has items not in oldArray
  return newArray.some((item) => !oldArray.includes(item));
}
/**
 * check segment is linked to any sub-segment that has enabled 'Prunews'
 * @param segement
 * @returns
 */
export function hasActiveSubsegment(segement: AgentSegmentItem | undefined) {
  var docs = get(segement, 'subSegmentList.docs') || [];
  return docs.some((item: { isActive: boolean }) => item.isActive);
}

export function hasInactiveSubsegment(segement: AgentSegmentItem | undefined) {
  var docs = get(segement, 'subSegmentList.docs') || [];
  return docs.length > 0 && docs.some((item: { isActive: boolean }) => item.isActive === false);
}
