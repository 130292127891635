import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  newsText: {
    lineHeight: 1.8,
  },
  imgRow: {
    marginTop: 20,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  imgItem: {
    margin: 10,
    maxWidth: 500,
  },
  tag: {
    padding: '8px 10px',
    fontWeight: 600,
    fontSize: 12,
  },
  inputMargin: {
    marginRight: 20,
  },
  mandatory: {
    color: 'red',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
    paddingLeft: 14,
  },
  settingTable: {
    width: '100%',
  },
  checkboxNoArchiveContainer: {
    margin: 0,
  },
  checkboxFieldContainer: {
    paddingTop: 9,
    minWidth: 160,
    boxSizing: 'border-box',
  },
  textAreaRowContainer: {
    display: 'flex',
  },
  dialogContainer: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogTitle: {
    fontSize: 20,
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
}));
