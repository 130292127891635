import { get } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { getConfigurations, useDataProvider } from 'src/app/common/utils';
import { useLang } from 'src/app/i18n';
import { fetchRegistrationList, getExamCodeAndName } from '../../../network/license-exam-crud';
import { PaginateList, RegistrationItem, RegistrationListParam } from '../../../types/license-exam-types';
import { Template } from '../../../types/registration-template-types';

const initialState: RegistrationListParam = {
  examCodes: [],
  examType: '',
  examDateFrom: '',
  examDateTo: '',
  examCentre: '',
  examNames: [],
  registrationApprovalStates: [],
  orNumbers: '',
  registrationIds: '',
  applicantIds: '',
  firstnames: '',
  lastnames: '',
  result: '',
  page: 1,
  limit: 20,
};

export const registrationFilterKeys = [
  'examCodes',
  'examType',
  'examNames',
  'examDateFrom',
  'examDateTo',
  'examCentre',
  'registrationApprovalStates',
  'orNumbers',
  'registrationIds',
  'applicantIds',
  'firstnames',
  'lastnames',
  'result',
];

const paramsInitiator = (initialParams?: Record<string, any>): RegistrationListParam => {
  console.log('initialParams:', initialParams);
  return initialParams
    ? {
        examCodes: initialParams.examCodes,
        examType: initialParams.examType,
        examNames: initialParams.examNames?.split(','),
        examDateFrom: initialParams.examDateFrom,
        examDateTo: initialParams.examDateTo,
        examCentre: initialParams.examCentre,
        registrationApprovalStates: initialParams.registrationApprovalStates?.split(','),
        orNumbers: initialParams.orNumbers,
        registrationIds: initialParams.registrationIds,
        applicantIds: initialParams.applicantIds,
        firstnames: initialParams.firstnames,
        lastnames: initialParams.lastnames,
        result: initialParams.result,
        page: 1,
        limit: 20,
      }
    : initialState;
};

export const useRegistrationListingPage = ({ initialParams, onChangeQueryParams }: ParamsProps) => {
  const dispatch = useDispatch();
  const locale = useLang();

  const [filterState, setFilterState] = useState<any>(paramsInitiator(initialParams));
  const [registrationList, setRegistrationList] = useState<PaginateList<RegistrationItem>>();
  const [template, setTemplate] = useState<Template | null>(null);

  const [examCodeOpts, setExamCodeOpts] = useState<{ displayName: string; value: any }[]>([
    { displayName: 'All', value: '' },
  ]);
  const [examNameOpts, setExamNameOpts] = useState<{ displayName: string; value: any }[]>([
    { displayName: 'All', value: '' },
  ]);

  // get runtime-config
  const enableApproval: boolean = useMemo(() => {
    const config = getConfigurations();
    const enableApproval = get(config, 'Recruitment.enableExamApproval', false);
    return enableApproval;
  }, []);

  const { isLoading, refreshData } = useDataProvider<PaginateList<RegistrationItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchRegistrationList(
          {
            ...filterState,
            examDateFrom: filterState.examDateFrom ? moment(filterState.examDateFrom).format('YYYY-MM-DD') : undefined,
            examDateTo: filterState.examDateTo ? moment(filterState.examDateTo).format('YYYY-MM-DD') : undefined,
            examNames: filterState.examNames,
            lang: locale ?? 'en',
            fields: template?.fields
              ? template.fields.map((field) => `${field.name},${field.order}`).join('|')
              : undefined,
          },
          dispatch,
        );
      } catch (err) {}
    },
    setRegistrationList,
    false,
  );

  // get exam code and name's options by api
  useEffect(() => {
    getExamCodeAndName().then((res: any) => {
      const { examCodeOptions, examNameOptions } = res ?? {};
      if (!examCodeOptions || !examNameOptions) return;
      setExamCodeOpts(
        [].concat(
          examCodeOptions.map((code: string) => {
            return { displayName: code, value: code };
          }),
        ),
      );
      setExamNameOpts(
        [].concat(
          examNameOptions.map((name: string) => {
            return { displayName: name, value: name };
          }),
        ),
      );
    });
  }, []);

  const onSort = (newSortState: { key: string; value?: string }) => {
    setFilterState({
      ...filterState,
      sortBy: newSortState.value ? newSortState.key : undefined,
      orderBy: newSortState.value,
    });
    refreshData();
  };

  // Handle template change
  const handleTemplateChange = useCallback((template: Template | null) => {
    setTemplate(template);
    refreshData();
  }, []);

  return {
    filterState,
    registrationList,
    isLoading,
    enableApproval,
    setFilterState,
    refreshData,
    onSort,
    handleTemplateChange,
    examCodeOpts,
    examNameOpts,
  };
};
