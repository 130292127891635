import { FC } from 'react';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useAgentSubSegmentListingPage } from './sub-segment-list.hook';
import { AgentSubSegmentList } from './components/sub-segment-list.component';
import { LoadingComponent } from 'src/app/common/components/loading/loading.component';

export const SubSegmentListPage: FC<ParamsProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const {
    filterState,
    agentSubSegmentList,
    isLoading,
    segmentOption,
    subsegmentOption,
    segmentValue,
    subsegmentValue,
    loadingSegment,
    loadingSubsegment,
    setFilterState,
    refreshData,
    onSort,
  } = useAgentSubSegmentListingPage({
    initialParams,
    onChangeQueryParams,
  });
  // wait segmentValue & subsegmentValue to init dropdown
  if (loadingSegment || loadingSubsegment) {
    return <LoadingComponent />;
  }

  return (
    <>
      <PruFilter
        title={t('insights.common.filter')}
        itemDef={[
          {
            type: PruFilterItemType.MULTIPLE_DROPDOWN,
            style: filterWidth,
            field: 'segment',
            initialValue: segmentValue,
            displayName: t('newsArticle.common.segment'),
            choices: segmentOption,
          },
          {
            type: PruFilterItemType.MULTIPLE_DROPDOWN,
            style: filterWidth,
            field: 'subsegment',
            initialValue: subsegmentValue,
            displayName: t('subsegment'),
            choices: subsegmentOption,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: filterWidth,
            field: 'search',
            initialValue: filterState.search,
            displayName: t('subsegment.name'),
          },

          {
            type: PruFilterItemType.DATE_TIME_RANGE,
            fieldFrom: 'createdDateFrom',
            fieldTo: 'createdDateTo',
            initialDateFrom: filterState.createdDateFrom,
            initialDateTo: filterState.createdDateTo,
            displayName: t('application.common.createdAt'),
          },
        ]}
        onChangeFilter={(data) => {
          setFilterState({
            ...filterState,
            search: data.search || undefined,
            segmentIds: data.segment || [],
            subSegmentIds: data.subsegment || [],
            createdDateFrom: data.createdDateFrom,
            createdDateTo: data.createdDateTo,
          });
        }}
        fetchData={refreshData}
      />
      <AgentSubSegmentList
        isLoading={isLoading}
        agentSubSegmentList={agentSubSegmentList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={onSort}
      />
    </>
  );
};
const filterWidth = { width: 200 };
