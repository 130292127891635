import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang, useServerLocale } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchAgentSegmentList } from 'src/app/common/network';
import { fetchAgentSegmentItem } from 'src/app/modules/Config/network/agentSegmentCrud';
import { AgentSegmentItem } from 'src/app/common/types';
import { fetchNewsArticleList } from 'src/app/modules/News/network/newsCrud';

interface HookProps {
  data?: AgentSegmentItem;
  modifyTargetApplicant: (
    option:
      | 'enrolmentType'
      | 'eligibleAgents'
      | 'eligibleDesignations'
      | 'excludedAgents'
      | 'agentListFileName'
      | 'agentListUploaded',
    value: any,
  ) => void;
}

export const useSubSegmentForm = (props: HookProps) => {
  // i18n
  const locale = useServerLocale();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [segmentOption, setSegmentOption] = useState<{ displayName: string; value: string }[]>([]);
  // const [parentData, setParentData] = useState<AgentSegmentItem>();

  // only get segment that enableInPruNews
  const { loading: loadingSegment } = useRequest(
    () => fetchAgentSegmentList({ enableInPruNews: true, pagination: false }),
    {
      manual: false,
      onSuccess: (data) => {
        let value: string[] = [];
        let option: { displayName: string; value: string }[] = [];
        (data.docs || []).forEach((item) => {
          value.push(item._id);
          option.push({
            displayName: item.name[locale],
            value: item._id,
          });
        });

        // setSegmentList(value);
        setSegmentOption(option);
      },
    },
  );
  // redux
  const dispatch = useDispatch();

  const { data: articleCount, runAsync: fetchArticle } = useRequest(
    (id: string) => fetchNewsArticleList({ subSegments: [id] } as any, dispatch).then((res) => res.totalRecords),
    { manual: true },
  );
  useEffect(() => {
    const id = props?.data?._id;
    if (id && props.data?.isActive) {
      // get attached article list by sub-segment-id
      fetchArticle(id);
    }
  }, [props.data]);

  const {
    loading: loadingParentData,
    data: parentData,
    runAsync: fetchSegmentDetail,
  } = useRequest((parentSegmentId: string) => fetchAgentSegmentItem(parentSegmentId, dispatch), { manual: true });

  const handlePress = useCallback(() => {}, []);

  const onSelectSegment = useCallback((parentSegmentId: string) => {
    fetchSegmentDetail(parentSegmentId).then((res) => {
      props.modifyTargetApplicant('enrolmentType', res.enrolmentType);
      // setParentData(res);
    });
  }, []);

  return {
    parentData,
    segmentOption,
    handlePress,
    onSelectSegment,
    loadingParentData,
    loadingSegment,
    articleCount,
  };
};
