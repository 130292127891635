import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dialog, DialogTitle, List } from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
// import { useStyles } from './deep-link-list.style';
import { useDeepLinkList } from './deep-link-list.hook';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
};

export const DeepLinkListComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });

  // custom-hook
  const { open, deeplinkList, handleCopy, handleClickOpen, handleClose } = useDeepLinkList();
  if (!deeplinkList) {
    return null;
  }
  return (
    <>
      <div className="tw-my-4 tw-text-blue-700 tw-cursor-pointer" onClick={handleClickOpen}>
        {t('news.deeplink')}
      </div>
      <Dialog onClose={handleClose} open={open} maxWidth="md">
        <DialogTitle sx={{ pb: 0 }}>PRUForce URL List</DialogTitle>
        <List sx={{ px: 2 }}>
          {deeplinkList.map((link, index) => {
            const rowClassName = index % 2 === 0 ? 'tw-bg-gray-100' : 'tw-bg-gray-200';
            return (
              <li className={`tw-flex tw-justify-between tw-p-2 ${rowClassName}`} key={index}>
                <span className="tw-pr-10 tw-mr-10">{link.name}</span>
                <span className="tw-flex tw-justify-between">
                  <span className="tw-underline tw-pr-10"> {link.value}</span>
                  <FileCopyOutlinedIcon className="tw-cursor-pointer" onClick={() => handleCopy(link.value)} />
                </span>
              </li>
            );
          })}
        </List>
      </Dialog>
    </>
  );
});
