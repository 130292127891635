import * as XLSX from 'xlsx';
import { JsonObject } from './data-uploader.component';
import moment from 'moment';

export const downloadExcel = (data: JsonObject[], fileName?: string) => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert the data array to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Generate a binary string
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });

  // Create a Blob from the binary string
  const blob = new Blob([excelBuffer], {
    type: 'application/octet-stream',
  });
  // format file name to: *.xlsx
  fileName = fileName ? `${fileName}.xlsx` : `downloaded_data_${moment().format('YYYY_MM_DD')}.xlsx`;

  // Create a link to download the Blob
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); // Filename

  // Append to the body, click and remove
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
