import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Translation } from 'src/app/i18n';

// Utility functions (support strings)
function not(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => !b.includes(value));
}

function intersection(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.includes(value));
}

function union(a: readonly string[], b: readonly string[]) {
  return [...a, ...not(b, a)];
}

// Define props type for the component
interface TransferListProps {
  initialLeft?: string[];
  initialRight?: string[];
  onListChange?: (left: string[], right: string[]) => void;
  disabled?: boolean;
}

export default function TransferList({
  initialLeft = [],
  initialRight = [],
  onListChange,
  disabled = false,
}: TransferListProps) {
  const [checked, setChecked] = React.useState<string[]>([]);
  const [left, setLeft] = React.useState<string[]>(initialLeft);
  const [right, setRight] = React.useState<string[]>(initialRight);
  const leftListRef = React.useRef<HTMLUListElement>(null);
  const rightListRef = React.useRef<HTMLUListElement>(null);

  // Store scroll positions
  const scrollPositions = React.useRef<{
    left: number;
    right: number;
  }>({ left: 0, right: 0 });

  // Update state only when initialLeft or initialRight changes
  React.useEffect(() => {
    setLeft(initialLeft);
  }, [JSON.stringify(initialLeft)]);

  React.useEffect(() => {
    setRight(initialRight);
  }, [JSON.stringify(initialRight)]);

  React.useEffect(() => {
    if (onListChange) {
      onListChange(left, right);
    }
  }, [left, right, onListChange]);

  // Save scroll positions before updates
  const saveScrollPositions = () => {
    if (leftListRef.current) {
      scrollPositions.current.left = leftListRef.current.scrollTop;
    }
    if (rightListRef.current) {
      scrollPositions.current.right = rightListRef.current.scrollTop;
    }
  };

  // Restore scroll positions after updates
  const restoreScrollPositions = () => {
    requestAnimationFrame(() => {
      if (leftListRef.current) {
        leftListRef.current.scrollTop = scrollPositions.current.left;
      }
      if (rightListRef.current) {
        rightListRef.current.scrollTop = scrollPositions.current.right;
      }
    });
  };

  const handleToggle = (value: string) => (e: React.MouseEvent) => {
    if (disabled) return;

    e.preventDefault();
    e.stopPropagation();

    saveScrollPositions();

    setChecked((prevChecked) => {
      const currentIndex = prevChecked.indexOf(value);
      const newChecked = [...prevChecked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      return newChecked;
    });

    restoreScrollPositions();
  };

  const numberOfChecked = (items: readonly string[]) => intersection(checked, items).length;

  const handleToggleAll = (items: readonly string[]) => () => {
    if (disabled) return;

    saveScrollPositions();

    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }

    restoreScrollPositions();
  };

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleCheckedRight = () => {
    if (disabled) return;

    saveScrollPositions();

    setRight([...right, ...leftChecked]);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    restoreScrollPositions();
  };

  const handleCheckedLeft = () => {
    if (disabled) return;

    saveScrollPositions();

    setLeft([...left, ...rightChecked]);
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));

    restoreScrollPositions();
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    saveScrollPositions();

    const sourceList = source.droppableId === 'left' ? [...left] : [...right];
    const destinationList = destination.droppableId === 'left' ? [...left] : [...right];

    const [removed] = sourceList.splice(source.index, 1);
    destinationList.splice(destination.index, 0, removed);

    if (source.droppableId === 'left') {
      setLeft(sourceList);
    } else {
      setRight(sourceList);
    }

    if (destination.droppableId === 'left') {
      setLeft(destinationList);
    } else {
      setRight(destinationList);
    }

    restoreScrollPositions();
  };

  const SortableList = React.forwardRef<
    HTMLUListElement,
    {
      title: React.ReactNode;
      items: readonly string[];
      droppableId: string;
      listRef?: React.RefObject<HTMLUListElement>;
    }
  >(({ title, items, droppableId, listRef }, ref) => {
    const memoizedItems = React.useMemo(() => items, [items]);

    return (
      <Card
        sx={{
          padding: '12px 20px',
          borderRadius: '12px',
          border: '1px solid #E6E6E6',
          boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.05)',
        }}
      >
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={disabled ? undefined : handleToggleAll(items)}
              checked={numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
              disabled={items.length === 0 || disabled}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <List
              component="ul"
              ref={(el) => {
                provided.innerRef(el);
                if (ref) (ref as React.MutableRefObject<HTMLUListElement | null>).current = el;
                if (listRef) (listRef as React.MutableRefObject<HTMLUListElement | null>).current = el;
              }}
              {...provided.droppableProps}
              sx={{
                width: 320,
                height: 230,
                bgcolor: 'background.paper',
                overflow: 'auto',
              }}
              dense
            >
              {memoizedItems.map((value, index) => (
                <Draggable key={value} draggableId={value} index={index}>
                  {(provided) => (
                    <ListItemButton
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      data-value={value}
                      role="listitem"
                      onClick={(e) => {
                        if ((e.target as HTMLElement).closest('.MuiCheckbox-root')) return;
                        handleToggle(value)(e);
                      }}
                      sx={{
                        '&.ui-sortable-helper': {
                          cursor: 'grabbing',
                          boxShadow: 3,
                        },
                      }}
                      disabled={disabled}
                    >
                      {!disabled && (
                        <ListItemIcon
                          className="drag-handle"
                          sx={{
                            cursor: 'grab',
                            minWidth: '32px',
                            '&:hover': { cursor: 'grab' },
                          }}
                        >
                          <DragIndicatorIcon fontSize="small" color="action" />
                        </ListItemIcon>
                      )}

                      <ListItemIcon sx={{ minWidth: 40 }}>
                        <Checkbox
                          checked={checked.includes(value)}
                          tabIndex={-1}
                          disableRipple
                          disabled={disabled}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleToggle(value)(e);
                          }}
                        />
                      </ListItemIcon>

                      <ListItemText
                        id={`transfer-list-item-${value}-label`}
                        primary={Translation(value)}
                        sx={{ userSelect: 'none' }}
                      />
                    </ListItemButton>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </Card>
    );
  });

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Grid item>
          <SortableList title="Choices" items={left} droppableId="left" listRef={leftListRef} />
        </Grid>
        <Grid item>
          <Grid container direction="column" sx={{ alignItems: 'center' }}>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0 || disabled}
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0 || disabled}
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <SortableList title="Chosen" items={right} droppableId="right" listRef={rightListRef} />
        </Grid>
      </Grid>
    </DragDropContext>
  );
}
