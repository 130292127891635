import { FC, PropsWithChildren, createContext, useMemo, useContext } from 'react';
import { RegionLocale } from './types/i18n-types';
import { regionLocale } from './utils/i18n-utils';
import React from 'react';

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';

type LangConfig = {
  selectedLang: RegionLocale;
};

//  Read From Env Config Afterwards
const initialState: LangConfig = {
  selectedLang: regionLocale[0],
};

export const getConfig = () => {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls) as LangConfig;
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
};

// Side effect
export const setLanguage = (lang: RegionLocale) => {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
};

const I18nContext = createContext<LangConfig | undefined>(undefined);

export const useLang = () => {
  return useContext(I18nContext)?.selectedLang || initialState.selectedLang;
};

export const I18nConsumer = I18nContext.Consumer;

export const I18nConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const lang = useMemo(getConfig, []);

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
};
/**
 * get current Language Abbreviation.
 * e.g. enUS -> en, zh-hant -> zh
 * @param lang
 * @param toUpperCase if convert to upper case, default is true
 */
export function getLangAbbreviation(lang: string, toUpperCase: boolean = true): string {
  if (!lang) {
    return '-';
  }
  // Check if the language string contains a hyphen
  if (lang.includes('-')) {
    // Split the language code at the hyphen and get the first part
    const abbreviation = lang.split('-')[0];
    return toUpperCase ? abbreviation.toUpperCase() : abbreviation;
  } else {
    // If no hyphen, return the first two characters
    const abbreviation = lang.substring(0, 2);
    return toUpperCase ? abbreviation.toUpperCase() : abbreviation;
  }
}
