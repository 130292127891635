export const RECRUITMENT_BASE_PATH = '/recruitment';
export const RECRUITMENT_APPLICATION_FORM_PATH = '/application-form';
export const RECRUITMENT_APPLICATION_FORM_SETTING_PATH = '/setting';
export const FORM_TEMPLATE_PATH = '/form-template';
export const PDF_TEMPLATE_PATH = '/pdf-template';
export const RECRUITMENT_ONBOARDING_APPLICATION_PATH = '/onboarding-application';

export const RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_PATH = '/edit-history';

export const RECRUITMENT_LICENSE_EXAM_PATH = '/license-exam';
export const RECRUITMENT_LICENSE_EXAM_SCHEDULE_PATH = '/schedule';
export const RECRUITMENT_LICENSE_EXAM_SCHEDULE_ADD_PATH = '/add';
export const RECRUITMENT_LICENSE_EXAM_SCHEDULE_UPLOAD_PATH = '/upload';
export const RECRUITMENT_LICENSE_EXAM_REGISTRATION_PATH = '/registration';
export const RECRUITMENT_LICENSE_EXAM_RESULTS_PATH = '/results';
export const RECRUITMENT_LICENSE_EXAM_PASSING_RATE_EXPORT_PATH = '/passing-rate-export';
export const RECRUITMENT_QUIZ_REPORT_PATH = '/quiz-report';
export const RECRUITMENT_LICENSE_VERIFICATION_PATH = '/license-verification';

export const RECRUITMENT_APPROVAL_BASE_PATH = '/approval';
export const RECRUITMENT_APPROVAL_LIST_PATH = '/list';
export const RECRUITMENT_APPROVAL_detail_PATH = '/detail';

export const RECRUITMENT_APPLICATION_COURSES = '/course-list';
export const RECRUITMENT_ONBOARDING_TRAINING = '/onboarding-training';
export const RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION = '/fee-verification';

export const RECRUITMENT_TITLE = 'title.recruitment';

export const RECRUITMENT_APPROVAL_NAMESPACE = 'RECRUITMENT-ONBOARDING-APPLICATION-APPROVAL';
export const RECRUITMENT_APPLICATION_FORM_NAMESPACE = 'RECRUITMENT-APPLICATION-FORM';
export const RECRUITMENT_APPLICATION_FORM_SETTING_NAMESPACE = 'RECRUITMENT-APPLICATION-FORM-SETTING';
export const RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_NAMESPACE = 'RECRUITMENT-APPLICATION-FORM-EDIT-HISTORY';
export const RECRUITMENT_APPLICATION_TRAINING_COURSES_NAMESPACE = 'RECRUITMENT-APPLICATION-TRAINING-COURSES';
export const RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_NAMESPACE = 'RECRUITMENT-APPLICATION-TRAINING-QUIZ-REPORT';

export const RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_NAMESPACE =
  'RECRUITMENT-ONBOARDING-TRAINING-FEE-VERIFICATION';

export const RECRUITMENT_LICENSE_EXAM_SCHEDULE_NAMESPACE = 'RECRUITMENT-LICENSE-EXAM-SCHEDULE';
export const RECRUITMENT_LICENSE_EXAM_REGISTRATION_NAMESPACE = 'RECRUITMENT-LICENSE-EXAM-REGISTRATION';
export const RECRUITMENT_LICENSE_EXAM_RESULTS_NAMESPACE = 'RECRUITMENT-LICENSE-EXAM-RESULTS';
export const RECRUITMENT_LICENSE_VERIFICATION_NAMESPACE = 'RECRUITMENT-LICENSE-VERIFICATION';

export enum AdminRecruitmentPermissionType {
  RECRUITMENT_APPLICATION_FORM_SETTING_READ = 'READ_ANY_RECRUITMENT-APPLICATION-FORM-SETTING',
  RECRUITMENT_APPLICATION_FORM_SETTING_CREATE = 'CREATE_ANY_RECRUITMENT-APPLICATION-FORM-SETTING',
  RECRUITMENT_APPLICATION_FORM_SETTING_UPDATE = 'UPDATE_ANY_RECRUITMENT-APPLICATION-FORM-SETTING',
  RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_READ = 'READ_ANY_RECRUITMENT-APPLICATION-FORM-EDIT-HISTORY',
  RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_CREATE = 'CREATE_ANY_RECRUITMENT-APPLICATION-FORM-EDIT-HISTORY',
  RECRUITMENT_APPLICATION_TRAINING_COURSES_READ = 'READ_ANY_RECRUITMENT-APPLICATION-TRAINING-COURSES',
  RECRUITMENT_APPLICATION_TRAINING_COURSES_CREATE = 'CREATE_ANY_RECRUITMENT-APPLICATION-TRAINING-COURSES',
  RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_READ = 'READ_ANY_RECRUITMENT-APPLICATION-TRAINING-QUIZ-REPORT',
  RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_CREATE = 'CREATE_ANY_RECRUITMENT-APPLICATION-TRAINING-QUIZ-REPORT',
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_READ = 'READ_ANY_RECRUITMENT-LICENSE-EXAM-SCHEDULE',
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_CREATE = 'CREATE_ANY_RECRUITMENT-LICENSE-EXAM-SCHEDULE',
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_UPDATE = 'UPDATE_ANY_RECRUITMENT-LICENSE-EXAM-SCHEDULE',
  RECRUITMENT_LICENSE_EXAM_REGISTRATION_READ = 'READ_ANY_RECRUITMENT-LICENSE-EXAM-REGISTRATION',
  RECRUITMENT_LICENSE_EXAM_REGISTRATION_CREATE = 'CREATE_ANY_RECRUITMENT-LICENSE-EXAM-REGISTRATION',
  RECRUITMENT_LICENSE_EXAM_REGISTRATION_UPDATE = 'UPDATE_ANY_RECRUITMENT-LICENSE-EXAM-REGISTRATION',
  RECRUITMENT_LICENSE_EXAM_RESULTS_READ = 'READ_ANY_RECRUITMENT-LICENSE-EXAM-RESULTS',
  RECRUITMENT_LICENSE_EXAM_RESULTS_CREATE = 'CREATE_ANY_RECRUITMENT-LICENSE-EXAM-RESULTS',
  RECRUITMENT_LICENSE_EXAM_RESULTS_UPDATE = 'UPDATE_ANY_RECRUITMENT-LICENSE-EXAM-RESULTS',
  RECRUITMENT_LICENSE_VERIFICATION_READ = 'READ_ANY_RECRUITMENT-LICENSE-VERIFICATION',
  RECRUITMENT_LICENSE_VERIFICATION_CREATE = 'CREATE_ANY_RECRUITMENT-LICENSE-VERIFICATION',
  RECRUITMENT_LICENSE_VERIFICATION_UPDATE = 'UPDATE_ANY_RECRUITMENT-LICENSE-VERIFICATION',
  RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_READ = 'READ_ANY_RECRUITMENT-ONBOARDING-TRAINING-FEE-VERIFICATION',
  RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_CREATE = 'CREATE_ANY_RECRUITMENT-ONBOARDING-TRAINING-FEE-VERIFICATION',
  RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_UPDATE = 'UPDATE_ANY_RECRUITMENT-ONBOARDING-TRAINING-FEE-VERIFICATION',
  RECRUITMENT_APPROVAL_READ = 'READ_ANY_RECRUITMENT-ONBOARDING-APPLICATION-APPROVAL',
  RECRUITMENT_APPROVAL_UPDATE = 'UPDATE_ANY_RECRUITMENT-ONBOARDING-APPLICATION-APPROVAL',
  RECRUITMENT_APPROVAL_APPROVE = 'APPROVE_ANY_RECRUITMENT-ONBOARDING-APPLICATION-APPROVAL',
  RECRUITMENT_LICENSE_EXAM_PASSING_RATE_READ = 'READ_ANY_RECRUITMENT-LICENSE-EXAM-PASSING_RATE',
}
